import React, {useEffect, useState} from "react";
import PopperMenu from "../../../components/PopperMenu";
import makeStyles from "@mui/styles/makeStyles";
import BulkActionAssign from "./BulkActionAssign";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import BulkActionDelete from "./BulkActionDelete";
import BulkActionMenuItemBase from "./BulkActionMenuItemBase";
import {strings} from "../../../components/SopLocalizedStrings";
import {useNodeOrNull} from "../../../../hooks/nodeHooks";
import BulkActionCustom from "./BulkActionCustom";

const useStyle = makeStyles(() => ({
    popper: {
        zIndex: 900,
    },
    paper: {
        backgroundColor: "white",
    }
}));

export function GridBulkActionItem({action, selectedRowIds}) {
    if (action.id === 'assign') {
        return <BulkActionAssign action={action} selectedRowIds={selectedRowIds}/>;
    }
    if (action.id === 'delete') {
        return <BulkActionDelete action={action} selectedRowIds={selectedRowIds}/>
    }
    return <BulkActionCustom action={action} selectedRowIds={selectedRowIds}/>
}

export default function GridBulkActions({selectorId, selectedRowIds = [], allowActions, onClose}) {
    const selector = useNodeOrNull(selectorId);

    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyle();

    useEffect(() => {
        if (!anchorEl && selectedRowIds && selectedRowIds?.length > 0) {
            setTimeout(() => {
                const gridColumnHeaders = document.querySelector(`[data-table-selector="${selectorId}"] .MuiDataGrid-columnHeaders`);
                setAnchorEl(gridColumnHeaders);
            }, 10);
        }
    }, [selectedRowIds, anchorEl])

    const handleClose = () => {
        setAnchorEl(null);
        onClose?.();
    }

    if (!selector) {
        return null;
    }

    const isOpen = allowActions && anchorEl != null && selectedRowIds?.length > 0;

    return <PopperMenu
        open={isOpen}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        onClose={handleClose}
        popperProps={{
            className: classes.popper,
            modifiers: [
                {
                    name: "offset",
                    enabled: true,
                    options: {
                        offset: [-8, 8],
                    }
                },
                {
                    name: 'preventOverflow',
                    enabled: true,
                    options: {
                        altAxis: true,
                        altBoundary: false,
                        tether: false,
                        rootBoundary: 'document',
                        padding: 8,
                    },
                }
            ]
        }}
        paperProps={{
            className: classes.paper
        }}
    >
        <BulkActionMenuItemBase divider icon={<CheckIcon/>}>
            {strings.formatString(strings.project.list.selectedWorqItems, selectedRowIds?.length ?? 0)}
        </BulkActionMenuItemBase>
        {Object.values(selector.availableActions ?? {}).map(a => <GridBulkActionItem action={a}
                                                                               selectedRowIds={selectedRowIds}/>)}
        <BulkActionMenuItemBase onClick={onClose} icon={<CloseIcon/>} cyName={'bulk-actions-close'}>
            {strings.project.list.bulkActions.cancel}
        </BulkActionMenuItemBase>
    </PopperMenu>;
}