import React from "react";
import {strings} from "../components/SopLocalizedStrings";
import {Permissions} from "../../permissions";
import Dashboard from "@mui/icons-material/DashboardRounded";
import {NonMobileOnly, TbfAction} from "tbf-react-library";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";

const ViewTemplateButton = React.forwardRef(({executionId, procedureId, menuItem}, ref) => {
    const canView = useHasProcedurePermission(executionId || procedureId, Permissions.procedure.list)
    if (!canView) {
        return null
    }
    return (
        <NonMobileOnly>
            <TbfAction
                menuItem={menuItem}
                title={strings.buttons.viewTemplate}
                dataCy='view-template'
                iconComponent={Dashboard}
                path={'/procedures/' + procedureId}
                ref={ref}
            >
                {strings.procedure.name}
            </TbfAction>
        </NonMobileOnly>
    )
})

export default ViewTemplateButton;
