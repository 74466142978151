export const PUT_NODE = 'PUT_NODE';
export const PUT_NODES = 'PUT_NODES';
export const PUT_NODE_PROPERTY = 'PUT_NODE_PROPERTY';
export const INITALISE_GRAPH = 'INITALISE_GRAPH';
export const CLEAR_GRAPH_STARTED = 'CLEAR_GRAPH_STARTED';
export const CLEAR_GRAPH_SUCCESS = 'CLEAR_GRAPH_SUCCESS';
export const CLEAR_GRAPH_FAILURE = 'CLEAR_GRAPH_FAILURE';
export const RESET_GRAPH = 'RESET_GRAPH';
export const SAVE_NODES_START = 'SAVE_NODE_START';
export const DELETE_NODE_SUCCESS = 'DELETE_NODE_SUCCESS';
export const DELETE_NODE_FAILURE = 'DELETE_NODE_FAILURE';
export const SAVE_NODE_SUCCESS = 'NODE_SAVE_SUCCESS';
export const SAVE_NODE_FAILURE = 'NODE_SAVE_FAILURE';
export const SAVE_NODES_FINISH = 'SAVE_NODES_FINISH';
export const LOAD_NODES_START = 'LOAD_NODES_START';
export const LOAD_NODES_FAILURE = 'LOAD_NODES_FAILURE';
export const NODES_LOADED_STORAGE = 'NODES_LOADED_STORAGE';
export const NODES_LOADED_STORAGE_FAILED = 'NODES_LOADED_STORAGE_FAILED';
export const DIRTY_STORAGE_LOADED = 'DIRTY_STORAGE_LOADED';
export const NODES_LOADED_SERVER = 'NODES_LOADED_SERVER';
export const CLEAR_SAVE_ERROR = 'CLEAR_SAVE_ERROR';
export const NODES_SAVE_SUCCESS_LOCAL_DB = 'NODES_SAVE_SUCCESS_LOCAL_DB';
export const NODES_SAVE_ERROR_LOCAL_DB = 'NODES_SAVE_ERROR_LOCAL_DB';
export const NODE_PROPERTY_FOCUS = "NODE_PROPERTY_FOCUS";
export const NODE_PROPERTY_BLUR = "NODE_PROPERTY_BLUR";
export const SET_MENU_TOGGLE_STATE = "SET_MENU_TOGGLE_STATE";
export const NODE_RECOMPUTE_CHECK = "NODE_RECOMPUTE_CHECK";
export const RECOMPUTE_ALL = "RECOMPUTE_ALL";
export const SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE = "SET_FILE_DELETE_SNACKBAR_TOGGLE_STATE";
export const SET_FILE_DELETE_UNDO = "SET_FILE_DELETE_UNDO";
export const SET_FILE_DELETE_COMPLETED = "SET_FILE_DELETE_COMPLETED";