import {
    HELP_DOCUMENT_CREATE_ERROR,
    HELP_DOCUMENT_CREATE_START,
    HELP_DOCUMENT_CREATED,
    HELP_DOCUMENT_LOAD_END,
    HELP_DOCUMENT_LOAD_START,
    HELP_DOCUMENT_LOADED,
    HELP_DOCUMENT_UPDATE_ERROR,
    HELP_DOCUMENT_UPDATE_START,
    HELP_DOCUMENT_UPDATED,
    HELP_POINT_UPDATED,
    SET_HELP_DOCUMENT_SOURCE,
    UPDATE_HELP_POINT,
} from "../actions/helpManualActionTypes";
import keyBy from "lodash/keyBy";
import map from 'lodash/map'
import uuidv4 from "uuid/v4";
import {isEmptyObject} from "../../util/util";

const initialState = {
    helpDocumentSources: {},
    helpDocuments: {},
    helpPoints: {},
    helpDocumentLoading: false
}

const helpManualReducer = (state = initialState, action) => {
    try {
        switch (action.type) {
            case HELP_DOCUMENT_LOAD_START: {
                return {
                    ...state,
                    helpDocumentLoading: true
                }
            }
            case HELP_DOCUMENT_LOADED: {
                let helpDocument = action.payload.response.items && action.payload.response.items.length > 0 ? action.payload.response.items[0] : null;
                let helpPoints = {};
                const externalId = action.payload.externalId;
                let helpDocumentSource = state.helpDocumentSources[externalId];
                let saved = true;

                if (!helpDocument) {
                    helpDocument = {
                        externalId: helpDocumentSource.externalId,
                        externalType: helpDocumentSource.externalType,
                        externalName: helpDocumentSource.externalName,
                        helpPoints: []
                    }
                    saved = false;
                } else {
                    helpPoints = keyBy(helpDocument.helpPoints, a => a.id);
                }
                let updatedHelpPoints = keyBy(helpPoints, a => a.externalId);
                helpDocument.helpPoints = helpDocument.helpPoints.map(d => d.externalId);
                let updatedState = {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, loaded: true, saved: saved},
                    },
                    helpPoints: {...state.helpPoints, ...updatedHelpPoints},
                    helpDocumentLoading: false
                };

                synchronizeHelpDocument(updatedState, externalId);
                return updatedState;
            }
            case HELP_DOCUMENT_LOAD_END: {
                return {
                    ...state,
                    helpDocumentLoading: false
                }
            }
            case SET_HELP_DOCUMENT_SOURCE: {
                const externalId = action.payload.externalId;
                const updatedState = {
                    ...state,
                    helpDocumentSources: {
                        ...state.helpDocumentSources,
                        [action.payload.externalId]: action.payload
                    }
                };
                synchronizeHelpDocument(updatedState, externalId);
                return updatedState;
            }
            case HELP_DOCUMENT_CREATE_START: {
                const externalId = action.payload.externalId;
                const helpDocument = state.helpDocuments[externalId]
                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, creating: true},
                    },
                };
            }
            case HELP_DOCUMENT_CREATED: {
                const responseHelpDocument = action.payload.response;
                const externalId = action.payload.externalId;
                const helpDocument = {
                    ...state.helpDocuments[externalId],
                    id: responseHelpDocument.id,
                    rootId: responseHelpDocument.rootId
                };
                const updatedHelpPoints = {...state.helpPoints};
                // TODO: This is mutating the state
                for (let helpPointId of helpDocument.helpPoints) {
                    updatedHelpPoints[helpPointId].dirty = false;
                }

                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, loaded: true, saved: true, creating: false},
                    },
                    helpPoints: {...state.helpPoints, ...updatedHelpPoints}
                };
            }
            case HELP_DOCUMENT_CREATE_ERROR: {
                const externalId = action.payload.externalId;
                const helpDocument = state.helpDocuments[externalId]
                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, creating: false},
                    },
                };
            }
            case HELP_DOCUMENT_UPDATE_START: {
                const externalId = action.payload.externalId;
                const helpDocument = state.helpDocuments[externalId]
                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, updating: true},
                    },
                };
            }
            case HELP_DOCUMENT_UPDATED: {
                const externalId = action.payload.externalId;
                const helpPoints = keyBy(map(action.payload.response.helpPoints, x => ({
                    ...x,
                    dirty: false
                })), x => x.externalId)
                const helpDocument = {...state.helpDocuments[externalId], loaded: true, saved: true, updating: false}
                helpDocument.helpPoints = map(helpPoints, x => x.externalId)
                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: helpDocument,
                    },
                    helpPoints: {...state.helpPoints, ...helpPoints}
                }
            }
            case HELP_DOCUMENT_UPDATE_ERROR: {
                const externalId = action.payload.externalId;
                const helpDocument = state.helpDocuments[externalId]
                return {
                    ...state,
                    helpDocuments: {
                        ...state.helpDocuments,
                        [externalId]: {...helpDocument, updating: false},
                    },
                };
            }
            case HELP_POINT_UPDATED: {
                // TODO: This is mutating the state
                const updatedHelpPoints = {...state.helpPoints};
                for (let helpPoint of action.payload.helpPoints) {
                    updatedHelpPoints[helpPoint.externalId].dirty = false;
                }

                return {
                    ...state,
                    helpPoints: {
                        ...state.helpPoints,
                        ...updatedHelpPoints
                    }
                };
            }
            case UPDATE_HELP_POINT: {
                return {
                    ...state,
                    helpPoints: {
                        ...state.helpPoints,
                        [action.payload.externalId]: {
                            ...state.helpPoints[action.payload.externalId],
                            ...action.payload,
                            dirty: true
                        }
                    }
                };
            }
            default:
                return state;
        }
    } catch (error) {
        throw error;
    }
};

const synchronizeHelpDocument = (state, externalId) => {
    let helpDocumentSource = state.helpDocumentSources[externalId];
    const helpDocument = {...state.helpDocuments[externalId]};
    if (!helpDocumentSource || !helpDocument || isEmptyObject(helpDocumentSource) || isEmptyObject(helpDocument)) {
        return;
    }
    for (let helpPointSource of helpDocumentSource.helpPointSources) {
        let helpPoint = state.helpPoints[helpPointSource.externalId];
        if (helpPoint === undefined) {
            helpPoint = {
                id: uuidv4(),
                externalId: helpPointSource.externalId,
                externalType: helpPointSource.externalType,
                externalName: helpPointSource.externalName,
                externalParentId: helpPointSource.externalParentId || null,
                externalProperty: helpPointSource.externalProperty,
                externalParentIndex: helpPointSource.externalParentIndex,
                renderScope: 'partial',
                renderType: 'inline',
                dirty: true,
                type: "HelpPoint",
                deleted: helpPointSource.deleted
            }
            helpDocument.helpPoints.push(helpPoint.externalId);
            state.helpPoints[helpPointSource.externalId] = helpPoint;
        } else {
            let properties = ['externalName', 'externalParentId', 'externalParentIndex', 'externalProperty', 'deleted'];
            helpPoint = {...helpPoint};
            for (let property of properties) {
                if (helpPointSource[property] !== helpPoint[property]) {
                    helpPoint[property] = helpPointSource[property];
                    helpPoint.dirty = true;
                }
            }
            state.helpPoints[helpPointSource.externalId] = helpPoint;
        }
    }
    state.helpDocuments[externalId] = helpDocument;
    state.helpDocumentSources[externalId].synced = true;
}

export default helpManualReducer;
