import React, {useCallback, useEffect, useState} from "react";
import {strings} from "../components/SopLocalizedStrings";
import {useNodeOrNull, useNodeSchemaOrError} from "../../hooks/nodeHooks";
import {AddAction, reportInfo} from "tbf-react-library";
import {createNode} from "../../factory/graphFactory";
import {EXECUTION_SOURCE_TYPES, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {useDispatch} from "react-redux";
import {putNodeProperty} from "../../actions";
import {Permissions} from "../../permissions";
import {useHistory} from "react-router-dom";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";
import {hasItems} from "../../util/util";
import ExecutionAddNewDialog from "../execution/ExecutionAddNewDialog";

export const AddNewStyle = {
    dialog: 'dialog',
    page: 'page'
}
/**
 * When clicked this will open the add worq item dialog.
 *
 * @param procedureId
 * @param canAdd
 * @returns {JSX.Element}
 * @constructor
 */
const AddNewExecutionButton = ({procedureId, style = AddNewStyle.dialog, addingCompleted, primaryAction, linkAttributes, components = {}}) => {
    const [executionLinkNewId, setExecutionLinkNewId] = useState(null)
    const procedure = useNodeOrNull(procedureId)
    const schema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ExecutionLinkNew)
    const executionLinkNew = useNodeOrNull(executionLinkNewId)
    const dispatch = useDispatch()
    const history = useHistory()

    const [open, setOpen] = useState();

    const handleAddNew = useCallback(() => {
        if (procedure) {
            const newNode = createNode(schema, {
                procedureId: procedureId,
                source: {kind: EXECUTION_SOURCE_TYPES.add.id},
                ...linkAttributes
            });
            dispatch(putNodeProperty(newNode))
            setExecutionLinkNewId(newNode.id)
        } else {
            reportInfo(strings.procedure.errors.notLoaded);
        }
    }, [procedure, dispatch, procedureId, schema, linkAttributes])

    // Redirect to execution
    // Cypress uses this a lot, I don't want to change those tests
    useEffect(() => {
        if (style === AddNewStyle.page && executionLinkNew?.executionId && executionLinkNewId) {
            dispatch(putNodeProperty({id: executionLinkNewId, submit: true}));
            history.push('/executions/' + executionLinkNew.executionId)
        }
    }, [style, executionLinkNew?.executionId, executionLinkNewId, dispatch, history])
    const canAdd = useHasProcedurePermission(procedureId, Permissions.execution.create)
    const hasCompileWarnings = hasItems(procedure?.compileWarnings)
    const titleTemplate = hasCompileWarnings ? strings.procedure.show.compileErrorsMessage : strings.execution.list.addNewButtonTitle
    const tooltipMsg = strings.formatString(titleTemplate, procedure?.name || strings.execution.name)

    useEffect(() => {
        return () => {
            console.info('AddNewExecutionButton unmounting')
        }
    }, [])

    if (!canAdd) {
        return null;
    }

    const handleAdd = () => {
        if (style === AddNewStyle.dialog) {
            setOpen(true);
        } else {
            handleAddNew();
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const renderButton = () => {
        const {Button} = components;

        if (Button) {
            return <Button title={tooltipMsg} dataCy={'add-execution'}
                           disabled={hasCompileWarnings || !procedure?.loadedFull} onClick={handleAdd}/>
        }
        return <AddAction
            onClick={handleAdd}
            title={tooltipMsg}
            dataCy={'add-execution'}
            disabled={hasCompileWarnings || !procedure?.loadedFull}
            primaryAction={primaryAction}
        />;
    }

    return (<>
        {renderButton()}
        {open && <ExecutionAddNewDialog
            open={open}
            onClose={handleClose}
            onAddCompleted={addingCompleted}
            procedureId={procedureId}
            linkProperties={{
                procedureId: procedureId,
                source: {kind: EXECUTION_SOURCE_TYPES.add.id},
                ...linkAttributes,
            }}
        />}
    </>);
}
export default AddNewExecutionButton;
