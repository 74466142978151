import React from "react";
import {Permissions} from "../../../permissions";
import {TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";
import TrackChangesIcon from '@mui/icons-material/TrackChangesRounded';
import AlphaFeature from "../../components/AlphaFeature";

const ExecutionHistoryAction = React.forwardRef(({executionId, menuItem}, ref) => {
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)
    if (!canView) {
        return null
    }
    return (
        <AlphaFeature>
            <TbfAction
                menuItem={menuItem}
                title={strings.execution.show.viewHistory}
                dataCy='view-history'
                iconComponent={TrackChangesIcon}
                path={'/executions/' + executionId + '/history'}
                ref={ref}
            >
                {strings.execution.show.viewHistory}
            </TbfAction>
        </AlphaFeature>
    )
})
export default ExecutionHistoryAction;
