import {getTime} from "tbf-react-library";
import {executeSilentPromise, getApiBaseUrl} from "./commonAction";

export const SET_CLIENT_CONFIGURATION = 'SET_CLIENT_CONFIGURATION'

export const loadClientCustomConfig = (breakCache, anonymous) => {
    let url = `${getApiBaseUrl()}/files/${anonymous ? 'anonymous/config' : 'all'}/clientConfig.json`;
    if (breakCache) {
        url += `?v=${getTime()}`;
    }
    return executeSilentPromise('get', url);
}

export const setClientConfig = (config) => ({
  type: SET_CLIENT_CONFIGURATION,
  payload: config
})