import auth0 from 'auth0-js';
import {getTime, tbfLocalStorage, URL_CALLBACK_ABSOLUTE} from "tbf-react-library";
import {MANUAL_PERMISSION_LIST} from './permissions';

class ManualsIdentity {
    auth0 = new auth0.WebAuth({
        domain: process.env.REACT_APP_AUTHO_DOMAIN,
        clientID: process.env.REACT_APP_AUTHO_CLIENT_ID,
        redirectUri: URL_CALLBACK_ABSOLUTE,
        responseType: 'token id_token',
        scope: 'openid profile email roles groups ' + MANUAL_PERMISSION_LIST,
        audience: 'https://api.manual.tenbagsfull.com.au'
    });

    constructor() {
        const newAfter = JSON.parse(tbfLocalStorage.getItem('manual_renew_after'));
        this.renew = this.renew.bind(this);
        if (newAfter) {
            this.renewTimer = setTimeout(this.renew, newAfter - getTime());
        }
    }

    renewTimer;

    renew() {
        let nop = () => {
        };
        this.auth0.checkSession({}, this.processAuth0Result(nop, nop));
    }

    getToken() {
        const token = tbfLocalStorage.getItem('manual_access_token');
        let expiresAt = JSON.parse(tbfLocalStorage.getItem('manual_expires_at'));
        let hasExpired = expiresAt && (expiresAt < new Date().getTime());
        if (token && !hasExpired) {
            return new Promise((resolve) => {
                resolve(token)
            });
        } else {
            return new Promise((resolve, reject) => {
                MANUAL_TOKEN.auth0.checkSession({}, this.processAuth0Result(resolve, reject));
            });
        }
    }

    processAuth0Result = (resolve, reject) => (err, authResult) => {
        if (err) {
            reject(err);
        } else {
            tbfLocalStorage.setItem('manual_access_token', authResult.accessToken);
            tbfLocalStorage.setItem('manual_token_type', authResult.tokenType);
            let expiresAt = authResult.expiresIn * 1000 + getTime();
            tbfLocalStorage.setItem('manual_expires_at', JSON.stringify(expiresAt));
            let renewAfter = (authResult.expiresIn * 1000 / 2) + getTime();
            tbfLocalStorage.setItem('manual_renew_after', JSON.stringify(renewAfter));
            resolve(authResult.accessToken);
            this.renewTimer = setTimeout(this.renew, authResult.expiresIn * 1000 / 2);
        }
    };
}

export const MANUAL_TOKEN = new ManualsIdentity();
