import React from 'react';
import '../../style/alert.css';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {ComponentBase} from "tbf-react-library";
import {isOnline} from "../../util/util";
import {Link} from "react-router-dom";

class ExecutionLink extends ComponentBase {

    render() {
        let {nodeId, notAvailable, title} = this.props;
        return (

            <Link data-cy={'execution-link'} to={`/executions/${nodeId}`} title={title || ''}
                  className={notAvailable ? 'offline-not-available' : ''}>
                {this.props.children}
            </Link>
        );
    }
}

const styles = () => ({});
ExecutionLink.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let executionNode = getNodeOrNull(state, nodeId);
    let online = isOnline();
    return {
        nodeId: nodeId,
        notAvailable: !online && !!executionNode && executionNode.availableOffline !== true
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionLink));
