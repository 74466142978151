import React, {useCallback, useState} from "react";
import {AddAction} from "tbf-react-library";

import ExecutionShowQuestionAddLink from "../../ExecutionShowQuestionAddLink";
import {useGetAddButtonLabel, useGetTemplateOptions} from "../../../../hooks/executionHooks";


const QueryAddExecution = ({disabled, questionId, onAddCompleted}) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const {canAdd, hasMultiOptions, hasOptions} = useGetTemplateOptions(questionId)
    const label = useGetAddButtonLabel(questionId);

    const handleClose = useCallback(() => {
        setAnchorEl(null)
    }, [])
    const handleAdd = useCallback((e) => {
        setAnchorEl(e.target)
    }, [])
    if (!canAdd || disabled) {
        return null
    }

    if(!hasOptions) {
        return  <></>
    }

    return (
        <>
            <AddAction
                isMultiple={hasMultiOptions}
                primaryAction={true}
                onClick={handleAdd}
                label={label}
                dataCy={'add-execution'}
            />
            <ExecutionShowQuestionAddLink
                anchorEl={anchorEl}
                questionId={questionId}
                onClose={handleClose}
                handleCreate={onAddCompleted}
            />
        </>
    );
}
export default QueryAddExecution
