import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import ExecutionTask from "./ExecutionTask";
import ExecutionComplete from "./ExecutionComplete";
import * as PropTypes from "prop-types";
import HelpPointInline from "../../manuals/layouts/HelpPointInline";
import {ComponentBase} from "tbf-react-library";
import ExecutionRulesList from "./troubleshoot/ExecutionRulesList";
import NodeProcessingWarning from '../graph/NodeProcessingWarning';
import cx from 'classnames';

class ExecutionStep extends ComponentBase {

    renderTasks() {
        let {children, troubleshootOn, diagnosticsOn} = this.props;
        return (
            <React.Fragment>
                {
                    children.map(nodeId => (
                        <ExecutionTask
                            key={nodeId}
                            taskId={nodeId}
                            troubleshootOn={troubleshootOn}
                            diagnosticsOn={diagnosticsOn}
                        />
                    ))
                }
            </React.Fragment>
        );
    }

    render() {
        let {procedureStepId, name, stepId, classes, hideStep, stepDisabled, visible, troubleshootOn} = this.props;
        return (
            <React.Fragment>
                <div
                    data-cy-step={name}
                    data-cy-id={stepId}
                    data-cy-element={'Step'}
                    style={{visibility: `${hideStep ? 'hidden' : 'visible'}`}}
                    className={cx({[classes.hidden]: !visible})}
                >
                    <div className={classes.helpContainer}>
                        <HelpPointInline helpPointExternalId={procedureStepId}/>
                    </div>
                    <ExecutionComplete nodeId={stepId} disabled={stepDisabled}>
                        <div className={classes.executionTaskForm}>
                            {this.renderTasks()}
                        </div>
                    </ExecutionComplete>
                    {
                        troubleshootOn && <>
                            <ExecutionRulesList nodeId={stepId}/>
                            <NodeProcessingWarning nodeId={stepId}/>
                        </>
                    }
                </div>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    helpContainer: {
        width: '100%',
        padding: '0 8px'
    },
    executionTaskForm: {
        '& .signOffOn-container': {
            paddingLeft: 0
        }
    },
    hidden: {
        backgroundColor: theme.palette.deleted.one.main
    }
});
ExecutionStep.propTypes = {
    stepId: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    let {stepId} = ownProps;
    let executionStep = getNodeOrNull(state, stepId);
    let stepDisabled = executionStep?.disabled;
    return {
        children: executionStep?.children || [],
        procedureStepId: executionStep?.procedureStepId,
        name: executionStep?.name,
        stepDisabled: stepDisabled,
        visible: executionStep?.visible
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionStep));
