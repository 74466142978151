import {hasValue} from "../util/util";
import {reportDeveloperWarning, SharedAuth} from "tbf-react-library";
import {
    getActiveChildrenDescendantsAndSelf,
    getNodeOrError,
    getNodeOrNull,
    getNodesById,
    getNodesIfPresent
} from "./graphSelectors";
import {
    COMPLETE_ACTION_STYLES,
    GRANT_DENY_PERMISSIONS,
    NAVIGATION_STYLES,
    NODE_TYPE_OPTIONS,
    RULE_ACTION_TYPE
} from "../reducers/graphReducer";
import {shallowEqual, useSelector} from "react-redux";
import {Permissions} from "../permissions";
import {
    getActiveRuleForNodeByActionOrNull,
    getChildRuleByActionOrNull,
    getRulesForNodeByActionIfPresent
} from "./ruleSelectors";

export const isProcedureLoadedFull = procedure => {
    let looksLoaded = procedure && procedure.loadedFull;
    let butIsNot = looksLoaded && !hasValue(procedure?.children);
    if (butIsNot) {
        reportDeveloperWarning(`Procedure looks loaded but is not.`, procedure);
        return false;
    }
    return looksLoaded;
};
export const getRootRuleOrNull = (state, rule) => {
    if (rule.nodeIds == null || rule.nodeIds.length !== 1) {
        return rule;
    }
    let nodes = getNodesIfPresent(state, rule.nodeIds);
    if (nodes.length === 1) {
        if (nodes[0].type === NODE_TYPE_OPTIONS.ProcedureRule) {
            return getRootRuleOrNull(state, nodes[0]);
        }
    }
    return rule;
}
export const getParentRuleOrNull = (state, ruleId) => {
    let rule = getNodeOrError(state, ruleId);
    if (rule.nodeIds == null || rule.nodeIds.length !== 1) {
        return null;
    }
    let nodes = getNodesIfPresent(state, rule.nodeIds);
    if (nodes.length === 1) {
        if (nodes[0].type === NODE_TYPE_OPTIONS.ProcedureRule) {
            return nodes[0];
        }
    }
    return null;
}

export const getLinkToQuestionRules = (state, nodeId) => {
    const node = getNodeOrError(state, nodeId);
    const procedureNode = getNodeOrError(state, node.rootId);
    let ruleNodes = getNodesById(state, procedureNode.rules || []).filter(rule => !rule.draft && rule.linkToQuestionOn && rule.nodeIds.length > 0);
    return ruleNodes.filter(rule => (rule.linkToQuestionOn && rule.nodeIds.indexOf(nodeId) !== -1));
}

export const useLinkToQuestionRules = (nodeId) => {
    return useSelector(state => getLinkToQuestionRules(state, nodeId), shallowEqual);
}

export const getAllRuleNodes = (nodes) => {
    return nodes.filter(node => node.type === NODE_TYPE_OPTIONS.ProcedureRule);
}

export const getChildRules = (ruleNodes, ruleId) => {
    return ruleNodes.filter(d => d.nodeIds.indexOf(ruleId) !== -1);
}
export const getActiveProcedureQuestions = (state, procedureId) => {
    let nodes = getActiveChildrenDescendantsAndSelf(state, procedureId);
    return nodes
        .filter(a => !a.deleted && a.type === NODE_TYPE_OPTIONS.ProcedureQuestion);
}

export const hasProcedurePermission = (state, procedureId, permission) => {
    const node = getNodeOrNull(state, procedureId)
    const procedure = getNodeOrNull(state, node?.rootId)
    return hasProcedurePermissionLoaded(procedure, permission)
}
export const hasProcedurePermissionLoaded = (procedure, permission) => {
    if (!procedure) {
        return false;
    }
    if (permission === Permissions.procedure.edit && procedure.canComplete === false) {
        return false;
    }
    if (procedure._metadata) {
        const foundPermission = procedure._metadata.permissions?.find(a => a.permission === permission);
        return foundPermission !== undefined;
    }
    return !permission || SharedAuth.userHasPermission(permission)
}
export const canAddExecution = (state, addOptions) => {
    const procedureIds = (addOptions || []).map(a => a.procedureId)
    const nodes = getNodesIfPresent(state, procedureIds)
    for (let node of nodes) {
        if (isProcedureLoadedFull(node) && hasProcedurePermissionLoaded(node, Permissions.execution.create)) {
            return true;
        }
    }
    return false;
}
export const getNavigationStyle = (state, procedureId) => {
    const rule = getActiveRuleForNodeByActionOrNull(state, procedureId, RULE_ACTION_TYPE.navigationStyle.id)
    return rule ? rule.format : NAVIGATION_STYLES.tab.id;
}
export const getNavigationStyleRule = (state, procedureId) => {
    return getActiveRuleForNodeByActionOrNull(state, procedureId, RULE_ACTION_TYPE.navigationStyle.id)
}
export const getCompleteActionStyle = (state, procedureId) => {
    return getActiveRuleForNodeByActionOrNull(state, procedureId, RULE_ACTION_TYPE.completeActionStyle.id)?.format || COMPLETE_ACTION_STYLES.standard.id;
}

export const getGrantRulesForProcedure = (state, procedureId) => {
    const securityRule = getChildRuleByActionOrNull(state, procedureId, RULE_ACTION_TYPE.security.id)
    return getRulesForNodeByActionIfPresent(state, securityRule?.id, RULE_ACTION_TYPE.grant.id)
}

export const getRoleHasProcedurePermission = (state, procedureId, roleOrGroup, permission, everythingShouldMatch = false) => {
    const grantRules = getGrantRulesForProcedure(state, procedureId);
    const createRules = grantRules.filter(a => !a.deleted && Array.isArray(a.permissions) && (a.permissions.includes(GRANT_DENY_PERMISSIONS.all.id) || a.permissions.includes(permission)))

    if(everythingShouldMatch) {
        return createRules.every(a => a.calculateValueQuery && JSON.parse(a.calculateValueQuery).includes(roleOrGroup))
    }

    const roleOrGroupCreateRules = createRules.filter(a => a.calculateValueQuery && JSON.parse(a.calculateValueQuery).includes(roleOrGroup))
    return roleOrGroupCreateRules.length > 0
}