import React from 'react';
import Button from "@mui/material/Button";
import {connect} from "react-redux";
import withStyles from '@mui/styles/withStyles';
import {withRouter} from "react-router";
import ExpandLess from "@mui/icons-material/ExpandLessRounded";
import ExpandMore from "@mui/icons-material/ExpandMoreRounded";
import AssignmentCard from "../assignments/AssignmentCard";
import ChevronRight from "@mui/icons-material/ChevronRightRounded";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {strings} from "../components/SopLocalizedStrings";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import EmptyAssignmentMessage from "../assignments/EmptyAssignmentMessage";
import {defaultValue} from "../../util/util";
import Loader from "../components/Loader";
import {Panel} from "tbf-react-library";
import {MINUTES_5} from "../../util/constants";

const assignmentShownItems = 5;

class RecentAssignments extends React.Component {
    state = {
        numberOfActivitiesShown: assignmentShownItems,
    }

    renderMyAssignmentsSection = () => {
        const {classes, myAssignments, history} = this.props;
        const {numberOfActivitiesShown} = this.state;
        const seeMoreVisible = myAssignments.length > assignmentShownItems;
        const assignments = seeMoreVisible ? myAssignments.slice(0, numberOfActivitiesShown) : myAssignments;

        return <React.Fragment>
            <div role="list">
                {
                    assignments.map((assignment, index) => <AssignmentCard key={index} assignmentId={assignment.id}/>)
                }
            </div>
            <div className={classes.actionBottomContainer}>
                {
                    seeMoreVisible &&
                    <Button
                        size="small"
                        className={classes.showMoreAssignmentButton}
                        title={numberOfActivitiesShown === myAssignments.length ? strings.buttons.showLess : strings.buttons.showMore}
                        endIcon={(numberOfActivitiesShown === myAssignments.length ? <ExpandLess/> : <ExpandMore/>)}
                        onClick={() => {
                            this.setState({
                                numberOfActivitiesShown: (numberOfActivitiesShown === myAssignments.length ? assignmentShownItems : myAssignments.length)
                            });
                        }}>
                        {numberOfActivitiesShown === myAssignments.length ? strings.buttons.showLess : strings.buttons.showMore}
                    </Button>
                }
                <div className={classes.grow}/>
                <Button
                    variant='contained'
                    title={strings.buttons.viewAll}
                    color='secondary'
                    size="small"
                    className={classes.linkButton}
                    endIcon={<ChevronRight/>}
                    onClick={() => {
                        history.push('/assignments');
                    }}>
                    {strings.buttons.viewAll}
                </Button>
            </div>
        </React.Fragment>
    }


    render() {
        let {myAssignments, loading, classes, className} = this.props;
        let titleLoader = loading && myAssignments.length > 0;
        return <>
            <Panel title={strings.dashboard.assignments} loading={titleLoader} className={className}>
                <GraphResourceLoad
                    key={NODE_IDS.MyAssignments}
                    resourcePath={NODE_IDS.MyAssignments}
                    friendlyName={strings.assignment.namePlural}
                    nodeType={NODE_TYPE_OPTIONS.NodeAssignment}
                    incrementalLoadOff={true}
                    hideLoader={true}
                    hideOfflineWarnings={false}
                    reloadIntervalMs={MINUTES_5}>
                    {
                        !loading && myAssignments.length === 0 && <EmptyAssignmentMessage/>
                    }
                    {
                        myAssignments.length > 0 && this.renderMyAssignmentsSection()
                    }
                </GraphResourceLoad>
                {
                    loading && myAssignments.length === 0 &&
                    <Loader loaderContainerStyleClass={classes.loadingIndicator} circular={true} source={'MyWorkspaces'}
                            circularSize={32} contentCentered={true}/>
                }
            </Panel>
        </>
    }

}

const styles = (theme) => ({
    actionBottomContainer: {
        display: 'flex',
        float: 'left',
        width: '100%',
        paddingTop: 5
    },
    grow: {
        flexGrow: 1
    },
    paddingLeft0: {
        paddingLeft: 0
    },
    showMoreAssignmentButton: {
        fontSize: '0.75rem',
        padding: '4px 8px',
        color: theme.palette.grey.five.main,
        textTransform: 'none',
    },
    linkButton: {
        fontSize: 12,
        padding: '4px 8px',
        '& .MuiButton-endIcon': {
            marginTop: 1,
            marginLeft: 0,
            marginRight: -4,
        }
    },
    loadingIndicator: {
        minHeight: 300
    }
});
const mapStateToProps = (state) => {
    let resourcesSync = getNodeOrNull(state, NODE_IDS.MyAssignments);
    let assignmentIds = resourcesSync?.nodeIds || [];
    let assignments = getNodesById(state, assignmentIds);

    return {
        loading: defaultValue(resourcesSync?.loading, false),
        myAssignments: assignments,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(RecentAssignments)));
