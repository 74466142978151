import { SET_CLIENT_CONFIGURATION } from '../actions/configuration';

const initialState = {}

export default function clientConfig (state = initialState, action) {
  switch (action.type) {
    case SET_CLIENT_CONFIGURATION:
      return {
        ...action.payload
      }
    default:
      return state;
  }
}
