import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import {strings} from "../../components/SopLocalizedStrings";
import {putNodeProperty} from "../../../actions";
import {getNodeOrNull} from "../../../selectors/graphSelectors";
import {NODE_IDS, PROCEDURE_EXECUTION_VIEW_TYPES} from "../../../reducers/graphReducer";
import TabRoundedIcon from '@mui/icons-material/TabRounded';
import TocRoundedIcon from '@mui/icons-material/TocRounded';
import {TbfMenuItem} from "tbf-react-library";
import {getProcedureExecutionView} from "../../../selectors/executionSelectors";

const pageStrings = strings.execution.procedureViewType;

class ProcedureExecutionViewButton extends Component {

    handleProcedureTreeViewState = () => {
        let {
            onPutNodeProperty,
            procedureId,
            deviceDataProcedureExecutionView,
            procedureExecutionView,
            handleClicked
        } = this.props;
        handleClicked && handleClicked();
        const view = !procedureExecutionView || procedureExecutionView === PROCEDURE_EXECUTION_VIEW_TYPES.tabs ? PROCEDURE_EXECUTION_VIEW_TYPES.toc : PROCEDURE_EXECUTION_VIEW_TYPES.tabs;
        onPutNodeProperty({
            id: NODE_IDS.UserDevice,
            procedureExecutionView: {...deviceDataProcedureExecutionView, [procedureId]: view}
        });
    };

    render() {
        let {procedureExecutionView} = this.props;
        return <TbfMenuItem
            onClick={this.handleProcedureTreeViewState}
            iconComponent={(!procedureExecutionView || procedureExecutionView === PROCEDURE_EXECUTION_VIEW_TYPES.tabs ? TocRoundedIcon : TabRoundedIcon)}>
            {!procedureExecutionView || procedureExecutionView === PROCEDURE_EXECUTION_VIEW_TYPES.tabs ? pageStrings.switchContentView : pageStrings.switchTabView}
        </TbfMenuItem>
    }
}

const styles = (theme) => ({
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        paddingTop: 5,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    iconWrapper: {
        position: 'relative',
        width: 'auto',
        float: 'left',
        marginRight: 8,
        top: 1,
        display: 'flex',
    },
    listItemFabProgress: {
        color: theme.palette.primary.one.main,
        position: 'absolute',
        top: -4,
        left: -4,
        zIndex: 1,
    },
});

ProcedureExecutionViewButton.propTypes = {
    procedureId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    const userDeviceData = getNodeOrNull(state, NODE_IDS.UserDevice);
    const procedureExecutionView = getProcedureExecutionView(state, (ownProps.procedureId));

    return {
        deviceDataProcedureExecutionView: userDeviceData.procedureExecutionView,
        procedureExecutionView: procedureExecutionView,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProcedureExecutionViewButton)));
