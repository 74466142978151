import React  from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {Accordion, AccordionDetails, AccordionSummary} from '@mui/material'
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded';
import Grid from "@mui/material/Grid";
import ExecutionComplete from "./ExecutionComplete";
import DoneIcon from '@mui/icons-material/DoneRounded';
import ExecutionQuestion from "./ExecutionQuestion";
import HelpPointMarker from "../../manuals/layouts/HelpPointMarker";
import HelpPointInline from "../../manuals/layouts/HelpPointInline";
import {ComponentBase} from "tbf-react-library";
import AssignmentsUserBadge from "../components/AssignmentsUserBadge";
import ExecutionRulesList from "./troubleshoot/ExecutionRulesList";
import HighlightNode from './troubleshoot/HighlightNode';
import NodeProcessingWarning from '../graph/NodeProcessingWarning';
import cx from 'classnames';
import { useNodesIfPresent } from '../../hooks/nodeHooks';
import makeStyles from '@mui/styles/makeStyles';
import { DEFAULT_LAYOUT_COLUMNS_WIDTH, VISIBLE_MODES } from '../../reducers/graphReducer';
import {useParentWidthObserver} from "../../hooks/useResizeObserver";

const styles = (theme) => ({
    progressIcon: {
        verticalAlign: 'bottom'
    },
    taskContainer: {
    },
    expansionDetails: {
        display: 'block',
        padding: '10px 16px 16px 30px',
        [theme.breakpoints.down('xs')]: {
            padding: '10px 6px 16px 20px',
        },
    },
    iconButtonDone: {
        position: 'absolute',
        left: 9,
        width: '0.75em',
        height: '0.75em',
        fill: theme.palette.secondary.four.main,
        [theme.breakpoints.down('xs')]: {
            left: 0,
        },
    },
    gridItemQuestionContainer: {
        width: "100%",
    },
    grow: {
        flexGrow: 1,
    },
    expansionPanel: {
        paddingLeft: 0,
        '& .MuiCollapse-root': {
            overflow: 'hidden',
            paddingTop: 0
        }
    },
    accordionSummary: {
        paddingLeft: 29,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 20,
            paddingRight: 12
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: 0
        },
        '&.Mui-expanded': {
            minHeight: 48
        }
    },
    helpContainer: {
        width: '100%',
    },
    secondaryDetails: {
        '& .userName, & .statusName': {
            fontSize: '12px !important',
        }
    },
    hidden: {
        backgroundColor: theme.palette.deleted.one.main
    },
    questionDivider: {
        width: "100%",
        height: 0,
        maxHeight: 0,
        borderBottom: "1px dotted #DDD",
    }
});

const useStyles = makeStyles(styles);

export function ExecutionQuestionsGridItem({questionId, troubleshootOn, diagnosticsOn, row, columnWidth = 12}) {
    const classes = useStyles();

    return <Grid item xs={columnWidth} className={classes.gridItemQuestionContainer}>
        <ExecutionQuestion
            questionId={questionId}
            questionIndex={row}
            troubleshootOn={troubleshootOn}
            diagnosticsOn={diagnosticsOn}
            inColumn={columnWidth < 12}
        />
    </Grid>
}

const DEFAULT_WIDTH = 12;
export function ExecutionQuestionsGridItems({questionIds, taskColumnWidth, troubleshootOn, diagnosticsOn, procedureTaskId}) {
    const parentRef = React.useRef();
    const {width} = useParentWidthObserver(parentRef);

    let useTaskColumnWidth = !taskColumnWidth ? DEFAULT_LAYOUT_COLUMNS_WIDTH : taskColumnWidth;
    if(width <= 600) {
        useTaskColumnWidth =  DEFAULT_LAYOUT_COLUMNS_WIDTH;
    } else if(width > 600 && width <= 900) {
        useTaskColumnWidth = Math.min(2, useTaskColumnWidth);
    } else if (width > 900 && width <= 1200) {
        useTaskColumnWidth = Math.min(3, useTaskColumnWidth);
    } else {
        useTaskColumnWidth = Math.min(4, useTaskColumnWidth);
    }

    const classes = useStyles();

    const questions = useNodesIfPresent(questionIds);

    const renderQuestionsGridRows = () => {
        const rows = []
        let currentRowColumnWidthTotal = 0;
        let row = 0;
        for (let question of questions) {
            if ((!question.visible || question.deleted) && !troubleshootOn) {
                continue;
            }

            const questionWidth = question.columnWidth ?? 1;
            const columnWidthToUse = Math.min(DEFAULT_WIDTH,(DEFAULT_WIDTH / useTaskColumnWidth) * questionWidth);
            const potentialTotalColumnWidth = currentRowColumnWidthTotal + questionWidth;

            if (potentialTotalColumnWidth > DEFAULT_WIDTH) {
                currentRowColumnWidthTotal = columnWidthToUse;
                row++;
            } else {
                currentRowColumnWidthTotal += columnWidthToUse;
            }
            rows.push(
                <React.Fragment key={question.id}>
                    {(potentialTotalColumnWidth / 12 > 1) && <Grid item xs={12} className={classes.questionDivider} />}
                    <ExecutionQuestionsGridItem
                        questionId={question.id}
                        troubleshootOn={troubleshootOn}
                        diagnosticsOn={diagnosticsOn}
                        row={row}
                        columnWidth={columnWidthToUse}
                    />
                </React.Fragment>
            )
        }
        return rows;
    }

    return (
        // data-cy-loading is to address failing test in cypress for LayoutColumns.spec
        // where in it capture snapshot before applying the layout column rules
        <Grid ref={parentRef} data-cy-loading={width === 0} container rowSpacing={0} columnSpacing={2} className={classes.taskContainer} justifyContent={"flex-start"}>
            <Grid item xs={12} className={classes.helpContainer}>
                <HelpPointInline helpPointExternalId={procedureTaskId}/>
            </Grid>
            {renderQuestionsGridRows()}
        </Grid>
    );
}

class ExecutionTask extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderQuestion(questionId, troubleshootOn, diagnosticsOn, i, row, columnWidth = 1) {
        return <React.Fragment key={questionId}>
            <ExecutionQuestion
                questionId={questionId}
                questionIndex={i}
                troubleshootOn={troubleshootOn}
                diagnosticsOn={diagnosticsOn}
                inColumn={columnWidth > 1}
            />
        </React.Fragment>;
    }

    render() {
        let {
            classes,
            taskDisabled,
            children,
            taskId,
            allQuestionsCompleted,
            procedureTaskId,
            name,
            hidden,
            hideSignOffAction,
            create,
            troubleshootOn,
            diagnosticsOn,
            task
        } = this.props;
        let done = !taskDisabled && allQuestionsCompleted;
        if (!task) {
            return <React.Fragment/>;
        }
        if (hidden && !troubleshootOn) {
            return (<React.Fragment/>);
        }
        return (
            <Accordion
                defaultExpanded={true}
                className={cx(classes.expansionPanel, 'taskContainerPanel', {[classes.hidden]: hidden})}
                data-cy-task={name} data-cy-id={taskId} data-cy-element={'Task'}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon/>}
                                  className={`${classes.accordionSummary} taskContainerPanelHeader`}>
                    {
                        done &&
                        <DoneIcon className={classes.iconButtonDone}/>
                    }
                    <Typography variant={"h2"}>
                        <HighlightNode nodeId={taskId}>{name}</HighlightNode>
                        <HelpPointMarker helpPointExternalId={procedureTaskId}
                                         helpIconClassName={'helpIconSuperScript'}/>
                    </Typography>
                    <div className={classes.grow}/>
                    <div className={classes.secondaryDetails}>
                        <AssignmentsUserBadge nodeId={taskId}/>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.expansionDetails}>
                    <ExecutionComplete nodeId={taskId} disabled={taskDisabled} hideSignOffAction={hideSignOffAction}
                                       create={create}>
                        <ExecutionQuestionsGridItems procedureTaskId={procedureTaskId} questionIds={children} troubleshootOn={troubleshootOn} diagnosticsOn={diagnosticsOn} taskColumnWidth={task.columnWidth} />
                    </ExecutionComplete>
                    {
                        troubleshootOn && <>
                            <ExecutionRulesList nodeId={taskId}/>
                            <NodeProcessingWarning nodeId={taskId}/>
                        </>
                    }
                </AccordionDetails>
            </Accordion>
        );
    }
}
ExecutionTask.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let executionTaskNode = getNodeOrNull(state, ownProps.taskId)
    let canView = executionTaskNode?.canView !== false
    let taskDisabled = executionTaskNode?.disabled;
    return {
        allQuestionsCompleted: executionTaskNode?.allQuestionsCompleted,
        name: executionTaskNode?.name,
        procedureTaskId: executionTaskNode?.procedureTaskId,
        children: executionTaskNode?.children,
        hidden: !executionTaskNode || !executionTaskNode?.visible || executionTaskNode?.deleted || !canView,
        taskDisabled: taskDisabled,
        task: executionTaskNode,
    }
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionTask));
