import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import "./../../style/form.css";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Typography from "@mui/material/Typography";
import {getNodeOrNull, getNodeSchemaOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import Grid from "@mui/material/Grid";
import {LINK_TYPES, LINK_TYPES_SELECTABLE} from "../../reducers/graphReducer";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import {putNodesProperty} from "../../actions";
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import {createChildNode} from "../../factory/graphFactory";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import ExecutionSelector from "../components/ExecutionSelector";
import {ComponentBase, TbfModal, TbfSelectDeviceFriendly} from "tbf-react-library";
import FormLabel from "@mui/material/FormLabel";
import {GLOBAL_SEARCH_TYPES} from "../../util/constants";
import {hasExecutionPermission} from "../../selectors/executionSelectors";

const initialState = {
    open: false,
    linkType: LINK_TYPES.auditOf.id,
    toNode: null,
    inputSearchTerm: null,
};

const pageStrings = strings.execution.addLinkDialog;

class ExecutionAddLinkDialog extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = initialState;
    }

    handleOpen = () => {
        this.setState({open: true});
    };
    handleClose = () => {
        this.setState({open: false});
    };

    onLinkTypeSelected = (e) => {
        this.setState({linkType: (e ? e.value : '')});
    };

    onNodeSelected = (node) => {
        this.setState({toNode: node});
    };

    onSubmit = (event) => {
        event.preventDefault();
        let {fromNode, onPutNodesProperty, fromNodeLinks} = this.props;
        let {toNode, linkType} = this.state;

        let linkOne = this.buildLink(fromNode, fromNodeLinks, toNode, linkType, false);
        onPutNodesProperty([...linkOne])
        this.setState(initialState);
        return false;
    };

    buildLink(fromNode, fromNodeLinks, toNode, linkType, draft) {
        let {linkSchema} = this.props;
        let existing = fromNodeLinks.find(a => a.toNodeId === toNode.id && a.linkType === linkType);
        if (existing) {
            return [{id: existing.id, deleted: false}];
        } else {
            let linkAttr = {
                linkType: linkType,
                toNodeId: toNode.id,
                toNodeTitle: toNode.title,
                toNodeKey: toNode.key,
                draft: fromNode.draft || draft,
                preview: fromNode.preview
            };
            let linkNode = createChildNode(fromNode, linkSchema, linkAttr);
            return [linkNode, {id: fromNode.id, links: [...(fromNode.links || []), linkNode.id]}];
        }
    }

    handleSearchTermChange = (searchTerm) => {
        this.setState({inputSearchTerm: searchTerm});
    }

    render() {
        let {classes, fromNode, canLink} = this.props;
        let {open, linkType, toNode, inputSearchTerm} = this.state;
        if (!canLink) {
            return null;
        }

        const linkTypeOptions = Object.values(LINK_TYPES_SELECTABLE).map((item) => {
            return {value: item.id, label: item.name}
        });
        const selectedLinkTypeValues = linkType ? linkTypeOptions.filter(d => d.value === linkType) : [];

        const selectedExecutionValues = [];
        if (toNode) {
            selectedExecutionValues.push(toNode);
        }

        return (
            <React.Fragment>
                <Button color='inherit'
                        size={'small'}
                        onClick={this.handleOpen}
                        className={classes.link}
                        title={pageStrings.addButton}
                        data-cy='add-link'>
                    <AddRoundedIcon/>&nbsp;{pageStrings.addButton}
                </Button>

                <TbfModal
                    open={open}
                    onClose={this.handleClose} data-cy={'AddLinkDialog'}
                >
                    <form onSubmit={this.onSubmit}>
                        <div className={classes.paper}>
                            <Typography variant="h2" color="inherit" noWrap>Add Link</Typography>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div>You are adding a link from</div>
                                    <div>{fromNode.title}</div>
                                </Grid>
                                <Grid item xs={12} className={classes.marginTop20}>
                                    <FormLabel className={'sizeSmall'} htmlFor={'linkType'}>Link type</FormLabel>
                                    <TbfSelectDeviceFriendly
                                        multiple={false}
                                        closeMenuOnSelect={true}
                                        blurInputOnSelect={true}
                                        propertyName={'linkType'}
                                        onChange={this.onLinkTypeSelected}
                                        handleSearchTermChange={this.handleSearchTermChange}
                                        options={linkTypeOptions}
                                        value={selectedLinkTypeValues}
                                        inputValue={inputSearchTerm}
                                        disabled={false}
                                        required={true}
                                        isSearchable={true}
                                        mobileView={true}
                                        menuPortalTarget={document.body}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <FormLabel className={'sizeSmall'}
                                               htmlFor={'execution-autocomplete'}>{pageStrings.executionLookup}</FormLabel>
                                    <ExecutionSelector
                                        propertyName={'execution-autocomplete'}
                                        id={'add-link-dialog'}
                                        handleChange={this.onNodeSelected}
                                        required={true}
                                        isClearable={true}
                                        mobileView={true}
                                        limit={10}
                                        value={selectedExecutionValues}
                                        searchTypes={[GLOBAL_SEARCH_TYPES.workItems.id]}
                                        menuPortalTarget={document.body}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Toolbar disableGutters={true}>
                                        <div className={classes.grow}/>
                                        <Button color='secondary' variant='contained'
                                                onClick={this.handleClose}
                                                title={strings.app.cancel}
                                                data-cy='add-link-cancel'>{strings.app.cancel}</Button>
                                        {
                                            toNode &&
                                            <GraphResourceLoad
                                                key={toNode.id}
                                                resourcePath={`/executions?id=${toNode.id}`}
                                                friendlyName={strings.execution.name}
                                                nodeId={toNode.id}
                                                nodeType={'ExecutionRoot'}
                                            >
                                                <Button type={'submit'} color='primary' variant='contained'
                                                        title={strings.app.ok}
                                                        className={classes.button}
                                                        data-cy='add-link-ok'>{strings.app.ok}</Button>
                                            </GraphResourceLoad>
                                        }
                                        {
                                            !toNode &&
                                            <Button type={'submit'} color='primary' variant='contained' disabled={true}
                                                    className={classes.button} title={strings.app.ok}
                                                    data-cy='add-link-ok'>{strings.app.ok}</Button>
                                        }
                                    </Toolbar>
                                </Grid>
                            </Grid>
                        </div>
                    </form>
                </TbfModal>
            </React.Fragment>
        )
    }
}

const styles = (theme) => ({
    paper: {
        position: 'absolute',
        width: '95%',
        maxWidth: 600,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)'
    },
    button: {
        margin: theme.spacing(1),
    },
    grow: {
        flexGrow: 1,
    },
    link: {
        padding: '2px 10px',
    },
    linkAddIcon: {
        fill: theme.palette.add.main
    },
    marginTop20: {
        marginTop: 20
    }
});
ExecutionAddLinkDialog.propTypes = {
    fromNodeId: PropTypes.string.isRequired,
};
const mapStateToProps = (state, ownProps) => {
    let fromNode = getNodeOrNull(state, ownProps.fromNodeId);
    let fromNodeLinkIds = (fromNode && fromNode.links) || [];
    let fromNodeLinks = getNodesIfPresent(state, fromNodeLinkIds);
    let canLink = hasExecutionPermission(state, fromNode?.id, Permissions.execution.link);
    return {
        fromNode: fromNode,
        fromNodeLinks: fromNodeLinks,
        linkSchema: getNodeSchemaOrNull(state, 'ExecutionLink'),
        canLink: canLink
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodesProperty: node => dispatch(putNodesProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionAddLinkDialog));
