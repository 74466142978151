import React from "react";
import withStyles from '@mui/styles/withStyles';
import List from "@mui/material/List";
import ExecutionShowLinkItem from "./ExecutionShowLinkItem";

const ExecutionShowLinkList = ({classes, items, canRemoveLink, linkedExecutions}) => {

    return <List dense={true} className={classes.listContainer}>
        {items.map((item, index) => <ExecutionShowLinkItem key={index} item={item} canRemoveLink={canRemoveLink}
                                                           linkedExecutions={linkedExecutions}/>)}
    </List>
}

const styles = () => ({
    listContainer: {
        paddingTop: 2,
        paddingBottom: 0,
    },
});

export default withStyles(styles)(ExecutionShowLinkList);
