import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../../style/alert.css';
import "../../../style/form.css";
import {getNodeOrNull, getNodesById, getNodeSchemaOrNull} from "../../../selectors/graphSelectors";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import groupBy from "lodash/groupBy";
import keyBy from "lodash/keyBy";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CloseIcon from '@mui/icons-material/CloseRounded';
import Loader from "../../components/Loader";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import {Permissions} from "../../../permissions";
import ExecutionAssignmentChip from "../ExecutionAssignmentChip";
import {strings} from "../../components/SopLocalizedStrings";
import {ComponentBase} from "tbf-react-library";
import ExecutionAssignmentUser from "../ExecutionAssignmentUser";
import {hasExecutionPermission, isExecutionLoadedFull} from "../../../selectors/executionSelectors";

const pageStrings = strings.execution.assignmentDialog;

class ExecutionAssignmentDialog extends ComponentBase {

    constructor(props) {
        super(props);
    }

    renderAssignable = (assignable, index) => {
        let {classes} = this.props;
        let gridClass = index % 2 === 0 ? '' : classes.gridOdd;
        return (
            <React.Fragment key={assignable.id}>
                <Grid item xs={12} data-cy={'Assign' + assignable.type}>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={4} className={gridClass}>
                                <span>
                                    {assignable.icon} {assignable.name}
                                </span>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={gridClass}>
                            <span>{pageStrings.assignedToGroup} </span>
                            {<ExecutionAssignmentChip
                                nodes={assignable.nodes}
                                includeCount={true}
                                displayDelete={true}
                                hideDeletedGroups={true}
                                displayUsers={false}/>}
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} className={gridClass}>
                            <span>{pageStrings.assignedToUser} </span>
                            {
                                assignable.nodes && assignable.nodes.length === 1 &&
                                <ExecutionAssignmentUser nodes={assignable.nodes}/>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </React.Fragment>);
    };

    handleKeyDown = (e) => e.stopPropagation();

    render() {
        let {classes, nodeIds, assigns, nodeCount, loading, canAssign, handleClose} = this.props;
        if (!canAssign) {
            return null;
        }
        return (
            <React.Fragment>
                {
                    nodeIds.map(nodeId => (
                        <GraphResourceLoad
                            key={nodeId}
                            resourcePath={`/executions?id=${nodeId}`}
                            friendlyName={strings.execution.name}
                            nodeId={nodeId}
                            nodeType={'ExecutionRoot'}
                            hideOfflineWarnings={false}
                        />))
                }
                <Dialog
                    open={true}
                    onClose={handleClose}
                    scroll={"paper"}
                    fullScreen={true}
                    onKeyDown={this.handleKeyDown}
                    aria-labelledby={'dialog-title'}>
                    <DialogTitle className={classes.dialogTitle}>
                        <Typography variant={"span"} className={classes.title} id={'dialog-title'}>
                            {pageStrings.title}
                        </Typography>
                        <div className={classes.grow}/>
                        <IconButton
                            className={classes.closeButton}
                            onClick={handleClose}
                            data-cy='close'
                            title={strings.buttons.closeAssignmentsDialog}
                            size="large">
                            <CloseIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <div>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <span>{strings.formatString(pageStrings.message, nodeCount)}</span>
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} className={classes.assignmentContainer}>
                                        {
                                            loading &&
                                            <Loader source={'Execution Assignment Dialog'}/>
                                        }
                                        {
                                            !loading &&
                                            assigns.map(this.renderAssignable)
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button color='secondary' variant='contained' className={classes.button}
                                title={strings.app.close}
                                onClick={handleClose}
                                data-cy='add-assignment-close'>{strings.app.close}</Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    button: {
        margin: theme.spacing(1),
    },
    icon: {
        fill: theme.palette.add.main
    },
    gridSelected: {
        backgroundColor: theme.palette.primary.main
    },
    chip: {
        paddingRight: theme.spacing(1)
    },
    dialogTitle: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex'
    },
    gridOdd: {
        backgroundColor: theme.palette.grey.one.main
    },
    link: {
        padding: '2px 10px',
    },
    grow: {
        flexGrow: 1,
    },
    title: {
        paddingTop: 10,
        color: theme.palette.primary.two.main
    },
    closeButton: {
        color: theme.palette.primary.two.main
    },
    assignmentContainer: {
        padding: '8px 15px'
    }
});
ExecutionAssignmentDialog.propTypes = {
    nodeIds: PropTypes.array.isRequired,
    displayOpen: PropTypes.bool,
    handleClose: PropTypes.func
};
const mapStateToProps = (state, ownProps) => {
    const nodes = getNodesById(state, ownProps.nodeIds || []);
    const nodesByProcedure = groupBy(nodes, a => a.procedureId);
    const assigns = [];
    const processedIds = {};
    let loading = false;
    for (let [, nodes] of Object.entries(nodesByProcedure)) {
        for (let execution of nodes) {
            loading = loading || execution.loading || !isExecutionLoadedFull(execution);
            let canAssign = hasExecutionPermission(state, execution.id, Permissions.execution.assign);
            if (!processedIds[execution.procedureId]) {
                let assign = {
                    id: execution.procedureId,
                    name: 'WORQ: ' + execution.name,
                    level: 0,
                    nodes: [],
                    type: 'Execution',
                    canAssign: canAssign
                };
                processedIds[execution.procedureId] = assign;
                assigns.push(assign);
            }
            processedIds[execution.procedureId].count++;
            processedIds[execution.procedureId].nodes.push(execution);
            if (!execution.children) {
                continue;
            }
            for (let stepId of execution.children) {
                let step = getNodeOrNull(state, stepId);
                if (!step) {
                    continue;
                }
                if (!processedIds[step.procedureStepId]) {
                    let assign = {
                        id: step.procedureStepId,
                        name: 'Step: ' + step.name,
                        level: 1,
                        nodes: [],
                        type: 'Step',
                        canAssign: canAssign
                    };
                    processedIds[step.procedureStepId] = assign;
                    assigns.push(assign);
                }
                processedIds[step.procedureStepId].count++;
                processedIds[step.procedureStepId].nodes.push(step);
                for (let taskId of step.children) {
                    let task = getNodeOrNull(state, taskId);
                    if (!task) {
                        continue;
                    }

                    if (!processedIds[task.procedureTaskId]) {
                        let assign = {
                            id: task.procedureTaskId,
                            name: 'Task: ' + task.name,
                            level: 2,
                            nodes: [],
                            type: 'Task',
                            canAssign: canAssign
                        };
                        processedIds[task.procedureTaskId] = assign;
                        assigns.push(assign);
                    }
                    processedIds[task.procedureTaskId].count++;
                    processedIds[task.procedureTaskId].nodes.push(task);
                }
            }
        }
    }
    const canAssign = assigns.some(a => a.canAssign);
    return {
        assigns: assigns,
        nodeCount: nodes.length,
        executionsById: keyBy(nodes, a => a.id),
        loading,
        assignmentSchema: getNodeSchemaOrNull(state, 'ExecutionAssignment'),
        canAssign: canAssign
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionAssignmentDialog));
