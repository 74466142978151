import React, {useCallback} from 'react';
import ProcedureRuleBuilder from "./ProcedureRuleBuilder";
import GraphResourceLoad from "../../graph/GraphResourceLoad";
import {JSON_LOGIC_EDITORS, NODE_IDS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import NodeTextField from "../../graph/NodeTextField";
import {useNodeOrNull} from "../../../hooks/nodeHooks";
import Grid from "@mui/material/Grid";
import {GraphLoadGroup} from "../../graph/GraphLoadGroup";

const ProcedureRuleBuilderContainer = ({nodeId, ruleProperty, includeLinks, includeProcedureIds, disabled}) => {

    const node = useNodeOrNull(nodeId)
    let skipGroups = window.location.hostname === "localhost";
    let canUseFormula = node?.actionType !== RULE_ACTION_TYPE.filter.id;
    const renderRuleBuilder = useCallback(() => {
        return <Grid container>
            <Grid item xs={10}>
                {
                    node?.conditionStyle !== JSON_LOGIC_EDITORS.formula.id &&
                    <ProcedureRuleBuilder
                        key={includeLinks}
                        nodeId={nodeId}
                        ruleProperty={ruleProperty}
                        includeLinks={includeLinks}
                        includeProcedureIds={includeProcedureIds}
                        disabled={disabled}
                    />
                }
                {
                    node?.conditionStyle === JSON_LOGIC_EDITORS.formula.id &&
                    <NodeTextField
                        nodeId={nodeId}
                        nodePropertyName={'conditionHuman'}
                        disabled={disabled}
                        visual={'jsonlogic'}
                    />
                }
            </Grid>
            <Grid item xs={2}>
                {
                    !disabled && canUseFormula && node.type === NODE_TYPE_OPTIONS.ProcedureRule &&
                    <NodeTextField nodeId={nodeId} nodePropertyName={'conditionStyle'}/>
                }
            </Grid>
        </Grid>
    }, [nodeId, ruleProperty, includeLinks, includeProcedureIds, disabled, node])

    if (skipGroups) {
        return renderRuleBuilder();
    }
    return (
        <React.Fragment>
            <GraphLoadGroup renderChildren={renderRuleBuilder}/>
        </React.Fragment>
    )
}
export default ProcedureRuleBuilderContainer
