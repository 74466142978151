import React, {useCallback, useEffect, useState} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getDirtyNodes, getNodeOrNull, getNodes} from "../../selectors/graphSelectors";
import {clearSaveError, pretendSaveStarted, putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SyncIcon from '@mui/icons-material/Sync';
import {countDb, deleteDbNode, getDbNodes, hasMockError, setMockError, setNoImageCache} from "../../util/offline";
import GraphClear from "./GraphClear";
import {
    ai,
    ComponentBase,
    DEBUGS,
    ERRORS,
    getCurrentHost,
    getTime,
    reportDeveloperWarning,
    reportError,
    reportSuccess,
    SHARED_AUTH_INSTANCE,
    tbfLocalStorage,
    URL_RETURNED_RENEW_RELATIVE
} from "tbf-react-library";
import {SeverityLevel} from '@microsoft/applicationinsights-web';
import {DIAGNOSTIC_MODES, getRecentActions, NODE_IDS} from "../../reducers/graphReducer";
import TrackChanges from "@mui/icons-material/TrackChangesRounded";
import {formatDate, formatTime, getDate} from "../../util/util";
import InfoIcon from '@mui/icons-material/InfoRounded';
import IconButton from "@mui/material/IconButton";
import {strings} from "../components/SopLocalizedStrings";
import {Link} from "react-router-dom";
import NodeTextField from "./NodeTextField";
import GraphReset from "./GraphReset";
import {HOURS_24, MINUTES_15, MINUTES_5} from "../../util/constants";
import {startClearOldData, stopClearOldData} from "../../actions/offline";
import {Accordion, AccordionDetails, TextField} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import {getStorageLength} from "../MapView/offline/TbfTileManager";

function ExpandMoreIcon() {
    return null;
}
export const IndexedDbCount = () => {
    const [count, setCount] = useState(null)
    useEffect(() => {
        countDb().then((result) => {
            setCount(result);
        });
    }, [])
    return count
}
export const IndexedPersisted = () => {
    const [on, setOn] = useState(null)
    // if (navigator && navigator.storage && navigator.storage.persisted) {
    //     navigator.storage.persisted().then(result => {
    //         setOn(result);
    //     })
    // }
    if (on == null) return 'Unsure'
    return on ? 'Yes' : 'No'
}

export const TileCount = () => {
    const [count, setCount] = useState(null)
    useEffect(() => {
        getStorageLength().then((count) => {
            setCount(count);
        });
    }, [])
    return count
}

export const Auth0ActiveSession = () => {
    const [active, setActive] = useState(null)

    const checkSessionCallback = useCallback(() => {
        // SHARED_AUTH_INSTANCE.isIdentitySessionActive()
        //     .then((isSessionActive) => {
        //         setActive(isSessionActive);
        //     })
        //     .catch(() => {
        //         setActive(false);
        //     })
    })

    useEffect(() => {
        checkSessionCallback()
    }, [checkSessionCallback])

    return <React.Fragment>
        {active ? 'Yes' : 'No'}
        <SyncIcon sx={{cursor: "pointer"}} fontSize={"small"} onClick={checkSessionCallback}/>
    </React.Fragment>
}

class GraphDebug extends ComponentBase {

    constructor(props) {
        super(props);

        this.state = {
            debugDataIndex: null,
        };

        this.clearSaveErrors = this.clearSaveErrors.bind(this);
        this.causeError = this.causeError.bind(this);
        this.success = this.success.bind(this);
        this.handlePretendSaveStarted = this.handlePretendSaveStarted.bind(this);
        this.clearDb = this.clearDb.bind(this);
        this.setClearDbAgeOld = this.setClearDbAgeOld.bind(this);
        this.setClearDbAgeAll = this.setClearDbAgeAll.bind(this);
        GraphDebug.trackEvent = GraphDebug.trackEvent.bind(this);
        GraphDebug.trackException = GraphDebug.trackException.bind(this);
        GraphDebug.trackTrace = GraphDebug.trackTrace.bind(this);
    }

    mounted = false;
    nodeIdFieldRef = React.createRef();
    patchFieldRef = React.createRef();

    componentWillUnmount() {
        this.mounted = false;
    }

    setStateMounted = (state) => {
        this.setState(state);
    }

    componentDidMount() {
        this.mounted = true;
        this.getOauthInfo();
    }

    getOauthInfo = () => {
        const oauthError = tbfLocalStorage.getItem('login_processAuth0Result_last_error');
        const expireAt = SHARED_AUTH_INSTANCE.expiresAt;
        const tokenExpirationDate = new Date(+expireAt);
        this.setState({tokenExpirationDate: tokenExpirationDate, oauthError: oauthError ?? ""});
    }

    handlePretendSaveStarted() {
        let {pretendSaveStarted} = this.props;
        pretendSaveStarted();
    }

    handleBreakNextLogin() {
        tbfLocalStorage.setItem('perm_mock_login_processAuth0Result_error', 'Mock error during login');
    }

    clearSaveErrors() {
        let {nodes, dirtyNodes} = this.props;
        for (let dirtyNode of Object.values(dirtyNodes)) {
            let node = nodes[dirtyNode.id];
            this.props.clearSaveError(node);
        }
    }

    breakIndexedDb = () => {
        if (hasMockError()) {
            setMockError(null);
        } else {
            setMockError("Mock indexeddb error");
        }
    };

    breakStorage = () => {
        if (hasMockError()) {
            setMockError(null);
        } else {
            setMockError("QuotaExceededError");
        }
    };

    causeError = () => {
        this.setState({causeRenderError: true});
        let x = {};
        x.iAmABug.b = "";
    };


    reportDeveloperWarning = () => {
        reportDeveloperWarning('Mock developer warning.');
    };

    success = () => {
        reportSuccess('Ya it worked')
    };

    expireAuth0Token = () => {
        SHARED_AUTH_INSTANCE.mockExpiredToken()
    }

    killSession = () => {
        window.open(`${getCurrentHost()}${URL_RETURNED_RENEW_RELATIVE}?kill_session=1`);
    }

    storagePersist = () => {
        navigator.storage.persist()
            .then(result => {
                reportSuccess(result ? 'Persist on' : 'Persist not on')
                this.checkStorage();
            })
            .catch(error => {
                reportError(error)
            })
    }

    downloadState = () => {
        getDbNodes()
            .then((nodes) => {
                let json = JSON.stringify(nodes, null, 2);
                var blob = new Blob([json], {type: "application/json"});
                const url = URL.createObjectURL(blob);
                let today = getDate();
                let dt = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDay();
                let filename = 'INDEXEDDB-STATE-' + dt + '.json';
                this.downloadFile(url, filename);
            });
    };


    downloadRedux = () => {
        let {state} = this.props;
        let actions = getRecentActions();
        let result = {
            state: state,
            recentActions: actions,
            errors: ERRORS.slice().reverse(),
            debugs: DEBUGS.slice().reverse()
        };
        let json = JSON.stringify(result, null, 2);
        const blob = new Blob([json], {type: "application/json"});
        const url = URL.createObjectURL(blob);
        let today = getDate();
        let dt = today.getFullYear() + '-' + today.getMonth() + '-' + today.getDay();
        let filename = 'REDUX-STATE-' + dt + '.json';
        this.downloadFile(url, filename);
    };

    downloadFile = (url, filename) => {
        const element = document.createElement('a');
        element.setAttribute('href', url);
        element.setAttribute('download', filename);

        element.style.display = 'none';
        document.body.appendChild(element);

        element.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(element);
    };

    static trackException() {
        try {
            throw new Error('I am also an error, hear me ROAR!');
        } catch (e) {
            reportError('Error occurred message', e, {data: true, value: 'Hello World'});
        }

    }

    static trackTrace() {
        ai.trackTrace({message: 'some trace', severityLevel: SeverityLevel.Information});
    }

    static trackEvent() {
        ai.trackEvent({name: 'some event'});
    }

    reload() {
        console.info('GraphDebug.reload');
        window.location.reload();
    }

    static throwError() {
        // This will crash the app; the error will show up in the Azure Portal
        let foo = window['a']['b'];
        console.log(foo);
    }

    expireIndexeddbClear = () => {
        this.props.onPutNodeProperty({
            id: NODE_IDS.UserDevice,
            lastIndexeddbClearedDateTime: null
        })
    };

    expireAssignmentDownload = () => {
        this.props.onPutNodeProperty({
            id: NODE_IDS.MyAssignedExecutions,
            lastReloadTicks: getTime() - MINUTES_15 - 1000
        })
    };

    expireFullAssignmentDownload = () => {
        this.props.onPutNodeProperty({
            id: NODE_IDS.MyAssignedExecutions,
            lastReloadTicks: getTime() - MINUTES_15 - 1000,
            lastFullReloadTicks: getTime() - 7 * HOURS_24 - 1000
        })
    };

    expireNotFullAssignmentDownload = () => {
        this.props.onPutNodeProperty({
            id: NODE_IDS.MyAssignedExecutions,
            lastReloadTicks: getTime() - MINUTES_15 - 1000,
            lastFullReloadTicks: getTime() - HOURS_24 - 1000
        })
    };

    toggleDiagnosticMode = () => {
        const {userDevice} = this.props;
        const diagnosticsOn = userDevice.diagnosticMode === DIAGNOSTIC_MODES.full.id;
        tbfLocalStorage.setItem('perm_diagnosticsOn', !diagnosticsOn);
        this.props.onPutNodeProperty({
            id: userDevice.id,
            diagnosticMode: diagnosticsOn ? DIAGNOSTIC_MODES.none.id : DIAGNOSTIC_MODES.full.id
        })
    };

    toggleTroubleshootOn = () => {
        const {userDevice} = this.props;
        const troubleshootOn = !userDevice.troubleshootOn;
        tbfLocalStorage.setItem('perm_trooubleShootOn', troubleshootOn);
        this.props.onPutNodeProperty({
            id: userDevice.id,
            troubleshootOn: troubleshootOn
        })
    };

    displayDebugData = (index) => () => {

        this.setState(state => ({debugDataIndex: state.debugDataIndex === index ? null : index}));
    };


    setClearDbAgeOld = () => {
        const oldderThan = MINUTES_5
        this.setClearDbAge(oldderThan)
    };
    setClearDbAgeAll = () => {
        const oldderThan = 0
        this.setClearDbAge(oldderThan)
    };
    setClearDbAge = (since) => {
        this.props.onPutNodeProperty({
            id: NODE_IDS.UserDevice,
            indexeddbClearedAge: since
        })
    }

    setProcessingWarning = (nodeId, value) => {
        this.props.onPutNodeProperty({
            id: nodeId,
            processingWarning: value,
        });
    }

    deleteDbNode = async (nodeId) => {
        await deleteDbNode(nodeId);
    }

    draftNode = (nodeId) => {
        this.props.onPutNodeProperty({
            id: nodeId,
            draft: true
        })
    }

    deleteReduxNode = async (nodeId) => {
        this.props.onPutNodeProperty({
            id: nodeId,
            deleted: true,
        });
    }

    restoreReduxNode = async (nodeId) => {
        this.props.onPutNodeProperty({
            id: nodeId,
            deleted: false,
        });
    }

    patchNode = async (nodeId, value) => {
        try {
            let patch = JSON.parse(value);
            this.props.onPutNodeProperty({
                id: nodeId,
                ...patch,
            });
        } catch {
            console.error("[Error] Patch is invalid.");
        }
    }

    clearDb = () => {
        let {userDevice} = this.props;
        let since = getTime() - userDevice.indexeddbClearedAge
        return startClearOldData(since, true)
    }
    cancelClearDb = () => {
        stopClearOldData(true)
    }

    preventImageCache = () => {
        setNoImageCache(true)
    }

    render() {
        let {classes, dirtyNodes, nodes, user, userDevice} = this.props;
        const {
            debugDataIndex,
            causeRenderError,
            oauthError
        } = this.state;
        let rows = [];
        for (let dirtyNode of Object.values(dirtyNodes)) {
            let node = nodes[dirtyNode.id];
            if (node?.rootId == null) {
                continue;
            }
            let rootNode = nodes[node.rootId];
            if (!rootNode) {
                continue;
            }
            let cells = [];
            cells.push(<Link to={`/executions/${rootNode.id}`}>{rootNode.name}</Link>);
            cells.push(<Link to={`/graph/nodes/${dirtyNode.id}`}>{node.type}</Link>);
            let name = null;
            if (node.type === 'Photo') {
                let relatedNode = node.type === 'Photo' ? nodes[node.executionQuestionId] : null;
                let relatedRootNode = relatedNode ? nodes[relatedNode.rootId] : null;
                if (relatedNode && relatedRootNode) {
                    name = relatedRootNode.title || relatedRootNode.name + ' -> ' + relatedNode.name;
                } else {
                    name = '';
                }
            } else {
                name = rootNode.title || rootNode.name;
                if (node !== rootNode) {
                    name = name + ' -> ' + node.name;
                }
            }
            cells.push(name);
            cells.push(dirtyNode.dirtyProperties.join(','));
            cells.push(dirtyNode.saveErrorCount);
            cells.push(JSON.stringify(dirtyNode.lastSaveError || {}, null, 2));
            rows.push(cells);
        }
        let errors = ERRORS.slice().reverse();
        let debugs = DEBUGS.slice().reverse();
        const diagnosticsOn = userDevice.diagnosticMode === DIAGNOSTIC_MODES.full.id;
        const troubleshootOn = userDevice.troubleshootOn;
        return (
            <div>
                <div>
                    <GraphReset/>
                    <Button data-cy={'retry-save-all'} variant='contained' onClick={this.clearSaveErrors}>
                        Retry All Saving
                    </Button>
                    <Button data-cy={'pretend-save-all'} variant='contained' onClick={this.handlePretendSaveStarted}>
                        Pretend Saving
                    </Button>
                    <Button data-cy={'download-state'} variant='contained' onClick={this.downloadRedux}>
                        Download Redux
                    </Button>
                    <Button data-cy={'reload'} variant='contained' onClick={this.reload}>Reload</Button>
                    <TextField label={"Node ID"} inputProps={{className: classes.textField, ref: this.nodeIdFieldRef}} data-cy={'debug-node-id'} />
                    <Button data-cy={'add-processing-warning'} variant='contained' onClick={() => this.setProcessingWarning(this.nodeIdFieldRef?.current?.value, "Debugging error!")}>Add</Button>
                    <Button data-cy={'clear-processing-warning'} variant='contained' onClick={() => this.setProcessingWarning(this.nodeIdFieldRef?.current?.value, null)}>Clear</Button>
                    <Button data-cy={'delete-db-node'} variant='contained' onClick={() => this.deleteDbNode(this.nodeIdFieldRef?.current?.value)}>Delete from DB</Button>
                    <Button data-cy={'delete-redux-node'} variant='contained' onClick={() => this.deleteReduxNode(this.nodeIdFieldRef?.current?.value)}>Delete</Button>
                    <Button data-cy={'restore-redux-node'} variant='contained' onClick={() => this.restoreReduxNode(this.nodeIdFieldRef?.current?.value)}>Restore</Button>
                    <Button data-cy={'draft-db-node'} variant='contained' onClick={() => this.draftNode(this.nodeIdFieldRef?.current?.value)}>Draft</Button>
                    <TextField label={"Patch"} inputProps={{className: classes.textField, ref: this.patchFieldRef}} data-cy={'patch-json-field'} />
                    <Button data-cy={'patch-redux-node'} variant='contained' onClick={() => this.patchNode(this.nodeIdFieldRef?.current?.value, this.patchFieldRef?.current.value)}>Patch</Button>
                </div>
                <div>
                    <h2>Indexeddb</h2>
                    <GraphClear/>
                    <Button data-cy={'download-state'} variant='contained' onClick={this.downloadState}>
                        Download IndexedDb
                    </Button>
                    <Button data-cy={'break-indexeddb'} variant='contained' onClick={this.breakIndexedDb}>
                        Break IndexedDb
                    </Button>
                    <Button data-cy={'break-storage'} variant='contained' onClick={this.breakStorage}>
                        Quota Exceeded
                    </Button>
                    <Button
                        data-cy={'clear-indexeddb-expire'}
                        variant='contained'
                        onClick={this.expireIndexeddbClear}
                        title={'Clear that cache clear has run'}
                    >
                        Expire Cache Clear
                    </Button>
                    <Button data-cy={'clear-db-5-min'} variant='contained' onClick={this.setClearDbAgeOld}>
                        Set 5 min Cache
                    </Button>
                    <Button data-cy={'clear-db-0-sec'} variant='contained' onClick={this.setClearDbAgeAll}>
                        Set 0 min Cache
                    </Button>
                    <Button data-cy={'clear-db'} variant='contained' onClick={this.clearDb}>
                        Clear Cache
                    </Button>
                    <Button data-cy={'cancel-db'} variant='contained' onClick={this.cancelClearDb}>
                        Cancel Clear Cache
                    </Button>
                    <Button data-cy={'no-image-cache'} variant='contained' onClick={this.preventImageCache}>
                        No Image Cache
                    </Button>
                </div>
                <div>
                    <h2>Logging</h2>
                    <Button data-cy={'cause-error'} variant='contained' onClick={this.causeError}>Cause Error</Button>
                    <Button data-cy={'track-exception'} variant='contained' onClick={GraphDebug.trackException}>Track
                        Exception</Button>
                    <Button data-cy={'track-trace'} variant='contained' onClick={GraphDebug.trackTrace}>Track
                        Trace</Button>
                    <Button data-cy={'track-event'} variant='contained' onClick={GraphDebug.trackEvent}>Track
                        Event</Button>
                    <Button data-cy={'track-event'} variant='contained' onClick={this.reportDeveloperWarning}>Developer
                        Warning</Button>
                    <Button data-cy={'success'} variant='contained' onClick={this.success}>Success</Button>
                    <Button data-cy={'toggle-diagnostics-mode'} variant='contained' onClick={this.toggleTroubleshootOn}>
                        <TrackChanges/> Troubleshoot Mode {troubleshootOn ? 'On' : 'Off'}
                    </Button>
                    <Button data-cy={'toggle-diagnostics-mode'} variant='contained' onClick={this.toggleDiagnosticMode}>
                        <TrackChanges/> Advanced Diagnostic Mode {diagnosticsOn ? 'On' : 'Off'}
                    </Button>
                </div>

                <div>
                    <h2>Security</h2>
                    <Button data-cy={'expire'} variant='contained' onClick={this.expireAuth0Token}>Expire Token</Button>
                    <Button data-cy={'storagePersist'} variant='contained' onClick={this.storagePersist}>Make storage
                        persistent</Button>
                    <Button data-cy={'break-next-login'} variant='contained' onClick={this.handleBreakNextLogin}>
                        Error during next login
                    </Button>
                    <Button data-cy={'kill-session'} variant='contained' onClick={this.killSession}>End Identity
                        Session</Button>
                </div>
                <div>
                    <h2>Offline</h2>
                    <Button
                        data-cy={'assigned-execution-expire-full'}
                        variant='contained'
                        onClick={this.expireNotFullAssignmentDownload}
                        title={'Set last check time to be 24 hours ago'}
                    >
                        Partially expire assigned download
                    </Button>
                    <Button
                        data-cy={'assigned-execution-expire-full'}
                        variant='contained'
                        onClick={this.expireFullAssignmentDownload}
                        title={'Set last check time to be 7 days ago'}
                    >
                        Fully expire assigned download
                    </Button>
                </div>
                <NodeTextField nodeId={NODE_IDS.UserSettings} nodePropertyName={'fastReloadOn'}/>
                <div>IndexedDb Count: <IndexedDbCount/></div>
                <div>Tile Count: <TileCount/></div>
                <div>Persistence
                    Storage: <IndexedPersisted/></div>
                {causeRenderError && strings.field.not.exists}

                <div>
                    Session is active: <Auth0ActiveSession/>
                </div>
                <div>
                    Token issued at: {user.tokenIssuedDate ? formatDate(user.tokenIssuedDate) : 'Unknown'}
                </div>
                <div>
                    Token expires at: {user.tokenExpiredDate ? formatDate(user.tokenExpiredDate) : 'Unknown'}
                </div>
                <div>
                    Last token renew
                    at: {user.lastRenewAttemptedDate ? formatDate(user.lastRenewAttemptedDate) : 'None'}
                </div>
                <div>
                    Next token renew at: {user.renewTokenAfterDate ? formatDate(user.renewTokenAfterDate) : 'Unknown'}
                </div>
                <div>{`Last OAuth error: ${oauthError}`}</div>
                <div>
                    Indexeddb Last Cleared: {formatDate(userDevice?.lastIndexeddbClearedDateTime) || 'Never'}
                </div>
                <div>
                    Indexeddb Clear Older Than: {userDevice?.indexeddbClearedAge / 1000 / 60} minutes
                </div>

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                            Unsaved Changes
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Root</TableCell>
                                    <TableCell>Type</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Dirty</TableCell>
                                    <TableCell>Save Attempts</TableCell>
                                    <TableCell>Errors</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((cells, rowIndex) => (
                                    <TableRow key={rowIndex}>
                                        {cells.map((cell, cellIndex) => (
                                            <TableCell key={cellIndex}>
                                                {cell}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>


                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                            ERRORS
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Error</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {errors.map((error, rowIndex) => (
                                    <TableRow key={rowIndex}>

                                        <TableCell>
                                            {formatTime(error.date)}
                                        </TableCell>
                                        <TableCell>
                                            {JSON.stringify(error.message || {}, null, 2)}
                                        </TableCell>
                                        <TableCell>
                                            {JSON.stringify(error.error || {}).substring(0, 100)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>


                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.title} variant="h1" color="inherit" noWrap>
                            DEBUG
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Table className={classes.table}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Message</TableCell>
                                    <TableCell>Data</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {debugs.map((debug, rowIndex) => (
                                    <React.Fragment key={rowIndex}>
                                        <TableRow>
                                            <TableCell>
                                                {formatTime(debug.date)}
                                            </TableCell>
                                            <TableCell>
                                                {JSON.stringify(debug.message || {}, null, 2)}
                                            </TableCell>
                                            <TableCell>
                                                {
                                                    debug.data &&
                                                    <IconButton
                                                        title={strings.buttons.diagnostics}
                                                        onClick={this.displayDebugData(rowIndex)}
                                                        size="large">
                                                        <InfoIcon/>
                                                    </IconButton>
                                                }
                                            </TableCell>
                                        </TableRow>
                                        {
                                            rowIndex === debugDataIndex &&
                                            <TableRow>
                                                <TableCell colSpan={3}>
                                            <pre>
                                            {JSON.stringify(debug.data || {}, null, 2)}
                                            </pre>
                                                </TableCell>
                                            </TableRow>
                                        }
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </AccordionDetails>
                </Accordion>

            </div>
        );
    }
}

const styles = () => ({
    textField: {
        height: "0.1em",
    }
});
GraphDebug.propTypes = {};
const mapStateToProps = (state) => {
    let dirtyNodes = getDirtyNodes(state);
    return {
        nodes: getNodes(state),
        dirtyNodes: dirtyNodes,
        state: state,
        user: getNodeOrNull(state, NODE_IDS.User),
        userDevice: getNodeOrNull(state, NODE_IDS.UserDevice)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        clearSaveError: node => dispatch(clearSaveError(node)),
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        pretendSaveStarted: () => dispatch(pretendSaveStarted())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphDebug));
