import React from 'react';
import {useChildRuleByActionOrNull, useNodeOrError, useNodesIfPresent} from "../../../hooks/nodeHooks";
import {LINK_TYPES, NODE_IDS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE} from "../../../reducers/graphReducer";
import ProcedureRuleBuilderContainer from "./ProcedureRuleBuilderContainer";
import GraphResourceLoad from "../../graph/GraphResourceLoad";

const ProcedureRuleFilterEditor = ({parentRuleId, disabled}) => {
    const parentRule = useNodeOrError(parentRuleId);
    const filterRule = useChildRuleByActionOrNull(parentRuleId, RULE_ACTION_TYPE.filter.id);
    const proceduresIds = parentRule.linkMatchProcedureIds || [];
    const procedures = useNodesIfPresent(proceduresIds)
    const loaded = filterRule && !filterRule.deleted && procedures.length === proceduresIds.length
        && procedures.find(a => !a.loadedFull) === undefined;
    if (!filterRule || filterRule.deleted) {
        return null;
    }
    return <>
        {
            proceduresIds.map(id => (<GraphResourceLoad
                key={id}
                resourcePath={NODE_IDS.ProcedureFull(id)}
                friendlyName={'Template'}
                nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}
            />))
        }
        {
            loaded &&
            <ProcedureRuleBuilderContainer
                key={proceduresIds.join('-')}
                nodeId={filterRule.id}
                includeProcedureIds={proceduresIds}
                ruleProperty={'condition'}
                includeLinks={false}
                disabled={disabled}
            />
        }
    </>
}
export default ProcedureRuleFilterEditor;