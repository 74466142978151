import {useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useState} from "react";
import {createNode} from "../factory/graphFactory";
import {putNodeProperty} from "../actions";
import {useNodeOrNull, useNodePropertyOrNull, useNodesById, useNodeSchemaOrError,} from "./nodeHooks";
import {NODE_TYPE_OPTIONS} from "../reducers/graphReducer";
import {reportInfo} from "tbf-react-library";
import {getActiveExecutionQuestionsSafe, hasExecutionPermission} from "../selectors/executionSelectors";
import {hasProcedurePermissionLoaded, isProcedureLoadedFull} from "../selectors/procedureSelectors";
import {Permissions} from "../permissions";
import {hasItems} from "../util/util";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {useCanAdd} from "./procedureHooks";
import {keyBy} from "lodash";


export const useCallbackExecutionLinkNew = (procedureId) => {

    const procedure = useNodeOrNull(procedureId)
    const schema = useNodeSchemaOrError(NODE_TYPE_OPTIONS.ExecutionLinkNew)
    const dispatch = useDispatch()

    return useCallback(() => {
        if (procedure) {
            const newNode = createNode(schema, {procedureId: procedureId})
            dispatch(putNodeProperty(newNode))
        } else {
            reportInfo('Template is not loaded')
        }
    }, [dispatch, procedure, procedureId, schema])
}

export const useHasExecutionPermission = (procedureId, permission) => {
    return useSelector(state => hasExecutionPermission(state, procedureId, permission))
}

export const useComputeExecutionOfflineStatus = (offline) => {
    if (!offline?.on) {
        return 'notenabled';
    }
    if (offline?.loadingError) {
        return 'loaderror'
    }
    if (offline?.loaded) {
        return 'loaded';
    }
    return offline?.loading ? 'loading' : 'notloaded';
}

export const useGetAddButtonLabel = (questionId) => {
    const question = useNodeOrNull(questionId);
    return question?.addButton;
}

export const useActiveExecutionQuestions = (procedureId) => {
    return useSelector(state => getActiveExecutionQuestionsSafe(state, procedureId));
}

export const useGetTemplateOptions = (questionId) => {
    const addOptions = useNodePropertyOrNull(questionId, a => a.linkedAddOptions)
    const procedureIds = addOptions?.map(linked => linked.procedureId) ?? [];
    const procedureNodes = useNodesById(procedureIds);
    const proceduresById = useMemo(() => {
        return keyBy(procedureNodes, node => node.id);
    }, [procedureNodes]);
    const canAdd = useCanAdd(addOptions);
    const [displayOptions, setDisplayOptions] = useState([]);

    useEffect(() => {
        const displayOptions_ = Object.keys(proceduresById).reduce((options, procedureId) => {
            const procedure = proceduresById[procedureId];
            if (!isProcedureLoadedFull(procedure)) {
                return options;
            }
            if(!hasProcedurePermissionLoaded(procedure, Permissions.execution.create)) {
                return options;
            }
            let invalidReason = null;
            if (hasItems(procedure.compileWarnings)) {
                invalidReason = `${strings.procedure.name} ${procedure.name} has errors.`;
            }

            const linkTypes = addOptions.map((o) => o.linkType);

            return [...options, {
                procedureName: (procedure && procedure.name) || 'Loading',
                procedureId,
                invalidReason,
                linkTypes,
            }];
        }, []) ?? [];

        setDisplayOptions(displayOptions_.sort((a, b) => (a.procedureName.localeCompare(b.procedureName))));
    }, [proceduresById, addOptions])
    

    return {
        canAdd,
        displayOptions: displayOptions,
        hasMultiOptions: displayOptions?.length > 1,
        hasOptions: displayOptions?.length > 0
    }
}