import {NODES_LOADED_SERVER, PUT_NODE_PROPERTY} from '../../../actions/types'
import {NODE_IDS} from '../../../reducers/graphReducer'
import {getNodeOrNull} from '../../../selectors/graphSelectors'
import get from 'lodash/get'
import uniq from 'lodash/uniq'
import {getMapSettings, isCachingMap} from '../selectors'
import reverse from 'lodash/reverse'
import {isOnline} from '../../../util/util'
import {putNodeProperty, SET_CLIENT_CONFIGURATION} from '../../../actions'
import isEmpty from 'lodash/isEmpty'
import {getMyAssignments} from '../../assignments/selectors'
import sortBy from 'lodash/sortBy'
import {uniqBy} from "lodash";
import {addMultiPointSave} from "../offline/leafletOfflineExtension";
import {tiledDynamicMapLayer} from "../TiledDynamicMapLayer";
import {tiledImageMapLayer} from "../TiledImageMapLayer";
import {createMapUrl} from "../MapUtils";

const moveLatLngByMetersApprox = (meters, point) => {
  // number of km per degree = ~111km (111.32 in google maps, but range varies between 110.567km at the equator and 111.699km at the poles)
  // 1km in degree = 1 / 111.32km = 0.0089
  // 1m in degree = 0.0089 / 1000 = 0.0000089
  const coef = meters * 0.0000089;
  const [lat, lng] = point
  return [lat + coef, lng + coef / Math.cos(lat * 0.018)]
}

const tileByZoomLevel = {};
const mapDownloadCancellationToken = {cancel: false}
const toggleMapData = async (store) => {
  // Lazy load the leaflet libraries
  const L = await import(/* webpackChunkName: "map" */ 'leaflet')
  await import(/* webpackChunkName: "map" */ 'leaflet.offline')

  const isRunning = isCachingMap(store.getState())
  // If we are still running the caching, do not run it again
  if (isRunning) {
    mapDownloadCancellationToken.cancel = true
    return
  }

  let offlineMaps = [];
  const layers = get(getMapSettings(store.getState()), ['layers']);
  for (let layer of layers) {
    for (let osmLayer of (layer.osmMapLayers || []).filter(a => a.offline)) {
      offlineMaps.push({
        ...osmLayer, layer: (options) => {
          let layer = L.tileLayer.offline(options.url);
          return layer;
        }
      });
    }
    for (let osmLayer of (layer.dynamicTiledLayers || []).filter(a => a.offline)) {
      offlineMaps.push({
        ...osmLayer, layer: (options, map) => {
          let layer = new tiledDynamicMapLayer(options);
          layer._map = map;
          return layer;
        }
      });
    }
    for (let osmLayer of (layer.imageTiledMapLayer || []).filter(a => a.offline)) {
      offlineMaps.push({
        ...osmLayer, layer: (options, map) => {
          let layer = new tiledImageMapLayer(options);
          layer._map = map;
          return layer;
        }
      });
    }
  }
  offlineMaps = uniqBy(offlineMaps, 'url');
  const assignments = getMyAssignments(store.getState())
  const userDeviceData = getNodeOrNull(store.getState(), NODE_IDS.UserDevice)
  const mapsOffline = get(userDeviceData, ['mapsOffline'], undefined)
  let cachedAssignments = get(userDeviceData, ['cachedAssignments'], [])

  if (mapsOffline === undefined) {
    // Need to wait for it to be loaded so we know what to do
    // Or the user has not set it on
  } else if (mapsOffline === false) {
    if (!isEmpty(cachedAssignments)) {
      store.dispatch(putNodeProperty({id: NODE_IDS.UserDevice, cachedAssignments: []}))
      // const request = indexedDB.deleteDatabase('leaflet.offline')
    }
  } else if (mapsOffline === true) {
    const assignmentsWithFeature = assignments.filter(a => a?.feature?.geometry?.coordinates)
    const assignmentsNeedingToBeCached = assignmentsWithFeature.filter(a => !cachedAssignments.includes(a.id))

    if (!isEmpty(assignmentsNeedingToBeCached) && !isEmpty(offlineMaps)) {
      console.info('Offline Download: Started');
      store.dispatch(putNodeProperty({id: NODE_IDS.UserDevice, cachingRunning: true}))

      mapDownloadCancellationToken.cancel = false
      for (let offlineMap of offlineMaps) {
        const {
          offlineZoomLevels,
          offlineBoundaryMeters,
          offlineBoundaryMultiplier,
          layer,
          ...rest
        } = offlineMap;
        console.info(`Offline Download: Map: ${offlineMap.name}: Started`);
        // URLS for integration are relative
        rest.url = createMapUrl(rest.url, rest.appHost);

        const element = document.createElement('div')
        const map = L.map(element)
        const tileLayer = layer(rest, map)
        tileLayer.addTo(map);

        let start = new Date().getTime();
        const zoomLevels = sortBy(offlineZoomLevels, x => -x)
        const saveAreas = [];
        //
        for (let assignment of assignmentsWithFeature) {
          const position = reverse([...assignment.feature.geometry.coordinates.slice(0, 2)])
          for (let zoomLevel of zoomLevels) {
            if (!tileByZoomLevel[zoomLevel]) {
              tileByZoomLevel[zoomLevel] = 0;
            }
            const meters = zoomLevel >= 18 ? offlineBoundaryMeters :
                Math.abs((zoomLevel - 18)) * offlineBoundaryMultiplier * offlineBoundaryMeters
            let bounds = L.latLngBounds()
            bounds.extend(moveLatLngByMetersApprox(-meters, position))
            bounds.extend(moveLatLngByMetersApprox(meters, position))
            let saveArea = {
              bounds,
              zoomLevel
            }
            saveAreas.push(saveArea)
          }
        }
        let finish = new Date().getTime();
        console.info(`Offline Download: Map: ${offlineMap.name}:  Computing areas took ${finish - start} ms`);

        await new Promise(async (resolve, reject) => {
          try {
            const saveControl = L.control.savetiles(tileLayer, {
              saveAreas: saveAreas,
              parallel: 1
            })
            addMultiPointSave(L, saveControl);
            saveControl.addTo(map)
            let totalTiles = 0;
            const onStoreStatus = (status, completed) => {
              console.info(`Offline Download: Map: ${offlineMap.name}`, status);
              totalTiles = status.lengthTotal || totalTiles
              const lengthAlreadySaved = totalTiles - status.lengthToBeSaved
              const downloaded = status.lengthLoaded + lengthAlreadySaved
              // For some reason is raises done when not
              const failedCount = completed ? totalTiles - downloaded : status.lengthFailed || 0
              const lengthAttempted = status.lengthLoaded + failedCount
              store.dispatch(putNodeProperty({
                id: NODE_IDS.UserDevice,
                mapsOfflineState: {
                  [offlineMap.name]: {
                    name: offlineMap.name,
                    total: totalTiles,
                    downloaded: downloaded,
                    progress: status.lengthToBeSaved ? lengthAttempted / status.lengthToBeSaved * 100 : 100,
                    failed: failedCount
                  }
                }
              }))
            }
            const onSaveEnd = (status) => {
              // we are using the same tile layer for all saves so remove the listener now so it does not get called again
              tileLayer.off('saveend', onSaveEnd)
              tileLayer.off('loadtileend', onStoreStatus)
              tileLayer.off('loadtilefailed', onStoreStatus)
              onStoreStatus(status, true)
              console.info(`Offline Download: Map: ${offlineMap.name}: Completed`, status);
              // Once the save has finished, resolve the promise
              resolve()
            }
            tileLayer.on('savestart', onStoreStatus)
            tileLayer.on('loadtileend', onStoreStatus)
            tileLayer.on('loadtilefailed', onStoreStatus)
            tileLayer.on('saveend', onSaveEnd)


            saveControl._saveTiles(mapDownloadCancellationToken)
          } catch (e) {
            console.info(`Offline Download: Map: ${offlineMap.name}: Failed: ${e}`);
            reject(e)
          }
        })
      }
      const revisedCachedAssignments = uniq([
        ...cachedAssignments,
        ...assignmentsNeedingToBeCached.map(a => a.id)
      ]);
      console.info('Offline Download: Completed');
      store.dispatch(putNodeProperty({
        id: NODE_IDS.UserDevice,
        cachingRunning: false,
        cachedAssignments: revisedCachedAssignments
      }))
    }
  }
}

const mapMiddleware = store => next => action => {
  const result = next(action)

  if (isOnline()) {
    // We are only interested in the changes to the assignmentsOffline property
    const offlineToggled = action.type === PUT_NODE_PROPERTY && get(action, ['payload', 'node', 'id']) === NODE_IDS.UserDevice && get(action, ['payload', 'node', 'mapsOffline'], undefined) !== undefined
    const myAssignmentsLoaded = (action.type === NODES_LOADED_SERVER && get(action, ['payload', 'resourceSync', 'id']) === NODE_IDS.MyAssignments)
    const clientConfigSet = action.type === SET_CLIENT_CONFIGURATION // When the client config is set, we also need to check the map offline settings
    if (offlineToggled || myAssignmentsLoaded || clientConfigSet) {
      toggleMapData(store)
    }
  } else {
    mapDownloadCancellationToken.cancel = true
  }

  return result
}

export default mapMiddleware
