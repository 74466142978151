import React from "react";
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {putNodeProperty} from "../../actions";
import {connect} from "react-redux";
import AssignmentsUserBadge from "../components/AssignmentsUserBadge";
import IconButton from "@mui/material/IconButton";
import MenuOpenRoundedIcon from '@mui/icons-material/MenuOpenRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import ExecutionStatusWithDetails from "./ExecutionStatusWithDetails";
import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import {strings} from "../components/SopLocalizedStrings";
import {getActiveSteps} from "../../selectors/executionSelectors";
import {withMobile} from "tbf-react-library";

class ExecutionTreeLayoutStepDetails extends React.Component {

    componentDidMount() {
        const {mobileViewPort, onPutNodeProperty, executionId} = this.props;

        if(mobileViewPort) {
            onPutNodeProperty({id: executionId, treeViewToggleState: false});
        }
    }

    handleTreeToggle = () => {
        let {executionId, treeViewToggleState, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: executionId, treeViewToggleState: !treeViewToggleState});
    }

    handleTabChange = (stepIndex) => {
        let {executionId, onPutNodeProperty} = this.props;
        onPutNodeProperty({id: executionId, selectedStepIndex: stepIndex});
    };

    render() {
        const {classes, stepId, stepName, totalSteps, selectedStepIndex, treeViewToggleState, showStepper, mobileViewPort} = this.props;

        return <div className={classes.outerWrapper} data-cy-element={'TocStepHeader'}>
            <div style={{alignItems: !showStepper ? "center": undefined }} className={classes.container}>
                <IconButton
                    className={classes.toggleButton}
                    onClick={this.handleTreeToggle}
                    size={'small'}
                    data-cy={ treeViewToggleState ? 'tree-view-close': 'tree-view-open'}
                    title={`${treeViewToggleState ? strings.execution.procedureViewType.treeView.closeNavigator : strings.execution.procedureViewType.treeView.openNavigator}`}>
                    {treeViewToggleState ? <MenuOpenRoundedIcon/> : <MenuRoundedIcon/>}
                </IconButton>
                <div className={classes.innerWrapper}>
                    <div className={classes.header}>
                        <div className={classes.stepName}>{stepName}</div>
                        <div className={classes.secondaryDetails}>
                            <ExecutionStatusWithDetails nodeId={stepId} showStatusName={!mobileViewPort}/>
                        </div>
                        <div className={classes.secondaryDetails}>
                            <AssignmentsUserBadge nodeId={stepId}/>
                        </div>
                    </div>

                    {
                        showStepper &&
                        <div className={classes.actions}>
                            <IconButton className={classes.actionButton}
                                        disabled={selectedStepIndex === 0}
                                        size={'small'}
                                        title={strings.buttons.goPreviousStep}
                                        onClick={() => this.handleTabChange(selectedStepIndex - 1)}>
                                <ChevronLeftRoundedIcon/>
                            </IconButton>

                            <span className={classes.counter}>Step {selectedStepIndex + 1} of {totalSteps}</span>

                            <IconButton className={classes.actionButton}
                                        disabled={selectedStepIndex === totalSteps - 1}
                                        size={'small'}
                                        title={strings.buttons.goNextStep}
                                        onClick={() => this.handleTabChange(selectedStepIndex + 1)}>
                                <ChevronRightRoundedIcon/>
                            </IconButton>
                        </div>
                    }
                </div>
            </div>
        </div>;
    }
}

const styles = theme => ({
    outerWrapper: {
        paddingBottom: theme.spacing()
    },
    container: {
        display: 'flex',
        background: theme.palette.primary.two.main,
        padding: '3px 10px 5px 10px',
        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
        borderRadius: 4,
        marginBottom: 5,
        alignItems: 'end',
    },
    innerWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingTop: 2,
    },
    toggleButton: {
        color: theme.palette.grey.four.main,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
    },
    stepName: {
        flexGrow: 1,
        marginLeft: 12,
        fontFamily: theme.fonts.primary.bold,
        color: theme.palette.grey.seven.main,
    },
    secondaryDetails: {
        '& .userName, & .statusName': {
            fontSize: '12px !important',
        }
    },
    actions: {
        display: 'flex',
        alignItems: 'center',
    },
    actionButton: {
        color: theme.palette.grey.seven.main,
        margin: '0 2px',
    },
    counter: {
        color: theme.palette.grey.seven.main,
        fontSize: 12,
    }
});

ExecutionTreeLayoutStepDetails.propTypes = {
    executionId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, ownProps) => {
    let executionNode = getNodeOrNull(state, ownProps.executionId);
    const visibleSteps = getActiveSteps(state, ownProps.executionId);
    let selectedStepIndex = executionNode.selectedStepIndex || 0;
    if (selectedStepIndex >= visibleSteps.length) {
        selectedStepIndex = visibleSteps.length - 1;
    }
    const selectedStep = visibleSteps[selectedStepIndex];
    const stepAssignments = getNodesById(state, (selectedStep && selectedStep.assignments) || []).filter(a => a.deleted !== true);

    return {
        stepName: selectedStep?.name,
        stepId: selectedStep?.id,
        assignments: stepAssignments.map(d => ({userName: d.entityName, id: d.id})),
        totalSteps: visibleSteps.length,
        selectedStepIndex: selectedStepIndex,
        treeViewToggleState: executionNode.treeViewToggleState,
        showStepper: visibleSteps?.length > 1
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withMobile()(withStyles(styles)(ExecutionTreeLayoutStepDetails)));
