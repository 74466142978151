import React from 'react';
import withStyles from '@mui/styles/withStyles';
import PropTypes from "prop-types";
import ImportExportIcon from "@mui/icons-material/ImportExportRounded";
import {strings} from "../components/SopLocalizedStrings";
import {ComponentBase, TbfMenuItem} from "tbf-react-library";
import DocumentShowExport from "../graph/DocumentShowExport";
import AlphaFeature from "../components/AlphaFeature";

const pageStrings = strings.execution.show;

class DocumentExportButton extends ComponentBase {

    constructor(props) {
        super(props);
        this.state = {download: false}
        this.handleDownload = this.handleDownload.bind(this);
    }

    handleDownload() {
        const {handleClicked} = this.props;
        handleClicked && handleClicked();
        this.setState({download: true});
    }

    render() {
        let {nodeId} = this.props;
        let {download} = this.state;
        return (
            <AlphaFeature>
                <TbfMenuItem iconComponent={ImportExportIcon} onClick={this.handleDownload} dataCy='export'>
                    {pageStrings.exportButton}
                </TbfMenuItem>
                {download && <DocumentShowExport nodeId={nodeId}/>}
            </AlphaFeature>);
    }
}

const styles = () => ({});
DocumentExportButton.propTypes = {
    nodeId: PropTypes.string.isRequired,
};
export default withStyles(styles)(DocumentExportButton);
