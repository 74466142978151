import React from "react";
import {withRouter} from "react-router";
import withStyles from '@mui/styles/withStyles';
import {IconButton} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import ProgressWithLabel from "../components/ProgressWithLabel";
import {getProcedureTypeIcon} from "../../util/image";
import AssignmentStatus from "./AssignmentStatus";
import Button from "@mui/material/Button";
import {getNodeOrNull, getNodesById} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import TbfAvatar from "../components/common/TbfAvatar";
import {MINUTES_5} from "../../util/constants";

class AssignmentDetails extends React.Component {

    routeToExecution = () => {
        const {history, executionId} = this.props;
        history.push(`/executions/${executionId}`);
    }

    render() {
        const {
            classes,
            handleCloseProgressPopover,
            procedureType,
            nodeTitle,
            status,
            executionId,
            totalQuestionCount,
            completedQuestionCount,
            itemAssignmentsUsers,
        } = this.props;

        return <React.Fragment>
            <GraphResourceLoad
                nodeId={executionId}
                friendlyName={strings.execution.namePlural}
                nodeType={'ExecutionRoot'}
                reloadIntervalMs={MINUTES_5}
                hideLoader={true}
            />
            <div className={classes.progressPopoverContainer}>
                <div className={'header'}>
                <span className={'mainText'}>
                    <img className={'icon'}
                         src={getProcedureTypeIcon(procedureType)}
                         alt={''}/>
                    <span className={'title'}>{nodeTitle}</span>
                </span>
                    <div className={classes.grow}/>
                    <IconButton size={'small'} className={'closeButton'}
                                title={strings.buttons.closeDetailsPopover}
                                onClick={handleCloseProgressPopover}>
                        <CloseIcon/>
                    </IconButton>
                </div>
                <div className={'content'}>
                    <div className={classes.marginBottom10 + ' section'}>
                        <div className={'sectionTitle'}>Status</div>
                        <div className={classes.marginBottom10}>
                            <AssignmentStatus status={status}/>
                        </div>
                        <div>
                            <ProgressWithLabel total={totalQuestionCount}
                                               completed={completedQuestionCount}/>
                        </div>
                    </div>
                    {
                        itemAssignmentsUsers && itemAssignmentsUsers.length > 0 &&
                        <div className={'section'}>
                            <div className={'sectionTitle'}>Assigned To</div>

                            {
                                itemAssignmentsUsers.map((user, index) => {
                                    return <div key={index} className={classes.userWrapper}>
                                        <TbfAvatar name={user.entityName}/>
                                        <span className={'userName'}>{user.entityName}</span>
                                    </div>;
                                })
                            }
                        </div>
                    }
                </div>
                <div className={'footer'}>
                    <Button title={strings.buttons.open} onClick={this.routeToExecution} className={'openButton'}
                            color='primary'
                            variant='contained'>
                        Open
                    </Button>
                </div>
            </div>
        </React.Fragment>
    }
}

const styles = theme => ({
    progressPopoverContainer: {
        background: theme.palette.primary.two.main,
        maxWidth: 500,
        minWidth: 300,
        '& .header': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            color: theme.palette.grey.seven.main,
            fontFamily: theme.fonts.primary.bold,
            paddingRight: 40,
            borderBottom: `1px solid ${theme.palette.grey.two.main}`,
            '& .closeButton': {
                position: 'absolute',
                right: 3,
                top: 3,
            },
            '& .mainText': {
                display: 'flex',
                alignItems: 'center',
                '& .icon': {
                    width: 30,
                    marginRight: 8,
                },
                '& .title': {
                    fontSize: 14,
                    color: theme.palette.grey.seven.main,
                    fontFamily: theme.fonts.primary.bold,
                }
            },
        },
        '& .content': {
            padding: '5px 20px 12px 20px',
            '& .section': {
                padding: '10px 0',
                borderBottom: `1px solid ${theme.palette.grey.two.main}`,
                '&:last-child': {
                    borderBottom: 'none',
                },
                '& .sectionTitle': {
                    fontSize: 12,
                    fontFamily: theme.fonts.primary.bold,
                    marginBottom: 15
                }
            }
        },
        '& .footer': {
            display: 'flex',
            alignItems: 'center',
            padding: '10px 15px',
            borderTop: `1px solid ${theme.palette.grey.two.main}`,
            '& .openButton': {
                width: '100%'
            }
        },
        '& .subText': {
            color: theme.palette.grey.four.main,
            fontSize: 12,
            marginTop: 10,
            display: 'block',
        }
    },
    marginBottom10: {
        marginBottom: 10
    },
    userWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
        '& .userInitials': {
            width: 30,
            height: 30,
            marginRight: 10,
            fontSize: 12,
            backgroundColor: theme.palette.primary.one.main
        },
        '& .userName': {
            fontSize: 14,
            color: theme.palette.grey.seven.main,
        }
    },
});

const mapStateToProps = (state, ownProps) => {
    const assignment = getNodeOrNull(state, ownProps.assignmentId);
    const itemNode = getNodeOrNull(state, assignment.nodeId);
    const itemAssignments = getNodesById(state, (itemNode && itemNode.assignments) || []).filter(a => a.deleted !== true);
    let totalQuestionCount = 0;
    let completedQuestionCount = 0;
    if (itemNode) {
        if (itemNode.type === 'ExecutionStep') {
            const tasks = getNodesById(state, itemNode.children);
            for (let task of tasks) {
                const questions = getNodesById(state, task.children);
                const completedQuestions = questions.filter(question => question.completed);
                totalQuestionCount = totalQuestionCount + questions.length + (task.completeEnabled ? 1 : 0);
                completedQuestionCount = completedQuestionCount + completedQuestions.length + (task.completeEnabled && task.completed ? 1 : 0);
            }
        } else if (itemNode.type === 'ExecutionTask') {
            const questions = getNodesById(state, itemNode.children);
            const completedQuestions = questions.filter(question => question.completed);
            totalQuestionCount = questions.length + (itemNode.completeEnabled ? 1 : 0);
            completedQuestionCount = completedQuestions.length + (itemNode.completeEnabled && itemNode.completed ? 1 : 0);
        }
    }

    return {
        totalQuestionCount: totalQuestionCount,
        completedQuestionCount: completedQuestionCount,
        itemAssignmentsUsers: itemAssignments,
        procedureType: assignment.procedureType,
        nodeTitle: assignment.nodeTitle,
        executionId: assignment.executionId,
        status: assignment.status,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(AssignmentDetails)));
