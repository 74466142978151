import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {connect} from "react-redux";
import '../../style/alert.css';
import {withRouter} from "react-router-dom";
import * as PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FullscreenIcon from "@mui/icons-material/FullscreenRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";
import {getLoadedHelpManualOrNull, getLoadedHelpPointOrNull} from "../selectors/loadHelpPointSelectors";
import LoadHelpPointDialog from "./LoadHelpPointDialog";
import {toggleHelpPointDisplay} from "../actions/loadHelpPointActions";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import {strings} from "../../layouts/components/SopLocalizedStrings";
import Loader from "../../layouts/components/Loader";
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import forEach from 'lodash/forEach'
import last from 'lodash/last'

customElements.define('scoped-document',
    class extends HTMLElement {
        addDocument = (body) => {
            var style = document.createElement('style');
            style.textContent = `
            .hide { 
                display: none;
            }
            table, p {
              margin-bottom: 16px !important;
            }
        `;

            const shadowRoot = this.attachShadow({mode: 'open'})
            shadowRoot.appendChild(style)
            shadowRoot.appendChild(body)
        }
    }
);

class HelpPointInline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showHelpDialog: false,
            helpDocumentHtml: '',
        };
        this.strippedNonBookmarks = false;
        this.parsedDocumentContainer = React.createRef();
        this.parsedDocumentInnerWrapper = React.createRef();
    }

    componentDidMount() {
        const {canReadHelpPoint, helpDocumentHtml} = this.props;
        if (canReadHelpPoint && helpDocumentHtml) {
            this.renderHelpPoint();
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {helpPointLoaded, helpDocumentHtml, canReadHelpPoint, helpPointToggleState} = this.props;
        if (canReadHelpPoint && helpDocumentHtml) {
            this.renderHelpPoint();
            this.strippedNonBookmarks = helpPointLoaded && helpDocumentHtml ? helpPointToggleState : false;
        }
    }

    renderHelpPoint = () => {
        const {helpDocumentHtml, helpPointLoaded, helpPointToggleState, bookmarkIds} = this.props;
        if (helpPointLoaded && helpPointToggleState && helpDocumentHtml && !this.strippedNonBookmarks) {
            this.strippedNonBookmarkContent(bookmarkIds);
            this.strippedNonBookmarks = true;
        }
    }

    // This will traverse the dom tree and hide any elements that do not contain a bookmark in
    // any of their child elements.
    traverseDomAndHideNodesIfNotBookmarked = (element, bookmarkIds) => {
        var bookmarkId = get(last(element.childNodes), ['id'])
        if (bookmarkId && bookmarkIds.includes(bookmarkId)) {
            return true
        }
        var show = false
        forEach(element.childNodes, node => {
            show = this.traverseDomAndHideNodesIfNotBookmarked(node, bookmarkIds) || show
        })
        if (!show && element.classList && element.nodeType === 1) {
            element.classList.add('hide')
        }
        return show
    }

    strippedNonBookmarkContent = (bookmarkIds) => {
        const {helpDocumentHtml} = this.props
        const documentContent = document.createElement('div')
        documentContent.innerHTML = helpDocumentHtml

        this.traverseDomAndHideNodesIfNotBookmarked(documentContent, bookmarkIds)

        // TODO: The document is scoped in a shadow dom because the css can conflict with other documents on the page.
        // Would be good to move this into its own component and use this whenever we render html from a work document
        const shadow = document.createElement('scoped-document')
        shadow.addDocument(documentContent)
        this.parsedDocumentInnerWrapper.current.appendChild(shadow)
    }

    handleOpenHelpDialog = () => {
        this.setState({showHelpDialog: true});
    };

    handleCloseHelpDialog = () => {
        this.setState({showHelpDialog: false});
    };

    render() {
        let {
            classes,
            helpPointExternalId,
            helpPointLoaded,
            helpPointToggleState,
            hasBookmarks,
            helpDocumentHtml,
            onToggleHelpPointDisplay,
            canReadHelpPoint
        } = this.props;
        let {showHelpDialog} = this.state;

        if (!canReadHelpPoint) {
            return <React.Fragment/>
        }

        return (
            <React.Fragment>
                {
                    helpPointLoaded && hasBookmarks && helpPointToggleState &&
                    <React.Fragment>
                        <div className={classes.parseDocumentContainer} ref={this.parsedDocumentContainer}>
                            {
                                !helpDocumentHtml ?
                                    <div className={classes.progressIndicatorWrapper}>
                                        <Loader circular={true} circularSize={40} loaderStyleClass={classes.fabProgress}
                                                source={'Help Point Inline'}/>
                                    </div> :
                                    <div className={classes.innerWrapper}>
                                        <div className={classes.toolbarContainer}>
                                            <IconButton
                                                title={strings.manuals.helpDocument.fullScreenAction}
                                                className={classes.actionButton}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    onToggleHelpPointDisplay(helpPointExternalId, false);
                                                }}
                                                size="large">
                                                <CloseIcon/>
                                            </IconButton>
                                            <Tooltip title={strings.manuals.helpDocument.fullScreenAction}>
                                                <IconButton
                                                    title={strings.manuals.helpDocument.fullScreenAction}
                                                    className={classes.actionButton}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.handleOpenHelpDialog();
                                                    }}
                                                    size="large">
                                                    <FullscreenIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        <div className={classes.content} ref={this.parsedDocumentInnerWrapper}/>
                                    </div>
                            }
                        </div>
                        {
                            showHelpDialog &&
                            <LoadHelpPointDialog
                                helpPointExternalId={helpPointExternalId}
                                closeHelpModal={this.handleCloseHelpDialog}/>
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const styles = (theme) => ({
    parseDocumentContainer: {
        width: '100%',
        margin: `${theme.spacing(3)} 0`,
        position: 'relative',
        minHeight: theme.spacing(4)
    },
    innerWrapper: {
        border: '1px solid #eadbdb',
        width: '100%',
        padding: '5px 20px 20px 20px',
        background: '#ffffff',
        boxShadow: '0 0 3px #f1ebeb',
        borderRadius: 3,
    },
    toolbarContainer: {
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        justifyContent: 'flex-end',
        borderBottom: '1px solid #ccc'
    },
    content: {
        paddingTop: theme.spacing(8)
    },
    actionButton: {},
    progressIndicatorWrapper: {
        width: '100%',
        textAlign: 'center'
    }
});

HelpPointInline.propTypes = {
    helpPointExternalId: PropTypes.string.isRequired
};
const mapStateToProps = (state, ownProps) => {
    const helpPoint = getLoadedHelpPointOrNull(state, ownProps.helpPointExternalId);
    const helpDocumentHtml = helpPoint ? getLoadedHelpManualOrNull(state, helpPoint.manualId) : null;
    const bookmarkIds = get(helpPoint, ['manualBookmarkIds'])
    return {
        helpPointLoaded: !!helpPoint,
        helpPointToggleState: helpPoint ? helpPoint.toggleState : false,
        helpPointHelpDocumentExternalId: helpPoint ? helpPoint.helpDocumentExternalId : null,
        helpDocumentHtml: helpDocumentHtml,
        canReadHelpPoint: SharedAuth.userHasPermission(Permissions.manuals.read),
        bookmarkIds,
        hasBookmarks: !isEmpty(bookmarkIds)
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onToggleHelpPointDisplay: (helpPointExternalId, toggleState) => dispatch(toggleHelpPointDisplay(helpPointExternalId, toggleState)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(HelpPointInline)));
