import React, {useCallback, useEffect, useState} from "react";
import {Permissions} from "../../../permissions";
import {reportBusinessError, TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import {useComputeExecutionOfflineStatus, useHasExecutionPermission} from "../../../hooks/executionHooks";
import {useCallbackPatchNode, useNodePropertyOrNull} from "../../../hooks/nodeHooks";
import OfflinePinIcon from '@mui/icons-material/OfflinePin';
import OfflinePinOutlinedIcon from '@mui/icons-material/OfflinePinOutlined';
import ErrorIcon from '@mui/icons-material/Error';
import {NODE_IDS} from "../../../reducers/graphReducer";

let previousStatus = null
const ExecutionOfflineToolbarAction = React.forwardRef(({executionId}, ref) => {
    const offlineExecutions = useNodePropertyOrNull(NODE_IDS.UserDevice, a => a.offlineExecutions) || {}
    const offlineExecution = offlineExecutions[executionId]
    const offline = !!offlineExecution?.on
    const implicit = !!offlineExecutions[executionId]?.onImplicit
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)
    let onClick = useCallbackPatchNode({
        id: NODE_IDS.UserDevice,
        offlineExecutions: {...offlineExecutions, [executionId]: {on: false}}
    })
    let onClickImplicit = useCallback(() => {
        reportBusinessError(strings.execution.show.offlineImplicitly)
    })

    // Status
    // If not loaded, and recently loading, we will pretend it is still loading for a few seconds
    // because of the way throttling works without this the loader keeps disappearing
    let status = useComputeExecutionOfflineStatus(offlineExecution)
    const [loadingUntilDate, setLoadingUntilDate] = useState(null)
    useEffect(() => {
        if (previousStatus === 'loading' && status === 'notloaded') {
            setLoadingUntilDate(true)
            setTimeout(() => {
                setLoadingUntilDate(false);
            }, 5000)
        }
        previousStatus = status
    }, [status, previousStatus, loadingUntilDate, setLoadingUntilDate])

    if (loadingUntilDate && status === 'notloaded') {
        status = 'loading'
    }

    if (!canView || !offline) {
        return null
    }
    let color = 'inherit';
    let title = strings.execution.show.offlineNotLoadedTitle
    let icon = OfflinePinOutlinedIcon;
    if (status === 'loaded') {
        color = 'primary'
        title = strings.execution.show.offlineLoadedTitle
        icon = OfflinePinIcon
    } else if (status === 'loading') {
        color = 'secondary'
        title = strings.execution.show.offlineLoadingTitle
    } else if (status === 'loaderror') {
        color = 'errorRed'
        title = strings.execution.show.offlineLoadErrorTitle
        icon = ErrorIcon
    } else if (status === 'notloaded') {
        color = 'secondary'
    }
    return (
        <TbfAction
            iconButton={true}
            title={title}
            dataCy='available-offline-off'
            iconComponent={icon}
            onClick={implicit ? onClickImplicit : onClick}
            ref={ref}
            color={color}
            loading={status === 'loading'}
        >
        </TbfAction>
    )
})
export default ExecutionOfflineToolbarAction;
