import React, {useCallback, useMemo, useState} from "react";
import {strings} from "../../components/SopLocalizedStrings";
import withStyles from '@mui/styles/withStyles';
import ExecutionShowLinkList from "./ExecutionShowLinkList";

const itemLimit = 5;
const ExecutionShowLinkGroup = ({classes, type, list, linkedExecutions, canRemoveLink}) => {
    const [itemNumberShown, setItemNumberShown] = useState(itemLimit);

    const itemsShown = useCallback((items) => {
        return items.slice(0, itemNumberShown);
    }, [itemNumberShown]);

    const showingAllItems = useMemo(() => {
        return itemNumberShown === list.length;
    }, [itemNumberShown, list]);

    const toggleList = () => {
        const limit = itemNumberShown === list.length ? itemLimit : list.length;
        setItemNumberShown(limit);
    };

    const sortByLinkKey = useCallback((list) => {
        return list.sort((a, b) => a?.toNodeKey?.toLowerCase().localeCompare(b?.toNodeKey?.toLowerCase()));
    }, []);

    return <div key={type} data-cy={type}>
        <div className={classes.linkSectionHeader}>
            <label className={classes.linkTypeLabel}>{type}</label>
            <label className={'muiToolbarCountBadge'}>{list.length}</label>
        </div>
        <ExecutionShowLinkList linkedExecutions={linkedExecutions} canRemoveLink={canRemoveLink}
                               items={itemsShown(sortByLinkKey(list))}/>
        <div className={classes.footer}>
            {
                (list.length > itemLimit) &&
                <a
                    role={'button'}
                    className={`${classes.showMoreItemsButton} ${showingAllItems ? 'showingAllItems' : ''}`}
                    title={showingAllItems ? strings.buttons.showLess : strings.buttons.showMore}
                    data-cy={`${type}-show-more-button`}
                    onClick={toggleList}>
                    {showingAllItems ? strings.buttons.showLess : `${strings.buttons.showMore} (${itemNumberShown} of ${list.length})`}
                </a>
            }
        </div>
    </div>
}

const styles = (theme) => ({
    linkSectionHeader: {
        display: 'flex',
        alignItems: 'center'
    },
    footer: {
        marginBottom: 18,
    },
    grow: {
        flexGrow: 1,
    },
    linkTypeLabel: {
        color: theme.palette.grey.seven.main,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        marginRight: 5,
    },
    showMoreItemsButton: {
        fontSize: '0.75rem',
        color: theme.palette.grey.seven.main,
        textTransform: 'none',
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.grey.six.main,
            textDecoration: 'underline',
        },
    },
});

export default withStyles(styles)(ExecutionShowLinkGroup);
