import {strings} from "../layouts/components/SopLocalizedStrings";

export const GLOBAL_SEARCH_TYPES = {
    workItems: {id: 'workItems', name: strings.execution.workItems.title},
    templates: {id: 'templates', name: 'Lists'},
    users: {id: 'users', name: 'People'},
    teams: {id: 'teams', name: 'Teams'},
}


export const MINUTES_1 = 1 * 60 * 1000;
export const MINUTES_2 = 2 * 60 * 1000;
export const MINUTES_5 = 5 * 60 * 1000;
export const MINUTES_15 = 15 * 60 * 1000;
export const HOURS_1 = 60 * 60 * 1000;
export const HOURS_24 = 24 * 60 * 60 * 1000;
export const DAYS_7 = 7 * 24 * 60 * 60 * 1000;