import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodesByCondition} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import ProjectAddButton from "./ProjectAddButton";
import PageComponentBase from "../PageComponentBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import cloneDeep from "lodash/cloneDeep";
import {Permissions} from "../../permissions";
import {intersect} from "../../util/util";
import {strings} from "../components/SopLocalizedStrings";
import {
    Breadcrumbs,
    PageContentWrapper,
    PageHeader,
    ProjectIcon,
    SharedAuth
} from "tbf-react-library";
import {NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import ProjectGrid from './ProjectGrid';



class ProjectList extends PageComponentBase {


    constructor(props, context) {
        super(props, context);
        this.toggleDisplayDeleted = this.toggleDisplayDeleted.bind(this);
        this.state = {
            displayDeleted: false,
            selectedFilter: 'mine'
        };
    }

    toggleDisplayDeleted = (deleted) => {
        this.setState({displayDeleted: deleted});
    };

    changeFilter = (name) => {
        this.setState({selectedFilter: name});
    };

    render() {
        let {projectNodes, canDelete, userGroups, canViewAllProjects} = this.props;
        let {displayDeleted, selectedFilter} = this.state;
        const extraPath = displayDeleted ? '?includeDeleted=true' : '';
        let projects = projectNodes;
        if (canViewAllProjects && selectedFilter === 'mine') {
            projects = projects.filter(a => intersect(userGroups || [], a.assignedGroups || []).length > 0);
        }
        return <>
            <PageHeader
                title={strings.project.namePlural}
                PageIcon={<img src={ProjectIcon} alt={''}/>}
                showMenuToggle={true}
                renderPageActions={() => (<ProjectAddButton/>)}
            />
            <Breadcrumbs list={[]}/>
            <PageContentWrapper>
                <GraphResourceLoad
                    key={`/projects${extraPath}`}
                    resourcePath={`/projects${extraPath}`}
                    friendlyName={'projects'}
                    nodeType={NODE_TYPE_OPTIONS.ProjectRoot}
                >
                    <div className={`materialTableResponsiveWrapper`}>
                        <ProjectGrid
                            projects={projects}
                            filter={selectedFilter}
                            onFilterChanged={this.changeFilter}
                            allowToggleDeleted={canDelete}
                            onToggleDeleted={this.toggleDisplayDeleted}
                        />
                    </div>
                </GraphResourceLoad>
            </PageContentWrapper>
        </>
    }
}

const styles = (theme) => ({
    addLink: {textDecoration: 'none'},
    grow: {
        flexGrow: 1,
    },
    filter: {
        paddingLeft: theme.spacing(2)
    },
});
ProjectList.propTypes = {};
const mapStateToProps = (state) => {
    let projectsFilter = {type: 'ProjectRoot'};
    let projects = getNodesByCondition(state, projectsFilter);
    return {
        canDelete: SharedAuth.userHasPermission(Permissions.project.delete),
        projectNodes: cloneDeep(projects),
        userGroups: SharedAuth.getGroups(),
        canViewAllProjects: SharedAuth.userHasPermission(Permissions.project.list)
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(ProjectList)));
