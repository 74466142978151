import React from "react";
import {getProcedureTypeIcon} from "../../util/image";
import withStyles from '@mui/styles/withStyles';
import { Link } from "react-router-dom/cjs/react-router-dom.min";

export const TemplateResultCard = ({classes, data, optionGrouping, onLinkClick, link}) => {

    const Wrapper = link ? ({children}) => <Link to={`/list/${data.procedureType}/${data.value}`} onClick={onLinkClick}>
        {children}
    </Link> : React.Fragment;

    return <React.Fragment>
        <label className={classes.customOptionTitle}>
            <img className={'optionIcon'} src={getProcedureTypeIcon(data.procedureType)}
                 alt={'Procedure Type Icon'}/>
            <Wrapper>
                <span className={`procedureName option_name ${optionGrouping ? 'typeLink' : ''}`}
                        title={data.label}>{data.label}</span>
            </Wrapper>
        </label>
        {
            data.categories && data.categories.length &&
            <div className={classes.categoryContainer}>
                {
                    data.categories.map((category, index) => <label key={index}
                                                                    className={'categoryTag'}>{category}</label>)
                }
            </div>
        }
    </React.Fragment>
}


const ExecutionSelectTemplateOption =
    React.forwardRef(({
                          innerProps,
                          classes,
                          data,
                          isFocused,
                          isSelected,
                          disabled,
                          optionGrouping,
                          onLinkClick,
                          link
                      }, ref) => {

        return (
            <div ref={ref} {...innerProps}
                 role={'listitem'}
                 className={
                     classes.customOptionContainer +
                     ' select__option' +
                     (isFocused ? ' select__option--is-focused' : '') +
                     (isSelected ? ' select__option--is-selected' : '') +
                     (disabled ? ' select__option--is-disabled' : '')
                 }>
                <TemplateResultCard classes={classes} data={data} optionGrouping={optionGrouping} onLinkClick={onLinkClick} link={link} />
            </div>
        )
    });

const styles = theme => ({
    customOptionContainer: {
        width: '100%',
        padding: '7px 15px',
        float: 'left',
        transition: 'background .3s'
    },
    customOptionTitle: {
        width: '100%',
        float: 'left',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        '& .optionIcon': {
            marginRight: 8,
        },
        '& .typeLink': {
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
        '& .procedureName': {
            color: theme.palette.primary.three.main,
        },
    },
    grow: {
        flexGrow: 1,
    },
    categoryContainer: {
        marginTop: 4,
        display: 'block',
        float: 'left',
        paddingLeft: 28,
        '& .categoryTag': {
            background: theme.palette.grey.one.main,
            color: theme.palette.grey.seven.main,
            fontSize: 10,
            marginRight: 4,
            padding: '2px 8px',
            borderRadius: 4,
        },
    },
});

export default withStyles(styles)(ExecutionSelectTemplateOption);
