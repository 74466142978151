import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrError, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import {blurNodeProperty, focusNodeProperty, putNodeProperty, putNodesProperty} from "../../actions";
import 'date-fns';
import ExecutionQuestionInputBase from "../execution/question/input/ExecutionQuestionInputBase";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "./SopLocalizedStrings";
import {LOCATION_MODES, NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import ExecutionSelect, {
    mapExecutionToOption,
    mapProcedureToOption,
    mapTeamToOption,
    mapUserToOption
} from "./ExecutionSelect";
import {EMPTY_ARRAY, hasValue, inArray, searchAndSortByRank} from "../../util/util";
import {createNode} from "../../factory/graphFactory";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import {reportBusinessError} from "tbf-react-library";
import cloneDeep from "lodash/cloneDeep";
import IconButton from "@mui/material/IconButton";
import LocationOn from "@mui/icons-material/LocationOn";
import {GLOBAL_SEARCH_TYPES, MINUTES_5} from "../../util/constants";
import {isLocalhost} from "../../registerServiceWorker";

class ExecutionSelector extends ExecutionQuestionInputBase {

    constructor(props, context) {
        super(props, context);
        this.state = {
            inputFocused: false,
            filteredSearchWaiting: false,
            searchValue: this.props.searchTerm,
        };

        this.handleUseLocation = this.handleUseLocation.bind(this);
    }

    static defaultProps = {
        renderOptionKey: true
    }

    mounted = true;

    searchCursorPositions = null;

    componentWillUnmount() {
        this.mounted = false;
    }

    handleBlur = () => {
        const {handleBlurElement} = this.props;
        handleBlurElement && handleBlurElement();
        this.setState({inputFocused: false})
    }
    handleFocus = () => {
        const {handleFocusElement} = this.props;
        handleFocusElement && handleFocusElement();
        this.setState({inputFocused: true});
    }

    handleChangeSelect = (selection) => {
        this.props.handleChangeSelect({id: selection.value, title: selection.label});
    };

    handleUseLocation = () => {
        let {propertyName} = this.props;
        this.handleSearchTermChange(strings.location.searchNearMe);
        window.setTimeout(() => {
        }, 0);
        let searchInput = document.querySelector(`input[id="${propertyName}"]`);
        searchInput && searchInput.focus();
    }

    handleSearchTermChange = (searchTerm) => {
        let {selectorId, location, propertyName} = this.props;
        let t = this;
        t.setState({filteredSearchWaiting: true, searchValue: searchTerm});
        let useLocation = null;
        if (searchTerm === strings.location.searchNearMe) {
            let loc = location;
            if (loc.mode === LOCATION_MODES.unavailable.id) {
                reportBusinessError(strings.location.unavailableMessage);
            } else if (loc.mode === LOCATION_MODES.userDenied.id) {
                reportBusinessError(strings.location.userDeniedMessage);
            } else {
                useLocation = cloneDeep(loc);
            }
        }
        this.props.onPutNodeProperty({id: selectorId, searchTerm: searchTerm, location: useLocation});
        this.debounceSearchTerm(t);
        const searchInput = document.querySelector(`input[id="${propertyName}"]`);
        if (searchInput) {
            this.searchCursorPositions = {
                start: searchInput.selectionStart,
                end: searchInput.selectionEnd,
            };
        }
    }

    componentDidUpdate(prevProps) {
        const { propertyName, searchTerm } = this.props;
        if (searchTerm && searchTerm != prevProps.searchTerm) {
            const searchInput = document.querySelector(`input[id="${propertyName}"]`);
            if (searchInput && this.searchCursorPositions) {
                const {start, end} = this.searchCursorPositions;
                searchInput.setSelectionRange?.(start, end);
            }
        }
    }

    debounceSearchTerm = debounce((t) => {
        if (!t.mounted) {
            return;
        }
        t.setState({filteredSearchWaiting: false});
    }, 200);


    componentDidMount() {
        let {selectorSchema, selector, onPutNodeProperty, selectorId} = this.props;
        if (selector == null) {
            let selectorNode = createNode(selectorSchema, {id: selectorId});
            onPutNodeProperty(selectorNode);
        }
    }

    renderNearMeButton = () => {
        let {classes} = this.props;
        return (
            <IconButton
                className={`${classes.nearMe} locationButton`}
                onClick={this.handleUseLocation}
                key={'near-me'}
                data-cy={'near-me'}
                title={strings.selectBox.nearMeTitle}
                size="large">
                <LocationOn/>
            </IconButton>
        );
    }

    render = () => {
        let {
            disabled,
            multiple,
            value,
            loadUsersUrl,
            loadFilteredUrl,
            loadProcedureUrl,
            propertyName,
            options,
            searchTerm,
            isLoading,
            handleChange,
            defaultOptions,
            dropDownAsSearch,
            isFocusedResized,
            customClass,
            customMenuClass,
            placeholder,
            renderOptionKey,
            locationAvailable,
            autoFocus,
            canCreate,
            handleCreateOption,
            customId,
            menuPortalTarget,
            optionGrouping,
            selectRef,
            executionId,
            workItemSearch,
            noOptionsAvailable,
            mobileView,
            allowContextMenu,
            linkOptions,
            executionLoaderDisabled = false,
        } = this.props;
        let {filteredSearchWaiting, searchValue} = this.state;
        let showLoading = (isLoading || filteredSearchWaiting) && !disabled;
        let additionalActions = [];
        if (locationAvailable && !hasValue(searchTerm)) {
            additionalActions.push(this.renderNearMeButton());
        }

        return <>
            {
                loadUsersUrl && !filteredSearchWaiting && !disabled &&
                <GraphResourceLoad
                    key={loadUsersUrl}
                    resourcePath={loadUsersUrl}
                    friendlyName={strings.execution.namePlural}
                    nodeType={'ExecutionRoot'}
                    reloadIntervalMs={MINUTES_5}
                    hideLoader={true}
                    incrementalLoadOff={true}
                    hideOfflineWarnings={true}
                />
            }
            {
                loadFilteredUrl && !filteredSearchWaiting && !disabled && !executionLoaderDisabled &&
                <GraphResourceLoad
                    key={loadFilteredUrl}
                    resourcePath={loadFilteredUrl}
                    friendlyName={strings.execution.namePlural}
                    nodeType={'ExecutionRoot'}
                    reloadIntervalMs={MINUTES_5}
                    hideLoader={true}
                    incrementalLoadOff={true}
                    hideOfflineWarnings={true}
                />
            }
            {
                loadProcedureUrl && !filteredSearchWaiting && !disabled &&
                <GraphResourceLoad
                    key={loadProcedureUrl}
                    resourcePath={loadProcedureUrl}
                    friendlyName={strings.procedure.namePlural}
                    nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}
                    reloadIntervalMs={MINUTES_5}
                    hideLoader={true}
                    incrementalLoadOff={true}
                    hideOfflineWarnings={true}
                />
            }
            <ExecutionSelect
                customId={customId}
                multiple={multiple}
                propertyName={propertyName}
                handleChange={handleChange}
                handleSearchTermChange={this.handleSearchTermChange}
                onBlur={this.handleBlur}
                onFocus={this.handleFocus}
                disabled={disabled}
                required={true}
                isClearable={true}
                defaultOptions={defaultOptions}
                noOptionsAvailable={noOptionsAvailable}
                options={options}
                isLoading={showLoading}
                inputValue={searchTerm}
                value={value}
                mobileView={mobileView}
                closeMenuOnChange={!multiple}
                dropDownAsSearch={dropDownAsSearch}
                isFocusedResized={isFocusedResized}
                customClass={customClass}
                customMenuClass={customMenuClass}
                placeholder={placeholder}
                renderOptionKey={renderOptionKey}
                actions={additionalActions}
                autoFocus={autoFocus}
                canCreate={canCreate}
                handleCreateOption={handleCreateOption}
                menuPortalTarget={menuPortalTarget}
                optionGrouping={optionGrouping}
                selectRef={selectRef}
                workItemSearch={workItemSearch}
                executionId={executionId}
                allowContextMenu={allowContextMenu}
                linkOptions={linkOptions}
            />
        </>
    };
}

const styles = (theme) => ({
    nearMe: {
        color: theme.palette.grey.six.main,
        position: 'absolute',
        right: 0,
        top: 0,
        padding: 5
    },
});
ExecutionSelector.propTypes = {
    renderOptionKey: PropTypes.bool
};
const mapStateToProps = (state, ownProps) => {
    const searchTypes = ownProps.searchTypes;
    const workItemSearchEnabled = inArray(searchTypes, GLOBAL_SEARCH_TYPES.workItems.id);
    const templateSearchEnabled = inArray(searchTypes, GLOBAL_SEARCH_TYPES.templates.id);
    const userSearchEnabled = inArray(searchTypes, GLOBAL_SEARCH_TYPES.users.id);
    const teamSearchEnabled = inArray(searchTypes, GLOBAL_SEARCH_TYPES.teams.id);

    const seeMoreResults = ownProps.seeMoreResults;
    const displayLimit = 5;
    const defaultOptions = ownProps.defaultOptions;
    let defaultsLimit = ownProps.limit || 100;
    let selectorId = NODE_TYPE_OPTIONS.ExecutionSelector + '-' + ownProps.id;
    let selector = getNodeOrNull(state, selectorId);
    let searchTerm = (selector && selector.searchTerm) || null;
    let useSearchTerm = (searchTerm && searchTerm.toLowerCase().trim()) || null;
    let procedureIds = ownProps.procedureIds || [];
    let workItemSearch = ownProps.workItemSearch;
    let optionGrouping = (searchTypes || []).length > 1;

    // Default list of options without filtering
    let loadDefaultsOn = defaultOptions !== false;

    let loadFilteredUrl = `/executions?summary=true&limit=${defaultsLimit}`;
    let loadProcedureUrl = `/procedures?summary=true&limit=${defaultsLimit}`;
    let loadUsersUrl = `/executions?summary=true&limit=${defaultsLimit}`;
    let loadTeamsUrl = NODE_IDS.Groups;

    procedureIds.forEach(procedureId => loadUsersUrl += `&procedureIds=${procedureId}`);
    if (userSearchEnabled && useSearchTerm) {
        loadUsersUrl += `&q=${encodeURIComponent(useSearchTerm)}`;
    } else if (!userSearchEnabled || (userSearchEnabled && !defaultOptions)) {
        loadUsersUrl = null;
    }

    if (templateSearchEnabled && useSearchTerm) {
        loadProcedureUrl += `&q=${encodeURIComponent(useSearchTerm)}`;
    } else if (!templateSearchEnabled || (templateSearchEnabled && !defaultOptions)) {
        loadProcedureUrl = null;
    }

    if (!teamSearchEnabled || (teamSearchEnabled && !useSearchTerm)) {
        loadTeamsUrl = null;
    }

    if (selector && selector.location) {
        loadFilteredUrl += `&lat=${selector.location.position.latitude}&lng=${selector.location.position.longitude}&orderBy=distance`;
        loadProcedureUrl = null;
        loadUsersUrl = null;
        loadTeamsUrl = null;
    } else if (workItemSearchEnabled && useSearchTerm) {
        loadFilteredUrl += `&q=${encodeURIComponent(useSearchTerm)}`;
    } else if (!workItemSearchEnabled || (workItemSearchEnabled && !defaultOptions)) {
        loadFilteredUrl = null;
    }

    let options = [];
    let procedures, workItems, users, teams;
    let loadFilteredResourceSync, loadFilteredProcedureResourceSync, loadUsersResourceSync, loadTeamsResourceSync;
    let noOptionsAvailable = false;
    if (workItemSearchEnabled) {
        if (workItemSearch && procedureIds && procedureIds.length > 0) {
            procedureIds.forEach(procedureId => loadFilteredUrl += `&procedureIds=${procedureId}`);
        } else if (workItemSearch && procedureIds && procedureIds.length === 0) {
            loadFilteredUrl = loadFilteredUrl += `&procedureIds=none`;
            noOptionsAvailable = true;
        }
        if (ownProps.where) {
            loadFilteredUrl += "&where=" + encodeURIComponent(ownProps.where);
        }
        if (ownProps.orderBy) {
            loadFilteredUrl += `&orderBy=${encodeURIComponent(ownProps.orderBy)}&orderByDirection=${encodeURIComponent(ownProps.orderByDirection ?? 'ascending')}`;
        }

        loadFilteredResourceSync = getNodeOrNull(state, loadFilteredUrl);
        let loadedFilteredNodes = getNodesIfPresent(state, (loadFilteredResourceSync && loadFilteredResourceSync.nodeIds) || []);
        workItems = loadedFilteredNodes.filter(a => a.deleted !== true);
        const seeAllWorkItems = seeMoreResults && (workItems && workItems.length > displayLimit);
        const includeTemplateParent = ownProps.workItemSearch ? !!(procedureIds && procedureIds.length > 1) : true;
        const workItemOptions = !seeAllWorkItems ? mapExecutionToOption(workItems, ownProps.renderOptionKey, includeTemplateParent) : mapExecutionToOption(workItems.slice(0, displayLimit), ownProps.renderOptionKey, includeTemplateParent);
        if (optionGrouping) {
            options.push({
                label: GLOBAL_SEARCH_TYPES.workItems.name,
                type: GLOBAL_SEARCH_TYPES.workItems.id,
                seeAllCount: seeAllWorkItems && loadFilteredResourceSync.total,
                options: workItemOptions,
            });
        } else {
            options = options.concat(workItemOptions);
        }
        if (workItemSearch && procedureIds && procedureIds.length > 0 && workItemOptions.length === 0 && !useSearchTerm) {
            noOptionsAvailable = true;
        }
    }

    if (templateSearchEnabled) {
        loadFilteredProcedureResourceSync = getNodeOrNull(state, loadProcedureUrl);
        let loadedFilteredProcedureNodes = getNodesIfPresent(state, (loadFilteredProcedureResourceSync && loadFilteredProcedureResourceSync.nodeIds) || []);
        procedures = loadedFilteredProcedureNodes.filter(a => a.deleted !== true);
        const seeAllProcedures = seeMoreResults && (procedures && procedures.length > displayLimit);
        options.push({
            label: GLOBAL_SEARCH_TYPES.templates.name,
            type: GLOBAL_SEARCH_TYPES.templates.id,
            seeAllCount: seeAllProcedures && loadFilteredProcedureResourceSync.total,
            options: !seeAllProcedures ? mapProcedureToOption(procedures) : mapProcedureToOption(procedures.slice(0, displayLimit))
        });
    }

    if (userSearchEnabled) {
        loadUsersResourceSync = getNodeOrNull(state, loadUsersUrl);
        let loadedUserNodes = cloneDeep(getNodesIfPresent(state, (loadUsersResourceSync && loadUsersResourceSync.nodeIds) || []));
        users = loadedUserNodes.filter(a => a.deleted !== true);
        const seeAllUsers = seeMoreResults && (users && users.length > displayLimit);
        options.push({
            label: GLOBAL_SEARCH_TYPES.users.name,
            type: GLOBAL_SEARCH_TYPES.users.id,
            seeAllCount: seeAllUsers && loadUsersResourceSync.total,
            options: !seeAllUsers ? mapUserToOption(users) : mapUserToOption(users.slice(0, displayLimit))
        });
    }

    if (teamSearchEnabled) {
        loadTeamsResourceSync = getNodeOrNull(state, loadTeamsUrl);
        let loadedFilteredTeamNodes = getNodesIfPresent(state, (loadTeamsResourceSync && loadTeamsResourceSync.nodeIds) || []);
        teams = loadedFilteredTeamNodes.filter(a => a.deleted !== true);
        if (useSearchTerm) {
            teams = searchAndSortByRank(useSearchTerm, teams, 'name');
        }
        const seeAllTeams = seeMoreResults && (teams && teams.length > displayLimit);
        options.push({
            label: GLOBAL_SEARCH_TYPES.teams.name,
            type: GLOBAL_SEARCH_TYPES.teams.id,
            seeAllCount: seeAllTeams && loadTeamsResourceSync.total,
            options: !seeAllTeams ? mapTeamToOption(teams) : mapTeamToOption(teams.slice(0, displayLimit))
        });
    }

    const loadingExecutions = workItemSearchEnabled &&
        (loadFilteredResourceSync == null || (loadFilteredResourceSync.loading && !hasValue(loadFilteredResourceSync.nodeIds)));
    const loadingProcedures = templateSearchEnabled &&
        (loadFilteredProcedureResourceSync == null || (loadFilteredProcedureResourceSync.loading && !hasValue(loadFilteredProcedureResourceSync.nodeIds)));
    const loadingUsers = userSearchEnabled &&
        (loadUsersResourceSync == null || (loadUsersResourceSync.loading && !hasValue(loadUsersResourceSync.nodeIds)));
    const loadingTeams = (!isLocalhost && (loadTeamsResourceSync && (loadTeamsResourceSync.loading && !hasValue(loadTeamsResourceSync.nodeIds))));
    const isLoading = (loadingExecutions || loadingProcedures || loadingUsers || loadingTeams) && (!defaultOptions ? useSearchTerm : true);
    let location = getNodeOrNull(state, NODE_IDS.Location);
    return {
        loadFilteredUrl: loadFilteredUrl,
        loadProcedureUrl: loadProcedureUrl,
        loadDefaultsOn: loadDefaultsOn,
        loadUsersUrl: loadUsersUrl,
        isLoading: isLoading,
        options: options.length > 0 ? options : EMPTY_ARRAY,
        searchTerm: searchTerm,
        selectorId: selectorId,
        selector: selector,
        defaultOptions: EMPTY_ARRAY,
        selectorSchema: getNodeSchemaOrError(state, NODE_TYPE_OPTIONS.ExecutionSelector),
        location: location,
        locationAvailable: location.position && location.position.latitude && location.position.longitude && ownProps.allowNearMe,
        optionGrouping: optionGrouping,
        noOptionsAvailable: noOptionsAvailable,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node)),
        onPutNodesProperty: node => dispatch(putNodesProperty(node)),
        onFocusNodeProperty: (id, propertyName) => dispatch(focusNodeProperty(id, propertyName)),
        onBlurNodeProperty: (id, propertyName) => dispatch(blurNodeProperty(id, propertyName)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionSelector));
