import React, {useCallback, useState} from "react";
import withStyles from '@mui/styles/withStyles';
import ExecutionLink from "../ExecutionLink";
import {LINK_TYPES, NODE_IDS} from "../../../reducers/graphReducer";
import {linkLongTitle, strings} from "../../components/SopLocalizedStrings";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/ClearRounded";
import ListItem from "@mui/material/ListItem";
import {hasValue} from "../../../util/util";
import {putNodeProperty} from "../../../actions";
import {useDispatch} from "react-redux";
import {ExecutionShowNodeInfoDialog} from "../ExecutionShowNodeInfoDialog";
import {useDiagnosticsOn, useNodeOrNull} from "../../../hooks/nodeHooks";

const ExecutionShowLinkItem = ({classes, item, linkedExecutions, canRemoveLink}) => {
    const dispatch = useDispatch();
    let linkedToExecution = linkedExecutions[item.toNodeId];
    let isRuleManaged = hasValue(item.ruleIds);
    let canDeleteThisLink = canRemoveLink && !isRuleManaged;
    let title = isRuleManaged ? 'This link is managed by a rule' : null;
    const nodeTitle = linkLongTitle(item, linkedToExecution);
    const diagnosticsOn = useDiagnosticsOn();
    const userDevice = useNodeOrNull(NODE_IDS.UserDevice);

    const onDelete = (linkId) => () => {
        dispatch(putNodeProperty({id: linkId, deleted: true}))
    };

    const deletedStyle = React.useMemo(() => {
        if(item?.deleted) {
            return classes.linkLabelDeleted;
        } else if (linkedToExecution?.deleted) {
            return classes.toNodeDeleted;
        } else {
            return ''
        }
    }, [linkedToExecution?.deleted, item?.deleted])

    return <ListItem key={`${item.linkType}_${item.id}`} disableGutters={true} className={`${classes.linkLabel} ${deletedStyle}`}
                     title={title}>
        <ExecutionLink nodeId={item.toNodeId} title={LINK_TYPES[item.linkType].name}>
            {nodeTitle}
        </ExecutionLink>
        <div className={classes.linkActions}>
            {
                canDeleteThisLink && !item.deleted &&
                <IconButton
                    className={'removeLinkButton'}
                    size={'small'}
                    color={'primary'}
                    edge="end"
                    title={strings.buttons.delete}
                    onClick={onDelete(item.id)}
                    data-cy='remove-link'
                >
                    <ClearIcon/>
                </IconButton>
            }
            {diagnosticsOn &&
                <ExecutionShowNodeInfoDialog node={item} title={nodeTitle} buttonStyle={'diagnosticButton'} />
            }
        </div>
    </ListItem>
}

const styles = theme => ({
    linkLabel: {
        paddingRight: 32,
        '& .removeLinkButton': {
            color: theme.palette.grey.seven.main,
            display: 'none',
        },
        '& .diagnosticButton': {
            position: 'relative',
            '& svg.MuiSvgIcon-root': {
                fontSize: '1.2rem'
            },
        },
        '&:hover': {
            '& a': {
                textDecoration: 'underline',
            },
            '& .removeLinkButton': {
                display: 'flex',
            },
        },
    },
    linkLabelDeleted: {
        backgroundColor: theme.palette.deleted.one.main,
    },
    toNodeDeleted: {
        textDecoration: 'line-through',
    },
    linkActions: {
        top: '50%',
        right: 5,
        position: 'absolute',
        display: 'flex',
        flexDirection: 'row',
        transform: 'translateY(-50%)',
        '& svg.MuiSvgIcon-root': {
            fontSize: '1.2rem'
        },
    }
});

export default withStyles(styles)(ExecutionShowLinkItem);
