import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { getNodeOrNull } from "../../selectors/graphSelectors";
import { connect, useDispatch, useSelector } from "react-redux";
import '../../style/alert.css';
import ExecutionStepsContent from "./ExecutionStepsContent";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import Grid from "@mui/material/Grid";
import ExecutionProperties from "./ExecutionProperties";
import { strings } from "../components/SopLocalizedStrings";
import GraphDeleteMessage from "../graph/GraphDeleteMessage";
import NodeVisited from "../graph/GraphNodeVisited";
import ExecutionShowTop from "./ExecutionShowTop";
import { DIAGNOSTIC_MODES, NODE_IDS, NODE_TYPE_OPTIONS } from "../../reducers/graphReducer";
import NodeDestroyedMessage from "../graph/GraphDestroyedMessage";
import { PageContentWrapper } from "tbf-react-library";
import GraphNodeSaveErrorMessage from "../graph/GraphNodeSaveErrorMessage";
import ExecutionDeleteSnackBar from "./ExecutionDeleteSnackBar";
import ExecutionShowDependencies from "./ExecutionShowDependencies";
import { getChildNodeIndex, getNodeContainerByTypeOrNull, isExecutionLoadedFull } from "../../selectors/executionSelectors";
import ExecutionUploadPhotoFromClipboard from "./ExecutionUploadPhotoFromClipboard";
import { isNodeSaved } from "../../factory/executionFactory";
import { ROUTES } from "../Routes";
import { useHistory, withRouter } from "react-router-dom";
import ExecutionRulesList from "./troubleshoot/ExecutionRulesList";
import { putNodeProperty } from '../../actions';
import QueryParamsProvider from './QueryParamsProvider';
import { useIsSavePending, useNodeOrNull } from "../../hooks/nodeHooks";
import ExecutionSourceBanner from './ExecutionSourceBanner';
import ExecutionDraftErrorBanner from "./ExecutionDraftErrorBanner";

const useStyles = makeStyles((theme) => ({
    executionStepContainer: {
        position: 'relative',
        paddingTop: theme.spacing(1)
    },
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    addLink: { textDecoration: 'none' },
    questionActionContainer: {
        '& button': {
            float: 'right'
        }
    },
    button: {
        margin: theme.spacing(1),
        minWidth: '120px'
    },
    radio: {
        minWidth: '90px'
    },
    progressIcon: {
        verticalAlign: 'bottom'
    },
    linkButton: {
        color: '#757575',
        marginRight: '10px',
        paddingTop: '5px'
    },
    tabletPadding: {
        [theme.breakpoints.down('sm')]: {
            padding: '8px 25px !important'
        },
    }
}));

const ExecutionShowPageRedirect = ({ isAnonymous, returnUrl, executionId }) => {
    const history = useHistory();
    const savePending = useIsSavePending(executionId);
    const execution = useNodeOrNull(executionId);

    useEffect(() => {
        if (isAnonymous && !savePending && execution?.completed && isNodeSaved(execution)) {
            history.replace(ROUTES.executionSubmitted(execution, isAnonymous, returnUrl));
        }
    }, [isAnonymous, savePending, execution, history, returnUrl]);

    return null;
};

const ExecutionShowPage = ({
                               nodeId,
                               executionPreview,
                               executionReady,
                               reloadInterval,
                               loadExecution,
                               renderProperties,
                               isAnonymous,
                               diagnosticsOn,
                               troubleshootOn,
                               executionId,
                               returnUrl,
                               highlightStepIndex,
                               onPutNodeProperty,
                               isDraft,
                               procedureId
                           }) => {
    const classes = useStyles();
    const [highlighted, setHighlighted] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!highlighted && highlightStepIndex !== null && highlightStepIndex !== undefined) {
            dispatch(onPutNodeProperty({ id: executionId, selectedStepIndex: highlightStepIndex }));
            setHighlighted(true);
        }
    }, [highlightStepIndex, executionId, highlighted, onPutNodeProperty, dispatch]);

    if(isDraft && !isAnonymous) {
        return <ExecutionDraftErrorBanner executionId={executionId} procedureId={procedureId}/>
    }

    return (
        <QueryParamsProvider>
            <div data-cy={'Execution'}>
                {troubleshootOn && <ExecutionSourceBanner executionId={nodeId} />}
                {
                    executionReady &&
                    <>
                        <ExecutionShowPageRedirect executionId={executionId} isAnonymous={isAnonymous} returnUrl={returnUrl} />
                        <ExecutionShowTop executionId={executionId} isAnonymous={isAnonymous} />
                    </>
                }
                <PageContentWrapper style={{ padding: '0px' }}>
                    {
                        loadExecution &&
                        <GraphResourceLoad
                            resourcePath={`/executions?id=${nodeId}`}
                            friendlyName={strings.execution.name}
                            nodeId={nodeId}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={reloadInterval}
                            disabled={executionPreview === true}
                            hideOfflineWarnings={false}
                        />
                    }
                    <GraphNodeSaveErrorMessage nodeId={nodeId} />
                    {
                        executionReady &&
                        <Grid container>
                            <Grid item xs={12} sm={12} md={renderProperties ? 9 : 12}>
                                <div className={classes.executionStepContainer}>
                                    <GraphDeleteMessage nodeId={nodeId} />
                                    <NodeDestroyedMessage nodeId={nodeId} />
                                    <ExecutionStepsContent executionId={nodeId} />
                                </div>
                            </Grid>
                            {
                                renderProperties &&
                                <Grid item xs={12} sm={12} md={3} className={classes.tabletPadding}>
                                    <ExecutionProperties executionId={nodeId} />
                                </Grid>
                            }
                        </Grid>
                    }
                    <NodeVisited nodeId={nodeId} nodeType={NODE_TYPE_OPTIONS.ExecutionRoot} />
                    <ExecutionShowDependencies executionId={nodeId} />
                    {
                        executionReady && troubleshootOn &&
                        <ExecutionRulesList nodeId={nodeId} diagnosticsOn={diagnosticsOn} />
                    }
                </PageContentWrapper>
                <ExecutionDeleteSnackBar />
                <ExecutionUploadPhotoFromClipboard executionId={nodeId} />
            </div>
        </QueryParamsProvider>
    );
};

const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.executionId;
    let executionNode = getNodeOrNull(state, nodeId);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const loadExecution = !executionNode?.loaded || (executionNode && isNodeSaved(executionNode));
    const renderProperties = !ownProps.dashboard && !ownProps.isAnonymous;
    const highlightStep = getNodeContainerByTypeOrNull(state, nodeId, {
        nodeId: ownProps.highlightId,
        ruleId: ownProps.highlightRuleId,
        type: NODE_TYPE_OPTIONS.ExecutionStep,
        matchWithProcedure: true,
    });
    const highlightStepIndex = getChildNodeIndex(state, executionNode, highlightStep, { visible: !userDevice.troubleshootOn });
    return {
        nodeId: nodeId,
        executionReady: executionNode?.type === NODE_TYPE_OPTIONS.ExecutionRoot && isExecutionLoadedFull(executionNode),
        executionPreview: executionNode && executionNode.preview,
        projectNode: executionNode ? getNodeOrNull(state, executionNode.projectId) : null,
        destroyed: executionNode && executionNode.destroyed,
        reloadInterval: executionNode?.reloadInterval,
        loadExecution: loadExecution,
        renderProperties: renderProperties,
        diagnosticsOn: userDevice.diagnosticMode === DIAGNOSTIC_MODES.full.id,
        troubleshootOn: userDevice.troubleshootOn,
        highlightStepIndex: highlightStepIndex !== -1 ? highlightStepIndex : null,
        isDraft: executionNode?.draft,
        procedureId: executionNode?.procedureId
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ExecutionShowPage));
