import React, { useState, useEffect, useMemo } from "react";
import { Tab, Tabs } from "@mui/material";
import { withStyles } from "@mui/styles";
import { Panel } from "tbf-react-library";
import SwipeableViews from "react-swipeable-views";
import { NODE_IDS } from '../../reducers/graphReducer';
import RecentExecutions, { RecentExecutionTypes } from "./RecentExecutions";
import { useNodeOrNull, useCallbackPatchNode } from "../../hooks/nodeHooks";
import { strings } from "../components/SopLocalizedStrings";
import { getNodeOrNull, getNodesOrNull } from '../../selectors/graphSelectors';
import { useSelector } from 'react-redux';
import Loader from "../components/Loader";
import GraphResourceLoad from '../graph/GraphResourceLoad';
import { MINUTES_5 } from '../../util/constants';
import cn from 'classnames';
import isEqual from "lodash/isEqual";

const styles = () => ({
    tabsContainer: {
        backgroundColor: 'white',
        margin: '8px 0px',
    },
    tabLoader: {
        height: '140px',
        width: '100%',
        textAlign: 'center',
    },
    tabContent: {
        marginLeft: '-5px',
    },
    loadingIndicator: {
        minHeight: 160,
    },
});

export const useRecentExecutions = (executionType, nodeId) => useSelector((state) => {
    switch (executionType) {
        case RecentExecutionTypes.availableOffline:
            const offlineIds = Object.values(getNodeOrNull(state, NODE_IDS.UserDevice).offlineExecutions).filter((e) => e.on && !e.onImplicit).map((e) => e.id);
            const offlineExecutions = getNodesOrNull(state, offlineIds)?.filter((e) => !e.deleted);
            return ([offlineExecutions?.map((e) => e.id), offlineExecutions !== null]);
        default:
            const res = getNodeOrNull(state, nodeId);
            const executionIds = (res && res?.nodeIds) || [];
            const executions = getNodesOrNull(state, executionIds)?.filter((e) => !e.deleted);
            return ([executions?.map((e) => e.id), res?.loaded]);
    }
}, isEqual);

const RecentExecutionsTabs = withStyles(styles)(function RecentExecutionsTabs({
    classes,
    className
}) {

    const [offlineIds] = useRecentExecutions(RecentExecutionTypes.availableOffline);

    const [workspaceIds, workspacesLoaded] = useRecentExecutions(RecentExecutionTypes.workspace, NODE_IDS.MyRecentWorkspaces);

    const [projectIds, projectsLoaded] = useRecentExecutions(RecentExecutionTypes.project, NODE_IDS.MyRecentProjects);

    const { recentExecutionsTabIndex } = useNodeOrNull(NODE_IDS.UserSettings);

    const [currentTab, setCurrentTab] = useState(recentExecutionsTabIndex || 0);

    const patch = useCallbackPatchNode();

    const handleTabChange = (_, value) => {
        setCurrentTab(value);
    }

    const handleChangeIndex = (index) => {
        setCurrentTab(index);
    }

    const tabs = useMemo(() => {
        const tabs_ = [
            {
                label: strings.dashboard.workspaces,
                value: 0,
                nodeId: NODE_IDS.MyRecentWorkspaces,
                type: RecentExecutionTypes.workspace,
                canViewAll: true,
                executionIds: workspaceIds ?? [],
            }
        ]

        let nextTabValue = 1;

        if (projectIds?.length) {
            tabs_.push({
                label: strings.dashboard.projects,
                value: nextTabValue++,
                nodeId: NODE_IDS.MyRecentProjects,
                type: RecentExecutionTypes.project,
                canViewAll: true,
                executionIds: projectIds ?? [],
            });
        }

        if (offlineIds?.length) {
            tabs_.push({
                label: strings.dashboard.availableOffline,
                value: nextTabValue++,
                type: RecentExecutionTypes.availableOffline,
                canViewAll: false,
                executionIds: offlineIds || [],
            })
        }

        return tabs_;
    }, [offlineIds, workspaceIds, projectIds]);

    const executionsLoaded = useMemo(() => workspacesLoaded && projectsLoaded && !!offlineIds, [workspacesLoaded, projectsLoaded, offlineIds]);

    useEffect(() => {
        if (executionsLoaded) {
            if (currentTab >= tabs.length) {
                setCurrentTab(tabs.length - 1);
            }
        }
    }, [tabs.length, executionsLoaded])

    useEffect(() => {
        patch({ id: NODE_IDS.UserSettings, recentExecutionsTabIndex: currentTab })
    }, [currentTab]);


    return <Panel title={strings.dashboard.recentExecutions} className={cn(classes.tabsContainer, className)}>
        <div data-cy={`MyWorQ`} data-cy-element={`MyWorQ`} >
            <GraphResourceLoad
                resourcePath={NODE_IDS.MyRecentWorkspaces}
                friendlyName={strings[RecentExecutionTypes.workspace].namePlural}
                nodeType={'ExecutionRoot'}
                hideLoader={true}
                hideOfflineWarnings={false}
                reloadIntervalMs={MINUTES_5}
            >
                {(executionsLoaded) ? <>
                    <Tabs
                        value={Math.min(currentTab, tabs.length - 1)}
                        onChange={handleTabChange}
                        aria-label={`recent-executions-tabs`}
                    >
                        {tabs.map((tab, index) =>
                            <Tab key={index} label={tab.label} aria-label={tab.label} value={tab.value} data-cy={`recent-${tab.type}-tab`} />
                        )}
                    </Tabs>
                    <SwipeableViews
                        axis={'x'}
                        index={Math.min(currentTab, tabs.length - 1)}
                        onChangeIndex={handleChangeIndex}
                        className={classes.tabContent}
                    >
                        {tabs.map((tab) => <RecentExecutions key={tab.type} executionIds={tab.executionIds} className={classes.executionsContainer} nodeId={tab.nodeId} executionType={tab.type} canViewAll={tab.canViewAll} />)}
                    </SwipeableViews>
                </> : <Loader loaderContainerStyleClass={classes.loadingIndicator} circular={true} source={'RecentExecutionsTabs'} size={16} contentCentered={true} />}
            </GraphResourceLoad>
            <GraphResourceLoad
                resourcePath={NODE_IDS.MyRecentProjects}
                friendlyName={strings[RecentExecutionTypes.project].namePlural}
                nodeType={'ExecutionRoot'}
                hideLoader={true}
                hideOfflineWarnings={true}
                reloadIntervalMs={MINUTES_5}
                displayErrorMode={'none'}
            />
        </div>
    </Panel>
});

export default RecentExecutionsTabs;