import {
    CLEAR_GRAPH_FAILURE,
    CLEAR_GRAPH_STARTED,
    CLEAR_GRAPH_SUCCESS,
    CLEAR_SAVE_ERROR,
    DIRTY_STORAGE_LOADED,
    LOAD_NODES_FAILURE,
    LOAD_NODES_START,
    NODE_PROPERTY_BLUR,
    NODE_PROPERTY_FOCUS,
    NODE_RECOMPUTE_CHECK,
    NODES_LOADED_SERVER,
    NODES_LOADED_STORAGE,
    NODES_LOADED_STORAGE_FAILED,
    PUT_NODE,
    PUT_NODE_PROPERTY,
    PUT_NODES,
    RECOMPUTE_ALL,
    RESET_GRAPH,
    SAVE_NODE_FAILURE,
    SAVE_NODE_SUCCESS,
    SAVE_NODES_FINISH,
    SAVE_NODES_START,
} from './types';
import cloneDeep from "lodash/cloneDeep";
import keyBy from "lodash/keyBy";
import get from 'lodash/get';
import axios from "axios";
import {clearDb, getDbNode, getDbNodeByDirty, getDbNodeByRootId, getDbNodeByScope} from "../util/offline";
import {hasValue, intersect, objectify} from "../util/util";
import {dataURLtoBlob} from "../util/image";
import {IDENTITY_TOKEN} from "../identities";
import {
    APPS as APP,
    deriveApiUrl,
    getTime,
    reportBusinessError,
    reportDebug,
    reportDeveloperWarning,
    reportError,
    SharedAuth
} from "tbf-react-library";
import {NODE_TYPE_OPTIONS, QUESTION_TYPES, SAVE_TIMEOUT_SECONDS} from "../reducers/graphReducer";
import {strings} from "../layouts/components/SopLocalizedStrings";
import {
    getProcedureSummaryId,
    getSummaryId,
    isProcedureSummaryId,
    isSummaryId,
    procedureSummaryToFullId,
    summaryToFullId
} from "../factory/executionFactory";
import {HOURS_1, HOURS_24, MINUTES_15} from "../util/constants";
import { truncate } from '../layouts/MapView/offline/TbfTileManager';

export const checkSaveTimeout = (saving, saveStartedTicks) => {
    return dispatch => {
        if (!saving) {
            return;
        }
        const saveDuration = getTime() - saveStartedTicks;
        let saveTimeout = hasValue(saveStartedTicks) && (saveDuration / 1000 > SAVE_TIMEOUT_SECONDS);
        if (saveTimeout) {
            reportDeveloperWarning(`Save timeout after ${saveDuration}ms.`)
            dispatch({
                type: SAVE_NODE_FAILURE,
                payload: {error: strings.resource.saveTimeout}
            });
        }
    };
};

export const pretendSaveStarted = () => {
    return {
        type: SAVE_NODES_START, rootIds: []
    };
};
export const clearSaveError = (node) => {
    return {
        type: CLEAR_SAVE_ERROR,
        payload: {node: node}
    };
};
export const putNode = (node) => {
    return {
        type: PUT_NODE,
        payload: {node: node}
    };
};
export const putNodes = (nodes) => {
    return {
        type: PUT_NODES,
        payload: {nodes: nodes, fromIndexDb: false}
    };
};
export const focusNodeProperty = (id, propertyName) => {
    return {
        type: NODE_PROPERTY_FOCUS,
        payload: {id: id, propertyName: propertyName}
    };
};
export const blurNodeProperty = (id, propertyName) => {
    return {
        type: NODE_PROPERTY_BLUR,
        payload: {id: id, propertyName: propertyName}
    };
};
export const markNodeForReload = (nodeId) => {
    return {
        type: PUT_NODE_PROPERTY,
        payload: {node: {id: nodeId, lastReloadTicks: null}}
    };
};
export const putNodeProperty = (node) => {
    return {
        type: PUT_NODE_PROPERTY,
        payload: {node: node}
    };
};
export const putNodesProperty = (nodes) => {
    return {
        type: PUT_NODE_PROPERTY,
        payload: {nodes: nodes}
    };
};
export const retryStoreDbNodes = (nodeIds) => {
    let nodes = nodeIds.map(a => ({id: a}));
    return {
        type: PUT_NODE_PROPERTY,
        payload: {nodes: nodes}
    };
};
export const recomputeAll = () => {
    return {
        type: RECOMPUTE_ALL
    };
};
export const recomputeCheck = () => {
    return {
        type: NODE_RECOMPUTE_CHECK
    };
};
export const resetGraph = () => {
    return {
        type: RESET_GRAPH
    };
};
export const clearGraph = () => {

    return dispatch => {
        dispatch({
            type: CLEAR_GRAPH_STARTED,
            payload: {}
        });
        clearDb()
            .then(() => {
                dispatch({
                    type: CLEAR_GRAPH_SUCCESS,
                    payload: {}
                });
            }).catch(error => {
            reportBusinessError('Failed to clear local storage. ', error)
            dispatch({
                type: CLEAR_GRAPH_FAILURE,
                payload: error
            })
        });
        truncate().catch(error => {
            reportBusinessError('Failed to clear leaflet indexeddb. ', error);
        });
    };
};
export const restoreAllDirtyNodes = () => {
    return (dispatch) => {
        return getDbNodeByDirty()
            .then((dbNodes) => {
                console.info('getDbNodeByDirty returned ' + dbNodes.length + ' nodes.');
                let uniqueRootIds = {};
                for (let dbNode of dbNodes) {
                    uniqueRootIds[dbNode?.node?.rootId] = true;
                }
                let loadIds = Object.keys(uniqueRootIds);
                let getPromises = loadIds.filter(a => a).map(rootId =>
                    getDbNodeByRootId(rootId)
                        .then(nodes => {
                            dispatch({
                                type: NODES_LOADED_STORAGE,
                                payload: {nodes: nodes, fromIndexDb: true}
                            });
                        })
                );
                return Promise
                    .all(getPromises)
                    .then(() => {
                        dispatch({
                            type: DIRTY_STORAGE_LOADED
                        });
                    });
            });
    }
};
export const restoreNodesByScope = (scope, resourceSync, offline) => {
    if (offline) {
        // Lets not restore all executions, just the ResourceSync so we know whats loaded
        return restoreNodeById(resourceSync);
    }
    reportDebug('Loading from IndexedDb by scope ' + scope);
    return (dispatch) => {
        return getDbNodeByScope(scope)
            .then((nodes) => {
                dispatch({
                    type: NODES_LOADED_STORAGE,
                    payload: {nodes: nodes, fromIndexDb: true, resourceSync: resourceSync, scope: scope}
                });
            }).catch(error => {
                reportError('Loading cached data from IndexedDb failed for scope: ' + scope, error)
                dispatch({
                    type: NODES_LOADED_STORAGE_FAILED,
                    payload: {resourceSync: resourceSync, scope: scope, error: error}
                });
            });
    }
};
export const restoreNodeById = (resourceSync) => {
    reportDebug('Loading from IndexedDb by id ' + resourceSync.id);
    return (dispatch) => {
        getDbNode(resourceSync.id)
            .then((node) => {
                dispatch({
                    type: NODES_LOADED_STORAGE,
                    payload: {nodes: node ? [node] : [], fromIndexDb: true, resourceSync: resourceSync, offline: true}
                });
            }).catch(error => {
            reportError('Loading cached data from IndexedDb failed for resource: ' + resourceSync.id + ' ' + resourceSync.path, error)
            dispatch({
                type: NODES_LOADED_STORAGE_FAILED,
                payload: {resourceSync: resourceSync, error: error, offline: true}
            });
        });
    }
};
export const getUpdatedNodesFromServer = (schema, resourceSync, loadNextPage, firstLoadRun, offline, skipDeltaLoad) => {
    return (dispatch) => {
        dispatch({
            type: LOAD_NODES_START,
            payload: {
                resourceSync: resourceSync
            }
        });
        const type = resourceSync.nodeType || resourceSync.type;
        if (!type) {
            throw new Error(`Missing type on resourceSync [${JSON.stringify(resourceSync)}]`);
        }
        loadNodes(dispatch, type, nodeTransforms[type], schema, resourceSync, loadNextPage, firstLoadRun, offline, skipDeltaLoad)
            .then(() => {
                // Use to raise LOAD_NODES_FINISH but no need as doing that work now in NODES_LOADED_SERVER
            })
            .catch(() => {
                // Already logged by loadNodes
            })
    }
};
const loadNodes = (dispatch, nodeType, transform, schema, resourceSync, loadNextPage, firstLoadRun, offline, skipDeltaLoad) => {
    if (!transform) {
        throw new Error(`No transform for type [${nodeType}]`);
    }
    let isDeltaLoad = false
    let url = null
    return getAuth(transform).then(auth => {
        return new Promise((resolve, reject) => {
            url = transform.baseUrl(auth.clientId, auth.tenantId) + (resourceSync.resourcePath || transform.getUrl(resourceSync));
            url += url.includes('?') ? '&' : '';
            url += url.includes('?') ? '' : '?'


            let nodeSchema = schema[nodeType];
            isDeltaLoad = !skipDeltaLoad && hasValue(resourceSync.lastUpdatedDateTime) && !!resourceSync.loadedFull && nodeSchema.incrementalLoad;
            if (isDeltaLoad) {
                // Fetch full every hour to make sure don't get too out of whack
                let reloadFullInterval = firstLoadRun ? MINUTES_15 : HOURS_1;
                if (offline) {
                    // We don't want to download all offline content every hour
                    reloadFullInterval = 7 * HOURS_24
                }
                if (!resourceSync.lastFullReloadTicks) {
                    isDeltaLoad = false;
                } else if (resourceSync.lastFullReloadTicks < getTime() - reloadFullInterval) {
                    isDeltaLoad = false;
                }
            }
            let multipageLoad = false;
            let pageSize = resourceSync.pageSize;
            if (loadNextPage) {
                url += `pageNumber=${(resourceSync.pageNumber || 0) + 1}`;
            } else if (isDeltaLoad) {
                let from = resourceSync.lastUpdatedDateTime;
                if (typeof from === 'number') {
                    from = new Date(from).toISOString();
                }
                url += `${nodeSchema.incrementalLoad.getParameter}=${from.replace(/"/g, '')}`;
            } else if (resourceSync.pageNumber > 1) {
                // Reloading results when multiple pages are already loaded gets ... interesting
                // For now I will reload all pages in 1 hit
                const fetchCount = resourceSync.pageNumber * resourceSync.pageSize;
                multipageLoad = true;
                pageSize = fetchCount;
            }
            if (pageSize) {
                url += "&pageSize=" + pageSize;
            }
            let isSummaryLoad = url.includes('summary=true');
            axios.get(url,
                {
                    headers: auth.headers
                })
                .then((response) => {
                    const responseData = response.data || [];
                    const responseNodes = responseData.items || (Array.isArray(responseData) ? responseData : [responseData]);
                    const arrayOrNodes = responseNodes.map(item => {
                        return transform.toClient(item, nodeType, schema)
                    });
                    const nodes = [].concat(...arrayOrNodes);
                    dispatch({
                        type: NODES_LOADED_SERVER,
                        payload: {
                            nodes: nodes,
                            resourceSync: resourceSync,
                            deltaLoad: isDeltaLoad,
                            summaryLoad: isSummaryLoad,
                            pageLoad: loadNextPage,
                            pageNumber: multipageLoad ? resourceSync.pageNumber : (responseData && responseData.pageNumber) || null,
                            itemCount: responseData?.items?.length,
                            hasNextPage: (responseData && responseData.hasNextPage) || false,
                            total: (responseData && responseData.total) || null,
                        }
                    });
                    resolve(response.data);
                }).catch(error => reject(error));
        })
    }).catch((err) => {
        let errorMessage = getErrorMessage(err);
        dispatch({
            type: LOAD_NODES_FAILURE,
            payload: {
                nodeType: nodeType,
                resourceSync: resourceSync,
                error: errorMessage,
                deltaLoad: isDeltaLoad
            }
        });
        reportError(`There was an error for fetching ${url}. ${errorMessage}`, err);
    })
};

function getAuth(transform) {
    return SharedAuth.getToken()
        .then(token => {
            return {
                headers: {
                    'Authorization': 'Bearer ' + token
                },
                clientId: SharedAuth.getClientId(),
                tenantId: SharedAuth.getTenantId()
            };
        })
        .catch(error => {
            reportError('Failed to get token to access WORQ API.', error);
            throw new Error(`Failed to get token to access WORQ API. ${error}`);
        });
}

const PUT_PROPERTIES = ['rules', 'children', 'ALL'];
const getRootTransformFor = (node, allDirty) => {
    // If there is not a transform defined the node must be part of its root transform
    if (node == null || node.type == null) {
        throw reportError('Cannot determine root transform for node: ' + node?.type, null, node);
    }
    let transformRootId = nodeTransforms[node.type] ? node.id : node.rootId;
    if (!transformRootId) {
        throw reportError('Cannot determine root transform for node: ' + node.type, null, node);
    }
    if (transformRootId !== node.rootId) {
        let rootDirty = allDirty[node.rootId];
        // If delete procedure/execution via summary and root is not loaded
        if (!rootDirty && (isSummaryId(node.id) || isProcedureSummaryId(node.id))) {
            return node.id;
        }
        let isSaved = rootDirty && rootDirty.storedServer;
        if (!isSaved) {
            // Cannot save yet if root is not saved
            // aka PUT execution with links
            return node.rootId;
        }
    }
    return transformRootId;
};
export const saveDirtyNodes = (nodes, schema, dirtyNodes, allDirty) => {
    return (dispatch) => {

        let dirtyToSave = Object.values(dirtyNodes).filter(item => item.dirty);
        let time = getTime();
        let dirtySaveReady = dirtyToSave.filter(dirtyNode => dirtyNode.nextSaveTicks <= time);
        // Lets find the ones that are ready to save. If one is dirty then save all for the root.
        let saveRootIds = dirtySaveReady.map(dirtyNode => getRootTransformFor(nodes[dirtyNode.id], allDirty)).filter(a => a);
        let saveRootIdsMap = keyBy(saveRootIds, a => a);

        // Patch does not support structural changes, so lets finds one that need a full save
        let saveByPut = {};
        let saveByDelete = {};
        for (let dirty of dirtyToSave) {
            let node = nodes[dirty.id];
            let saveRootId = getRootTransformFor(node, allDirty);
            if (!saveRootIdsMap[saveRootId]) {
                // Not time to save this one
                continue;
            }
            if (node.destroyed || saveByDelete[saveRootId]) {
                saveByDelete[saveRootId] = [];
                delete saveByPut[saveRootId];
            } else if (intersect(dirty.dirtyProperties, PUT_PROPERTIES).length > 0) {
                saveByPut[saveRootId] = [];
            }
        }
        // Lets patch the left overs
        // If patching/putting we want to get all dirty for the rootId even if nextSaveTicks isn't ready
        let saveByPatch = {};
        for (let dirty of dirtyToSave) {
            let node = nodes[dirty.id];
            let saveRootId = getRootTransformFor(node, allDirty);
            if (!saveRootIdsMap[saveRootId]) {
                // Not the time to save
                continue;
            }
            let saveViaParentPut = node.rootId !== saveRootId && saveByPut[node.rootId];
            if (saveByDelete[node.rootId]) {
                saveByDelete[node.rootId].push(node.id);
            } else if (saveViaParentPut) {
                // If we are going to PUT an Execution lets not also PUT the links/assignments
                saveByPut[node.rootId].push(node.id);
            } else if (saveByPut[saveRootId]) {
                saveByPut[saveRootId].push(node.id);
            } else {
                if (!saveByPatch[saveRootId]) {
                    saveByPatch[saveRootId] = [];
                }
                saveByPatch[saveRootId].push(node.id);
            }
        }
        if (saveByPut.length === 0 && saveByPatch.length === 0) {
            return;
        }
        const saveByPutIds = Object.keys(saveByPut);
        const saveByPatchIds = Object.keys(saveByPatch);
        const saveByDeleteIds = Object.keys(saveByDelete);
        // Now lets do the saving!
        dispatch({
            type: SAVE_NODES_START,
            payload: {
                rootIds: [...saveByPutIds, ...saveByPatchIds, ...saveByDeleteIds],
            }
        });
        let putPromises = saveByPutIds.map((sendNodeId) => callServerPutPost(dispatch, sendNodeId, saveByPut[sendNodeId], nodes, schema));
        let patchPromises = saveByPatchIds.map((sendNodeId) => callServerPatch(dispatch, sendNodeId, saveByPatch[sendNodeId], nodes, schema, dirtyNodes));
        let deletePromises = saveByDeleteIds.map((sendNodeId) => callServerDelete(dispatch, sendNodeId, saveByDelete[sendNodeId], nodes));

        Promise
            .all([...putPromises, ...patchPromises, ...deletePromises])
            .then(() => {
                dispatch({
                    type: SAVE_NODES_FINISH,
                });
            }).catch(() => {
            dispatch({
                type: SAVE_NODES_FINISH,
            });
        });
    }
};

function callServerPatch(dispatch, rootNodeId, dirtyNodeIds, nodes, schema, dirtyNodes) {
    let node = nodes[rootNodeId];
    let savingNodes = dirtyNodeIds.map(id => cloneDeep(nodes[id]));
    let transform = nodeTransforms[node.type];
    if (!transform) {
        throw new Error(`Unexpected save of node type ${node.type}. Expected one of ${Object.keys(nodeTransforms).join(', ')}.`);
    }
    return getAuth(transform).then(auth => {
        return new Promise((resolve, reject) => {
            let patches = [];
            for (let dirtyNodeId of dirtyNodeIds) {
                let node = nodes[dirtyNodeId];
                let dirty = dirtyNodes[dirtyNodeId];
                let patch = {id: dirty.id};
                for (let property of dirty.dirtyProperties) {
                    let value = node[property]
                    if (value === undefined) {
                        value = null
                    }
                    patch[property] = value;
                    if (property === 'children' || property === 'rules') {
                        throw new Error('Cannot patch children property!');
                    }
                }
                patches.push(patch);
            }
            if (transform.beforePatch) {
                transform.beforePatch(patches);
            }
            if (transform.patchSingle) {
                // Instead of a list send as an object
                if (patches.length !== 1) {
                    throw new Error('Cannot patch single when ' + patches.length + ' patches. Must be 1.');
                }
                patches = patches[0];
                delete patches.id;
            }
            let url = transform.baseUrl(auth.clientId, auth.tenantId) + transform.patchUrl(node);
            axios.patch(url, patches, {headers: auth.headers})
                .then((response) => {
                    let result = null;
                    if (response.data && transform.toClientPatch && transform.isPatchResponse && transform.isPatchResponse(node, response)) {
                        result = transform.toClientPatch(response.data, schema, nodes)
                    }
                    dispatch({
                        type: SAVE_NODE_SUCCESS,
                        payload: {
                            savedNodeId: rootNodeId,
                            dirtyNodeIds: dirtyNodeIds,
                            savedNodes: savingNodes,
                            url: url,
                            response: result,
                            responsePatch: result !== null
                        }
                    });
                    if (response.data && transform.patchResponseFull) {
                        let responseNodes = transform.toClient(response.data, node.type, schema);
                        dispatch({
                            type: NODES_LOADED_SERVER,
                            payload: {
                                nodes: responseNodes,
                                resourceSync: null,
                                url: url
                            }
                        });
                    }
                    resolve(response.data);
                })
                .catch((err) => {
                    let errorMessage = getErrorMessage(err);
                    dispatch({
                        type: SAVE_NODE_FAILURE,
                        payload: {savedNodeId: rootNodeId, dirtyNodeIds: dirtyNodeIds, error: errorMessage, url: url}
                    });
                    reject(err.response || err.message);
                    reportError(`Failed to PATCH ${node.type} ${node.id}. ${errorMessage}.`, err);
                });
        });
    });
}

function callServerDelete(dispatch, rootNodeId, dirtyNodeIds, nodes) {
    let node = nodes[rootNodeId];
    let savingNodes = dirtyNodeIds.map(id => cloneDeep(nodes[id]));
    let transform = nodeTransforms[node.type];
    if (!transform) {
        throw new Error(`Unexpected save of node type ${node.type}. Expected one of ${Object.keys(nodeTransforms).join(', ')}.`);
    }
    return getAuth(transform).then(auth => {
        return new Promise((resolve, reject) => {
            let url = transform.baseUrl(auth.clientId, auth.tenantId) + transform.deleteUrl(node);
            axios.delete(url, {headers: auth.headers})
                .then((response) => {
                    dispatch({
                        type: SAVE_NODE_SUCCESS,
                        payload: {
                            savedNodeId: rootNodeId,
                            dirtyNodeIds: dirtyNodeIds,
                            savedNodes: savingNodes,
                            url: url
                        }
                    });
                    resolve(response.data);
                })
                .catch((err) => {
                    let errorMessage = getErrorMessage(err);
                    dispatch({
                        type: SAVE_NODE_FAILURE,
                        payload: {savedNodeId: rootNodeId, dirtyNodeIds: dirtyNodeIds, error: errorMessage, url: url}
                    });
                    reject(err.response || err.message);
                    reportError(`Failed to DELETE ${node.type} ${node.id}. ${errorMessage}.`, err);
                });
        });
    });
}

function callServerPutPost(dispatch, nodeId, dirtyNodeIds, nodes, schema) {
    let node = nodes[nodeId];
    let savingNodes = dirtyNodeIds.map(id => cloneDeep(nodes[id]));
    let transform = nodeTransforms[node.type];
    if (!transform) {
        throw new Error(`Unexpected save of node type ${node.type}. Expected one of ${Object.keys(nodeTransforms).join(', ')}.`);
    }
    let method = transform.putUrl ? 'put' : 'post';
    return getAuth(transform).then(auth => {
        return new Promise((resolve, reject) => {
            let url = '';
            url = transform.baseUrl(auth.clientId, auth.tenantId) + transform[method + 'Url'](node);
            let serverNode = transform.toServer(node, nodes, schema);
            const config = transform.toServerConfig ? transform.toServerConfig(node, dispatch) : {};
            let serverPromise = serverNode.then ? serverNode : new Promise((resolve) => {
                resolve(serverNode)
            });
            serverPromise
                .then((requestData => {
                    if (requestData == null) {
                        resolve();
                        return;
                    }
                    let sendData;
                    if (requestData instanceof FormData) {
                        sendData = requestData;
                    } else {
                        sendData = stripNullProperties(requestData);
                    }
                    return axios[method](url, sendData, {headers: auth.headers, ...config})
                        .then((response) => {
                            let result = null;
                            if (response.data && transform.toClientPatch && transform.isPatchResponse && transform.isPatchResponse(node, response)) {
                                result = transform.toClientPatch(response.data, schema, nodes)
                            }
                            dispatch({
                                type: SAVE_NODE_SUCCESS,
                                payload: {
                                    savedNodeId: nodeId,
                                    dirtyNodeIds: dirtyNodeIds,
                                    savedNodes: savingNodes,
                                    url: url,
                                    response: result,
                                    responsePatch: result !== null
                                }
                            });
                            if (response.data) {
                                let responseNodes = transform.toClient(response.data, node.type, schema);
                                dispatch({
                                    type: NODES_LOADED_SERVER,
                                    payload: {
                                        nodes: responseNodes,
                                        resourceSync: null,
                                        url: url

                                    }
                                });
                            }
                            resolve(response.data);
                        })
                }))
                .catch((err) => {
                    let errorMessage = getErrorMessage(err);
                    dispatch({
                        type: SAVE_NODE_FAILURE,
                        payload: {savedNodeId: nodeId, dirtyNodeIds: dirtyNodeIds, error: errorMessage, url: url}
                    });
                    reject(err.response || err.message);
                    reportError(`Failed to save ${node.type} ${nodeId}. ${errorMessage}.`, err);
                });
        });
    });
}

/**
 * Server does not like nulls
 * @param node
 * @param deepth
 */
const stripNullProperties = (node, deepth = 0) => {
    if (deepth > 10) {
        throw new Error("How did I get here");
    }
    if (node == null) {
        return null;
    }
    if (typeof node === 'object') {
        for (let propertyName of Object.keys(node)) {
            let property = node[propertyName];
            if (property == null) {
                delete node[propertyName];
            } else if (Array.isArray(property)) {
                for (let item of property) {
                    stripNullProperties(item, deepth + 1);
                }
            } else if (property instanceof Date && property.toISOString) {
                node[propertyName] = property.toISOString();
            } else if (typeof property === 'object') {
                stripNullProperties(property, deepth + 1);
            }
        }
    }
    return node;
};
let propertiesToCopyCache = {
    true: {},
    false: {}
};
const copyServerFields = (node, nodeSchema, isToClient = false) => {
    let propertiesToCopy = propertiesToCopyCache[isToClient][nodeSchema.type];
    if (!propertiesToCopy) {
        propertiesToCopy = {};
        Object
            .entries(nodeSchema.properties)
            .filter(([, a]) => a.storeServer !== false || (isToClient && a.retrieveServer === true))
            .filter(([name]) => name !== 'children' && name !== 'rules')
            .forEach(([name]) => propertiesToCopy[name] = true);
        propertiesToCopyCache[isToClient][nodeSchema.type] = propertiesToCopy;
    }
    const properties = Object.entries(node)
        .filter(([key]) => propertiesToCopy[key])
        .map(([key, value]) => parseProperty(nodeSchema, key, value));
    return properties.reduce(objectify, {});
};
let propertiesToCopyPatchCache = {
    true: {},
    false: {}
};
const copyPatchServerFields = (node, nodeSchema, isToClient = false) => {
    let propertiesToCopy = propertiesToCopyPatchCache[isToClient][nodeSchema.type];
    if (!propertiesToCopy) {
        propertiesToCopy = {};
        Object
            .entries(nodeSchema.properties)
            .filter(([, a]) => a.storeServer !== false || (isToClient && a.retrieveServer === true))
            .forEach(([name]) => propertiesToCopy[name] = true);
        propertiesToCopyPatchCache[isToClient][nodeSchema.type] = propertiesToCopy;
    }
    const properties = Object.entries(node)
        .filter(([key]) => propertiesToCopy[key])
        .map(([key, value]) => parseProperty(nodeSchema, key, value));
    return properties.reduce(objectify, {});
};
const parseProperty = (nodeSchema, property, value) => {
    let parsedValue;
    if (value == null) {
        parsedValue = value;
    } else {
        parsedValue = value;
    }
    return [property, parsedValue];
};
export const nodeTransforms = {
    ProcedureRoot: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getUrl: node => `/procedures?id=${node.id}&includeDeleted=true${node.includeSchema === true ? "&includeSchema=true" : ""}`,
        deleteUrl: node => `/procedures/${node.id}`,
        putUrl: node => `/procedures/${node.id}?import=${node.importOn || false}${node.pruneOn || node.importOn ? '&force=true' : ''}&responseFormat=${node.importOn || !node.createdDateTime ? 'full' : 'summary'}`,
        patchUrl: node => `/procedures/${node.rootId}?import=${node.importOn || false}&responseFormat=${node.importOn ? 'full' : 'summary'}`,
        getAllUrl: '/procedures',
        toServer: (clientProcedure, nodes, schema) => {
            return {
                ...copyServerFields(clientProcedure, schema.ProcedureRoot),
                children: clientProcedure.children.map(clientStepId => {
                    let clientStep = nodes[clientStepId];
                    return {
                        ...copyServerFields(clientStep, schema.ProcedureStep),
                        children: clientStep.children.map(clientTaskId => {
                            let clientTask = nodes[clientTaskId];
                            return {
                                ...copyServerFields(clientTask, schema.ProcedureTask),
                                children: clientTask.children.map(clientQuestionId => {
                                    let clientQuestion = nodes[clientQuestionId];
                                    return {
                                        ...copyServerFields(clientQuestion, schema.ProcedureQuestion),
                                    };
                                })
                            };
                        }),
                    };
                }),
                schemas: clientProcedure.schemas?.map(schemaId => {
                    const procedureSchema = nodes[schemaId];
                    return {
                        ...copyServerFields(procedureSchema, schema.ProcedureSchema),
                        properties: procedureSchema?.properties?.map(propId => {
                            const procedureSchemaProperty = nodes[propId];
                            return {
                                ...copyServerFields(procedureSchemaProperty, schema.ProcedureSchemaProperty),
                            }
                        })
                    }
                }),
                rules: clientProcedure.rules
                    .map(clientRuleId => nodes[clientRuleId])
                    .map(clientRule => {
                        return {
                            ...copyServerFields(clientRule, schema.ProcedureRule),
                        };
                    })
            };
        },
        toClient: (server, nodeType, schema) => {
            let copyServerFieldsRoot = server => {
                let root = copyServerFields(server, schema[server.type], true);
                let full = hasValue(server.children);
                root.rootId = root.id;
                root.id = full ? root.id : getProcedureSummaryId(root.id);
                return root;
            }
            let nodes = [];
            let number = 1;
            nodes.push({
                ...copyServerFieldsRoot(server, schema[server.type], true),
                children: (server.children || []).map(item => item.id),
                rules: (server.rules || []).map(item => item.id),
                ruleIds: [],
                schemas: (server.schemas || []).map(item => item.id),
                number: number++
            });
            (server.children || []).forEach(serverStep => {
                nodes.push({
                    ...copyServerFields(serverStep, schema[serverStep.type], true),
                    children: (serverStep.children || []).map(item => item.id),
                    ruleIds: [],
                    number: number++
                });
                (serverStep.children || []).forEach((serverTask) => {
                    nodes.push({
                        ...copyServerFields(serverTask, schema[serverTask.type], true),
                        children: (serverTask.children || []).map(item => item.id),
                        ruleIds: [],
                        number: number++
                    });
                    (serverTask.children || []).forEach((serverQuestion) => {
                        nodes.push({
                            ...copyServerFields(serverQuestion, schema[serverQuestion.type], true),
                            ruleIds: [],
                            number: number++
                        });
                    });
                });
            });
            (server.rules || []).forEach((serverQuestion) => {
                nodes.push({
                    ...copyServerFields(serverQuestion, schema[serverQuestion.type], true),
                    ruleIds: []
                });
            });
            const nodesById = keyBy(nodes, a => a.id);
            (server.rules || []).forEach((serverRule) => {
                (serverRule.nodeIds || []).forEach((id) => {
                    const node = nodesById[id];
                    if (node) {
                        node.ruleIds.push(serverRule.id);
                    }
                })
            });
            server.schemas?.forEach(procedureSchema => {
                nodes.push({
                    ...copyServerFields(procedureSchema, schema[procedureSchema.type], true),
                    properties: procedureSchema.properties.map(prop => prop.id)
                });

                procedureSchema.properties?.forEach(procedureSchemaProp => {
                    nodes.push({
                        ...copyServerFields(procedureSchemaProp, schema[procedureSchemaProp.type], true)
                    });
                })
            })
            server.loadedFull = server.children && server.children.length > 0;
            return nodes;
        },
        beforePatch: (patches) => {
            for (let patch of patches) {
                patch.id = procedureSummaryToFullId(patch.id);
            }
        },
        isPatchResponse: (node, response) => !!(response?.data?.id && !response?.data?.children) || false,
        toClientPatch: (patch, schemas, beforeNodes) => {
            let result = [];

            let beforeNode = beforeNodes[patch.id];
            let nodeSchema = schemas[patch.type || beforeNode?.type];
            let patchedNode = {
                ...copyPatchServerFields(patch, nodeSchema, true)
            }
            // As we have not loaded the children, may be missing changes
            delete patchedNode.lastUpdatedDateTime
            delete patchedNode.pruneOn
            result.push(patchedNode)

            return result;
        }
    },
    ProjectRoot: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getUrl: node => `/projects?id=${node.id}&includeDeleted=true`,
        deleteUrl: node => `/projects/${node.id}`,
        putUrl: node => `/projects/${node.id}`,
        patchUrl: node => `/projects/${node.id}`,
        getAllUrl: '/projects',
        toServer: (client, nodes, schema) => {
            return {
                ...copyServerFields(client, schema.ProjectRoot)
            };
        },
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                ...copyServerFields(server, schema[server.type], true)
            });
            return nodes;
        }
    },
    ExecutionRoot: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getUrl: node => `/executions?id=${node.id}`,
        deleteUrl: node => `/executions/${node.id}`,
        putUrl: node => `/executions/${node.id}`,
        patchUrl: node => `/executions/${node.rootId}?responseFormat=mergePatch&fromVersion=${node.version || ''}`,
        isPatchResponse: (node, response) => !!(response?.data?.patches) || false,
        searchUrl: (procedureType, procedureId) => `/executions?procedureType=${encodeURIComponent(procedureType || '')}&procedureId=${encodeURIComponent(procedureId || '')}&limit=100&summary=true`,
        // TODO Don't get all ...
        getAllUrl: '/executions',
        toServer: (clientExecution, nodes, schema) => {
            return {
                ...copyServerFields(clientExecution, schema.ExecutionRoot),
                children: clientExecution.children.map(clientStepId => {
                    let clientStep = nodes[clientStepId];
                    return {
                        ...copyServerFields(clientStep, schema.ExecutionStep),
                        children: clientStep.children.map(clientTaskId => {
                            let clientTask = nodes[clientTaskId];
                            return {
                                ...copyServerFields(clientTask, schema.ExecutionTask),
                                children: clientTask.children.map(clientQuestionId => {
                                    let clientQuestion = nodes[clientQuestionId];
                                    return {
                                        ...copyServerFields(clientQuestion, schema.ExecutionQuestion),
                                    };
                                })
                            };
                        })
                    };
                }),
                assignments: (clientExecution.assignments || []).map(clientAssignmentId => {
                    let clientAssignment = nodes[clientAssignmentId];
                    return {
                        ...copyServerFields(clientAssignment, schema.ExecutionAssignment),
                    }
                }),
                links: (clientExecution.links || []).map(clientLinkId => {
                    let clientLink = nodes[clientLinkId];
                    return {
                        ...copyServerFields(clientLink, schema.ExecutionLink),
                    }
                }),
                rules: clientExecution.rules.map(clientRuleId => {
                    let clientRule = nodes[clientRuleId];
                    return {
                        ...copyServerFields(clientRule, schema.ExecutionRule),
                    };
                })
            };
        },
        toClient: (server, nodeType, schema) => {
            let copyServerFieldsRoot = server => {
                let root = copyServerFields(server, schema[server.type], true);
                let full = hasValue(server.children);
                root.rootId = root.id;
                root.id = full ? root.id : getSummaryId(root.id);
                root.fields = {};
                for (let field of server.fields || []) {
                    root.fields[field.procedureNodeId || field.id] = field;
                }
                return root;
            }
            let nodes = [];
            nodes.push({
                ...copyServerFieldsRoot(server, schema[server.type], true),
                children: (server.children || []).map(item => item.id),
                links: (server.links || []).map(item => item.id),
                assignments: (server.assignments || []).map(item => item.id),
                rules: (server.rules || []).map(item => item.id),
                ruleIds: [],
                processedFull: true,
            });
            (server.children || []).forEach(serverStep => {
                nodes.push({
                    ...copyServerFields(serverStep, schema[serverStep.type], true),
                    children: (serverStep.children || []).map(item => item.id),
                    ruleIds: [],
                    processedFull: true,
                });
                (serverStep.children || []).forEach((serverTask) => {
                    nodes.push({
                        ...copyServerFields(serverTask, schema[serverTask.type], true),
                        children: (serverTask.children || []).map(item => item.id),
                        ruleIds: [],
                        processedFull: true,
                    });
                    (serverTask.children || []).forEach((serverQuestion) => {
                        nodes.push({
                            ...copyServerFields(serverQuestion, schema[serverQuestion.type], true),
                            ruleIds: [],
                            processed: true,
                        });
                    });
                });
            });
            (server.links || []).forEach((serverLink) => {
                nodes.push({
                    ...copyServerFields(serverLink, schema[serverLink.type], true),
                });
            });
            (server.assignments || []).forEach((serverAssignment) => {
                nodes.push({
                    ...copyServerFields(serverAssignment, schema[serverAssignment.type], true),
                });
            });
            (server.rules || []).forEach((serverRule) => {
                nodes.push({
                    ...copyServerFields(serverRule, schema[serverRule.type], true),
                    ruleIds: []
                });
            });
            const nodesById = keyBy(nodes, a => a.id);
            (server.rules || []).forEach((serverRule) => {
                (serverRule.nodeIds || []).forEach((id) => {
                    const node = nodesById[id];
                    if (node) {
                        node.ruleIds.push(serverRule.id);
                    }
                })
            });
            for (let n of nodes) {
                if (n && n.type === NODE_TYPE_OPTIONS.ExecutionQuestion && n.questionType === QUESTION_TYPES.geographic.id) {
                    const value = n.initialValue;
                    if (value != null && typeof value === 'object') {
                        if (value && value && !value.id) {
                            value.id = n.id;
                        }
                        if (value && value && !value.properties?.layerId) {
                            value.properties = {layerId: n.id, ...value.properties}
                        }
                    }
                }
                if (n.feature != null) {

                    if (!n.feature.id) {
                        n.feature = {id: n.id, ...n.feature}
                    }
                    if (!n.feature.properties?.layerId) {
                        n.feature.properties = {layerId: n.id, ...n.feature.properties}
                    }
                }
            }
            server.loadedFull = server.children && server.children.length > 0;
            return nodes;
        },
        beforePatch: (patches) => {
            for (let patch of patches) {
                patch.id = summaryToFullId(patch.id);
            }
        },
        toClientPatch: (server, schemas, beforeNodes) => {
            let result = [];
            for (let patch of server?.patches || []) {
                let beforeNode = beforeNodes[patch.id];
                let nodeSchema = schemas[patch.type || beforeNode?.type];
                let patchedNode = {
                    ...copyPatchServerFields(patch, nodeSchema, true)
                }
                result.push(patchedNode)
            }
            return result;
        }
    },
    ExecutionAssignment: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        putUrl: node => `/executions/${node.rootId}/assignments/${node.id}`,
        patchUrl: node => `/executions/${node.rootId}/assignments/${node.id}`,
        patchSingle: true,
        toServer: (client, nodes, schema) => {
            return {
                ...copyServerFields(client, schema.ExecutionAssignment)
            };
        },
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                ...copyServerFields(server, schema[server.type], true)
            });
            return nodes;
        }
    },
    ExecutionLink: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        putUrl: node => `/executions/${node.rootId}/links/${node.id}`,
        patchUrl: node => `/executions/${node.rootId}/links/${node.id}`,
        patchSingle: true,
        toServer: (client, nodes, schema) => {
            return {
                ...copyServerFields(client, schema.ExecutionLink)
            };
        },
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                ...copyServerFields(server, schema[server.type], true)
            });
            return nodes;
        }
    },
    Photo: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getUrl: node => `/photos?id=${node.id}`,
        putUrl: node => `/photos/${node.id}`,
        deleteUrl: node => `/photos/${node.id}`,
        patchUrl: node => `/photos/${node.id}`,
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                ...copyServerFields(server, schema[server.type], true),
            });
            return nodes;
        },
        toServer: (client) => {
            const getFromInMemory = () => fetch(client.originalInMemoryUrl).then(response => {
                return response.blob().then(blob => {
                    const photoData = {
                        id: client.id,
                        projectId: client.projectId,
                        filename: client.filename,
                        executionId: client.executionId,
                        executionQuestionId: client.executionQuestionId,
                        propertyName: client.propertyName,
                        photoTakenDate: client.photoTakenDate,
                        orientation: client.orientation || null,
                        coordinates: client.coordinates || null,
                        executionKey: client.executionKey,
                        executionTitle: client.executionTitle,
                        preview: client.preview,
                        executionName: client.executionName,
                        executionQuestionName: client.executionQuestionName,
                        procedureId: client.procedureId,
                        procedureQuestionId: client.procedureQuestionId
                    };
                    const formData = new FormData();
                    formData.append('data', JSON.stringify(photoData));
                    formData.append('images', blob);
                    return formData;
                });
            });
            return getDbNode(client.photoCaptureId).then(dbNode => {
                if ((!dbNode || !dbNode.node.originalData) && client.originalInMemoryUrl) {
                    // This is fallback logic for when the image doesn't make it into indexeddb
                    return getFromInMemory();
                }
                if (!dbNode) {
                    throw Error('Cannot find photo capture in indexeddb for photo ' + client.id + ' for photo ' + JSON.stringify(client));
                }
                if (!dbNode.node.originalData) {
                    throw Error('PhotoCapture from indexeddb has no originalData for photo ' + client.id + ' for photo ' + JSON.stringify(client));
                }
                const formData = new FormData();
                const photo = {
                    id: dbNode.node.id,
                    projectId: dbNode.node.projectId,
                    filename: dbNode.node.filename,
                    executionId: dbNode.node.executionId,
                    executionQuestionId: dbNode.node.executionQuestionId,
                    propertyName: dbNode.node.propertyName,
                    photoTakenDate: dbNode.node.photoTakenDate,
                    orientation: dbNode.node.orientation || null,
                    coordinates: dbNode.node.coordinates || null,
                    executionKey: client.executionKey,
                    executionTitle: client.executionTitle,
                    preview: client.preview,
                    executionName: client.executionName,
                    executionQuestionName: client.executionQuestionName,
                    procedureId: client.procedureId,
                    procedureQuestionId: client.procedureQuestionId,
                    markup: client.markup
                };
                formData.append('data', JSON.stringify(photo));
                formData.append('images', dataURLtoBlob(dbNode.node.originalData));
                return formData;
            }).catch(err => {
                reportError("Error loading photo capture from indexeddb to save.", err, client);
                if (client.originalInMemoryUrl) {
                    return getFromInMemory();
                }
                throw err;
            });
        },
        toServerConfig: (client, dispatch) => {
            const onUploadProgress = (progressEvent) => {
                const { loaded, total } = progressEvent;
                dispatch({
                    type: PUT_NODE_PROPERTY,
                    payload: {node: {id: client.id, uploadCompletedBytes: loaded, uploadTotalBytes: total}}
                })
            };
            const config = {
                onUploadProgress: onUploadProgress
            }
            return config;
        }
    },
    GroupRoot: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.IDENTITY, clientId, tenantId),
        credentials: 'identity',
        getAllUrl: '/groups',
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                type: nodeType,
                ...copyServerFields(server, schema[nodeType], true),
                rootId: server.id
            });
            return nodes;
        }
    },
    NodeView: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        postUrl: () => '/views',
        bulkPost: true,
        toServer: (client) => {
            return [{
                viewType: client.viewType,
                viewedDateTime: client.viewedDateTime,
                viewedId: client.viewedId
            }];
        },
    },
    NodeAssignment: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getAllUrl: '/assignments/mine',
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            nodes.push({
                type: nodeType,
                ...copyServerFields(server, schema[nodeType], true),
                executionId: server.rootId,
                id: NODE_TYPE_OPTIONS.NodeAssignment + server.id
            });
            for (let node of nodes) {
                if (node.feature) {
                    node.feature.id = node.id
                    node.feature.properties = {
                        layerId: node.id,
                        ...node.feature.properties
                    }
                }
            }
            return nodes;
        }
    },
    NodeActivity: {
        baseUrl: (clientId, tenantId) => deriveApiUrl(APP.SOP, clientId, tenantId),
        getAllUrl: '/activity/recent',
        toClient: (server, nodeType, schema) => {
            let nodes = [];
            // For distinct list we can use rootId as the id as when we get an update we only want to
            // use the latest per rootId
            nodes.push({
                type: nodeType,
                ...copyServerFields(server, schema[nodeType], true),
                id: NODE_TYPE_OPTIONS.NodeActivity + server.rootId
            });
            return nodes;
        }
    },
};

const getErrorMessage = (error) => {
    return get(error, ['response', 'data', 'message']) || error.message || error.description || error;
};
