import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import ProcedureShowDataGrid from "./ProcedureShowDataGrid";
import ProcedureShowExportButton from "./actions/ProcedureShowExportButton";
import ProcedurePreviewButton from "./actions/ProcedurePreviewButton";
import PageComponentBase from "../PageComponentBase";
import GraphDelete from "../graph/GraphDelete";
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import ProcedurePushDialog from "./ProcedurePushDialog";
import Grid from "@mui/material/Grid";
import ProcedurePreview from "./ProcedurePreview";
import ProcedureHelpDocument from "./ProcedureHelpDocument";
import NodeVisited from "../graph/GraphNodeVisited";
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import GraphDestroy from "../graph/GraphDestroy";
import GraphDeleteMessage from "../graph/GraphDeleteMessage";
import GraphDestroyedMessage from "../graph/GraphDestroyedMessage";
import ProcedureSearchExecutionsButton from "./actions/ProcedureSearchExecutionsButton";
import ProcedureHistoryButton from "./actions/ProcedureHistoryButton";
import {
    Breadcrumbs,
    cypress,
    HistoryBackButton,
    PageContentWrapper,
    PageHeader,
    SharedAuth,
    TbfOptionsMenu
} from "tbf-react-library";
import {convertOptionObjectToSelectOptions, uniqueArray} from "../../util/util";
import {hasProcedurePermission, isProcedureLoadedFull} from "../../selectors/procedureSelectors";
import {getProcedureTypeIcon} from "../../util/image";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Input from "@mui/material/Input";
import {putNodeProperty} from "../../actions";
import ProcedureSettings from "./settings/ProcedureSettings";
import Typography from "@mui/material/Typography";
import GraphNodeSaveErrorMessage from "../graph/GraphNodeSaveErrorMessage";
import AddNewExecutionButton, {AddNewStyle} from "../workItems/AddNewExecutionButton";
import ProcedurePruneButton from "./ProcedurePruneButton";
import ProcedureEditButton from "./actions/ProcedureEditButton";
import ProcedureReviewButton from "./actions/ProcedureReviewButton";
import ProcedureReferences from "./references/ProcedureReferences";
import ProcedureSharing from "./sharing/ProcedureSharing";
import ProcedureSchema from "./schemas/ProcedureSchema";
import {HOURS_1, MINUTES_2} from "../../util/constants";
import ProcedureVersionsAction from "./actions/ProcedureVersionsAction";
import {GraphLoadGroup} from "../graph/GraphLoadGroup";
import AlphaFeature from "../components/AlphaFeature";

export const PROCEDURE_SHOW_TAB_OPTIONS = {
    build: {id: 'build', name: 'Build', position: 0},
    settings: {id: 'settings', name: 'Settings', position: 1},
    references: {id: 'references', name: 'References', position: 2},
    sharing: {id: 'sharing', name: 'Sharing', position: 3}
};


class ProcedureShow extends PageComponentBase {
    state = {
        selectedTabIndex: PROCEDURE_SHOW_TAB_OPTIONS.build.position,
    }

    breadcrumbs = [{name: strings.procedure.list.breadCrumb, to: '/procedures'}];

    constructor() {
        super();

        if(SharedAuth.userHasPermission(Permissions.feature.alpha)) {
            PROCEDURE_SHOW_TAB_OPTIONS['schema'] = {id: 'schema', name: 'Schema', position: 4}
        }
    }

    handleTabChanged = (e, value) => {
        let {onPutNodeProperty, nodeId} = this.props;
        onPutNodeProperty({id: nodeId, selectedShowTab: value})
    }

    renderPageTabs = () => {
        const {selectedShowTab} = this.props;
        let tabs = convertOptionObjectToSelectOptions(PROCEDURE_SHOW_TAB_OPTIONS);
        if (!SharedAuth.userHasPermission(Permissions.feature.alpha)) {
            tabs = tabs.filter(a => a.value !== PROCEDURE_SHOW_TAB_OPTIONS.references.id);
        }

        return (
            <Tabs
                value={selectedShowTab || PROCEDURE_SHOW_TAB_OPTIONS.build.id}
                variant="scrollable"
                scrollButtons
                onChange={this.handleTabChanged}
                allowScrollButtonsMobile
                >
                {tabs.map(tab => (
                    <Tab key={tab.value} label={tab.label} value={tab.value}/>
                ))}
            </Tabs>
        );
    }

    renderPageActions = () => {
        let {
            classes,
            nodeId,
            ready,
            destroyed,
            disabled,
            procedureType,
            canDelete
        } = this.props;
        const {selectedTabIndex} = this.state;

        const executionAddStyle = cypress.isCypress() ? AddNewStyle.page : AddNewStyle.dialog;

        return <>
            {
                ready && !destroyed &&
                <div data-cy={'ProcedureToolbar'} className={classes.procedureToolbar}>
                    <ProcedureReviewButton procedureId={nodeId}/>
                    <ProcedureEditButton procedureId={nodeId}/>
                    <ProcedureSearchExecutionsButton procedureId={nodeId} procedureType={procedureType}/>
                    {
                        selectedTabIndex === PROCEDURE_SHOW_TAB_OPTIONS.build.position &&
                        <ProcedurePreviewButton procedureId={nodeId}/>
                    }
                    <AddNewExecutionButton procedureId={nodeId} style={executionAddStyle}
                                           primaryAction={true}/>
                    <TbfOptionsMenu>
                        <ProcedureHistoryButton procedureId={nodeId}/>
                        <ProcedureVersionsAction procedureId={nodeId} menuItem={true}/>
                        <ProcedurePushDialog procedureId={nodeId}/>
                        <ProcedureShowExportButton nodeId={nodeId}/>
                        {
                            !disabled && canDelete &&
                            <GraphDelete nodeId={nodeId} menuItem={true}/>
                        }
                        {
                            !disabled &&
                            <GraphDestroy nodeId={nodeId} isListItem={true}/>
                        }
                        <ProcedurePruneButton procedureId={nodeId}/>
                    </TbfOptionsMenu>
                </div>
            }
        </>
    }

    renderNameInput = () => {
        const {classes, name, nodeId, onPutNodeProperty, disabled} = this.props;
        if (disabled) {
            return name;
        }
        return <Input className={classes.procedureNameInput + ' textFieldPrimary'}
                      value={name || ''}
                      onChange={(event) => onPutNodeProperty({id: nodeId, name: event.target.value})}/>
    }

    render() {
        let {
            classes,
            nodeId,
            name,
            ready,
            sideBySidePreviewOn,
            destroyed,
            loadProcedureIds,
            disabled,
            procedureType,
            selectedShowTab,
            editOn,
            includeSchema
        } = this.props;

        return (
            <div>
                <GraphNodeSaveErrorMessage nodeId={nodeId}/>
                <GraphDeleteMessage nodeId={nodeId}/>
                <GraphDestroyedMessage nodeId={nodeId}/>
                <Typography variant={'h1'} hidden={true} id={'hiddenTitleElement'}>{name}</Typography>
                <PageHeader
                    fullWidthTitle={true}
                    title={this.renderNameInput()}
                    PageIcon={<img src={getProcedureTypeIcon(procedureType)} alt={''}/>}
                    renderNavigationActions={() => <HistoryBackButton/>}
                    renderPageTabs={this.renderPageTabs}
                    renderPageActions={this.renderPageActions}
                />

                <Breadcrumbs list={this.breadcrumbs}/>

                <GraphResourceLoad
                    friendlyName={strings.procedure.name}
                    resourcePath={NODE_IDS.ProcedureFull(nodeId)}
                    nodeId={nodeId}
                    reloadIntervalMs={editOn ? MINUTES_2 : undefined}
                    nodeType={'ProcedureRoot'}
                    extraProps={{
                        includeSchema: true
                    }}
                    skipDeltaLoad={!includeSchema}
                />
                <GraphLoadGroup hideLoader={true}/>
                <GraphResourceLoad
                    resourcePath={NODE_IDS.ProcedureSummaryAll}
                    friendlyName={strings.procedure.namePlural}
                    hideLoader={true}
                    nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}
                />
                {
                    loadProcedureIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={NODE_IDS.ProcedureFull(id)}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ProcedureRoot'}
                            reloadIntervalMs={HOURS_1}
                        />))
                }
                {
                    ready &&
                    <PageContentWrapper>
                        {
                            selectedShowTab === PROCEDURE_SHOW_TAB_OPTIONS.build.id &&
                            <Grid container className={classes.procedureGrid}>
                                <Grid item xs={sideBySidePreviewOn ? 6 : 12} data-cy={'Procedure'}>
                                    {
                                        !destroyed &&
                                        <React.Fragment>
                                            <ProcedureHelpDocument procedureId={nodeId}/>
                                            <ProcedureShowDataGrid nodeId={nodeId} disabled={disabled}/>
                                        </React.Fragment>
                                    }
                                </Grid>
                                {
                                    sideBySidePreviewOn &&
                                    <Grid item xs={6} className={classes.previewGridItem}>
                                        <ProcedurePreview procedureId={nodeId}/>
                                    </Grid>
                                }
                            </Grid>
                        }
                        {
                            selectedShowTab === PROCEDURE_SHOW_TAB_OPTIONS.settings.id &&
                            <ProcedureSettings procedureId={nodeId} disabled={disabled}/>
                        }
                        {
                            selectedShowTab === PROCEDURE_SHOW_TAB_OPTIONS.references.id &&
                            <ProcedureReferences procedureId={nodeId}/>
                        }
                        {
                            selectedShowTab === PROCEDURE_SHOW_TAB_OPTIONS.sharing.id &&
                            <ProcedureSharing procedureId={nodeId} disabled={disabled}/>
                        }

                        {
                            selectedShowTab === PROCEDURE_SHOW_TAB_OPTIONS.schema?.id &&
                            <ProcedureSchema procedureId={nodeId} disabled={disabled}/>
                        }
                    </PageContentWrapper>
                }
                <NodeVisited nodeId={nodeId} nodeType={NODE_TYPE_OPTIONS.ProcedureRoot}/>
            </div>
        )
    }
}

const styles = (theme) => ({
    grow: {
        flexGrow: 1,
    },
    previewGridItem: {
        borderLeft: 'solid 1px black',
        paddingLeft: '10px',
        overflowY: 'auto',
        height: 'calc(100vh - 210px)',
    },
    procedureGrid: {
        marginBottom: -20,
        padding: 10,
    },
    procedureNameInput: {
        width: '100%',
        padding: '0 10px',
        borderRadius: 4,
        fontFamily: theme.fonts.primary.bold,
        '& .MuiInputBase-input': {
            background: theme.palette.primary.two.main,
            borderColor: 'transparent',
        },
        '&.MuiInput-underline': {
            '&:before, &:after': {
                display: 'none'
            }
        },
        '&:focus': {
            border: `1px solid ${theme.palette.grey.two.main}`
        },
    },
    procedureToolbar: {
        display: 'flex',
        gap: '10px'
    }
});
ProcedureShow.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.procedureId;
    let node = getNodeOrNull(state, nodeId);
    let rules = getNodesIfPresent(state, (node && node.rules) || []).filter(a => !a.deleted);
    let referencedProcedureIds = [
        ...rules.flatMap(a => a.linkMatchProcedureIds || []),
        ...rules.map(a => a.createExecutionProcedureId).filter(a => a),
    ].filter(a => a !== nodeId);
    referencedProcedureIds = uniqueArray(referencedProcedureIds);
    const canEdit = node?.editOn && !node?.importOn && hasProcedurePermission(state, nodeId, Permissions.procedure.edit);
    const disabled = !canEdit;
    return {
        nodeId: nodeId,
        ready: isProcedureLoadedFull(node),
        sideBySidePreviewOn: node?.sideBySidePreviewOn,
        deleted: node?.deleted,
        name: node?.name || null,
        procedureType: node?.procedureType || null,
        destroyed: node?.destroyed,
        selectedShowTab: node?.selectedShowTab || PROCEDURE_SHOW_TAB_OPTIONS.build.id,
        loadProcedureIds: referencedProcedureIds,
        disabled: disabled,
        canDelete: hasProcedurePermission(state, nodeId, Permissions.procedure.delete),
        editOn: node?.editOn,
        includeSchema: node?.includeSchema
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProcedureShow));
