import {loadClientCustomConfig, setClientConfig} from "../actions/configuration"
import {getClientConfiguration, setClientConfiguration, SharedAuth} from "tbf-react-library"

const USE_TEST_FILE = false;
let configLoaded = false;
let currentConfig = null
const clientConfiguration = store => {

    const handleDownloadResponse = response => {
        let data = response.data;
        if (typeof data === 'string') {
            data = JSON.parse(data);
        }
        const downloadedVersion = data?.version || 0;
        const currentVersion = currentConfig?.version || -1;
        if (data && data.clientId && downloadedVersion > currentVersion) {
            setClientConfiguration(data);
            store.dispatch(setClientConfig(data))
        }
    }
    // by default client will not have one, so expect 404
    const ahhhSilence = () => {
    }

    // This is for local testing of a config file
    if (USE_TEST_FILE) {
        fetch('/app/CLIENT_TEST_CONFIG.json')
            .then(data => data.json())
            .then(data => handleDownloadResponse({data}))
            .catch((e) => console.error(e))
    } else {
        let fetcher = () => {

            const clientId = SharedAuth.getClientId();
            const tenantId = SharedAuth.getTenantId();
            currentConfig = getClientConfiguration(clientId, tenantId);

            if (currentConfig) {
                store.dispatch(setClientConfig(currentConfig))
            }
            const anonymous = SharedAuth.isAnonymous()
            if ((!anonymous && !SharedAuth.hasActiveToken()) || configLoaded) {
                return;
            }
            if (!currentConfig) {
                // Load once hitting browser cached version
                window.setTimeout(() => {
                    loadClientCustomConfig(false, anonymous)
                        .then(handleDownloadResponse)
                        .catch(ahhhSilence);
                }, 250)
            }
            // Load latest version just in case
            window.setTimeout(() => {
                loadClientCustomConfig(true, anonymous)
                    .then(handleDownloadResponse)
                    .catch(ahhhSilence);
            }, 5000)
            configLoaded = true;
        };
        SharedAuth.addLoginListener(fetcher);
    }

    return next => action => {
        return next(action)
    }
}

export default clientConfiguration
