import React from "react";
import {Link} from "react-router-dom";
import withStyles from '@mui/styles/withStyles';
import cn from 'classnames'

const TableCellSpannedLink = ({children, classes, customClass, to, style, expand, ...rest}) => {
    return (
      <Link
        className={cn('table-cell-spanned-link', classes.fullAreaSpannedLink, customClass, {[classes.expand]: expand})}
        style={style}
        to={to}
        {...rest}
      >
        {children}
      </Link>
    )
}

const styles = () => ({
    fullAreaSpannedLink: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        padding: 16,
        color: 'inherit',
        minHeight: 72,
    },
    expand: {
      width: "fit-content",
      whiteSpace: "pre-wrap",
      maxWidth: 500,
    }
});

export default withStyles(styles)(TableCellSpannedLink);
