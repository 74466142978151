import React, {useCallback} from 'react';
import '../../style/alert.css';
import {executionPull} from "../../actions/executions";
import CallReceivedIcon from '@mui/icons-material/CallReceivedRounded';
import {reportSuccess, reportUserError, TbfAction} from "tbf-react-library";
import {strings} from "../components/SopLocalizedStrings";
import {Permissions} from "../../permissions";
import {useDispatch} from "react-redux";
import {markNodeForReload} from "../../actions";
import {useHasProcedurePermission} from "../../hooks/procedureHooks";

const pageStrings = strings.execution.pull;
const ExecutionPull = React.forwardRef(({nodeId, handleClicked}, ref) => {

    const dispatch = useDispatch()
    const handlePull = useCallback(() => {
        executionPull(nodeId)
            .then(response => {
                if (response.data?.pushResult) {
                    reportSuccess(pageStrings.successChanges + ' ' + response.data.pushResult);
                } else {
                    reportSuccess(pageStrings.successNoChanges);
                }
                dispatch(markNodeForReload(nodeId))
            }).catch(error => {
            reportUserError(error, null, pageStrings.fail);
        });
    }, [dispatch, nodeId])
    const canPull = useHasProcedurePermission(nodeId, Permissions.procedure.push)
    if (!canPull) {
        return null
    }
    return (<TbfAction
        menuItem={true}
        onClick={handlePull}
        title={pageStrings.title}
        iconComponent={CallReceivedIcon}
        ref={ref}
    >
        {pageStrings.button}
    </TbfAction>)
})
export default ExecutionPull;