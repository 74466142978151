import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getNodeOrNull, getNodeSchemaOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {putNodeProperty} from "../../actions";
import IconButton from "@mui/material/IconButton";
import {SharedAuth} from "tbf-react-library";
import {Permissions} from "../../permissions";
import DeleteForeverIcon from '@mui/icons-material/DeleteForeverRounded';
import * as PropTypes from "prop-types";
import {MenuItem} from "@mui/material";
import {strings} from "../components/SopLocalizedStrings";

class NodeDestroy extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleDeleteClicked = () => {
        let {nodeId, nodeSchema, handleClicked} = this.props;
        handleClicked && handleClicked();
        if (window.confirm(`Are you sure you want to permanently delete this ${nodeSchema.displayName}?`)) {
            let nodeUpdate = {id: nodeId, destroyed: true, deleted: true};
            this.props.onPutNodeProperty(nodeUpdate);
        }
    };

    render() {
        let {nodeSchema, canDelete, isListItem, classes} = this.props;
        if (!canDelete) {
            return null;
        }

        if (isListItem) {
            return <MenuItem onClick={this.handleDeleteClicked}>
                <span className={classes.listItemWrapper} aria-label={strings.buttons.permanentlyDelete}
                      data-cy={'GraphDestroy'}>
                    <span className={classes.iconWrapper}>
                        <DeleteForeverIcon/>
                    </span>
                <label className='listItemLabel'>{strings.buttons.permanentlyDelete}</label>
            </span>
            </MenuItem>;
        }
        return (
            <IconButton
                color={'primary'}
                title={`Permanently delete this ${nodeSchema.displayName}`}
                onClick={this.handleDeleteClicked}
                data-cy={'GraphDestroy'}
                size="large">
                <DeleteForeverIcon/>
            </IconButton>
        );
    }
}

const styles = (theme) => ({
    grow: {
        flexGrow: 1,
    },
    addLink: {textDecoration: 'none'},
    listItemWrapper: {
        color: theme.palette.grey.seven.main,
        fontSize: '14px',
        width: '100%',
        paddingTop: 5,
        '& .listItemLabel': {
            verticalAlign: 'middle'
        }
    },
    iconWrapper: {
        position: 'relative',
        width: 'auto',
        float: 'left',
        marginRight: 8,
        top: 1,
    },
});
NodeDestroy.propTypes = {
    nodeId: PropTypes.string.isRequired
};
const nodeTypeToPermission = {
    'ExecutionRoot': Permissions.execution.destroy,
    'ProcedureRoot': Permissions.procedure.destroy,
};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.nodeId;
    let node = getNodeOrNull(state, nodeId);
    let requiredPermission = node.type ? nodeTypeToPermission[node.type] : 'not-loaded';
    let canDelete = SharedAuth.userHasPermission(requiredPermission);
    let showButton = canDelete && node && node.destroyed !== true;
    return {
        nodeSchema: getNodeSchemaOrNull(state, node.type),
        canDelete: showButton
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        onPutNodeProperty: node => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NodeDestroy));
