import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useCallbackPatchNode, useChildRuleByActionOrNull, useCreateNode, useNodeOrNull, useNodesIfPresent } from "../../hooks/nodeHooks";
import { AFTER_ADD_NAVIGATION_MODE, NODE_IDS, NODE_TYPE_OPTIONS, RULE_ACTION_TYPE } from "../../reducers/graphReducer";
import ExecutionComplete from "./ExecutionComplete";
import { ExecutionQuestionsGridItems } from "./ExecutionTask";
import { Button, Checkbox, DialogActions, DialogContent, FormControlLabel, Grid } from "@mui/material";
import { strings } from "../components/SopLocalizedStrings";
import { getJsonDate, highlightIncompleteFieldsInForm } from "../../util/util";
import { SharedAuth, cypress, reportSuccess, useMobile } from "tbf-react-library";
import {useHistory} from 'react-router-dom';
import cn from "classnames";
import ExecutionShowDependencies from "./ExecutionShowDependencies";
import { Link, Prompt } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { openConfirmationDialog } from "../../actions/confirmation";

const pageStrings = strings.execution.addLinkNewDialog;

const useStyle = makeStyles((theme) => ({
    paper: {
        width: "95%",
        maxWidth: "1280px",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            maxWidth: "auto",
        }
    },
    taskContainer: {
        padding: "10px 16px 0px 30px",
        overflowX: "hidden",
    },
    titleContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    title: {
        display: "flex",
        columnGap: theme.spacing(2),
        alignItems: "center",
        maxWidth: "1100px",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "calc(100vw - 20%)",
        },
        "& span": {
            overflow: "hidden",
            maxWidth: "100%",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        }
    },
    addMoreButton: {
        marginRight: 0,
    },
    actions: {
        padding: `0 ${theme.spacing(2.5)} ${theme.spacing(3)}`,
    }
}));

export const useWarnIfUnsavedChanges = (hasUnsaved) => {
    useEffect(() => {
        if (hasUnsaved) {
            const handleBeforeUnload = (e) => e.preventDefault();

            window.addEventListener("beforeunload", handleBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            }
        }
    }, [hasUnsaved]);
}

const ExecutionAddNewDialog = ({procedureId, onAddCompleted, open, onClose, disableStartEditing, defaultMode = AFTER_ADD_NAVIGATION_MODE.default.id, linkProperties = {}}) => {

    const dispatch = useDispatch();

    const ref = useRef();

    const classes = useStyle();

    const executionLinkNew = useCreateNode(NODE_TYPE_OPTIONS.ExecutionLinkNew, {...linkProperties, procedureId}, open);

    const procedure = useNodeOrNull(procedureId);

    const execution = useNodeOrNull(executionLinkNew?.executionId);

    const firstStep = useNodeOrNull(execution?.children[0]);

    const firstTask = useNodeOrNull(firstStep?.children[0]);

    const user = useNodeOrNull(NODE_IDS.User);

    const patch = useCallbackPatchNode();

    const mobileViewPort = useMobile();
    
    const history = useHistory();

    const [submitted, setSubmitted] = useState(false);

    const [attempted, setAttempted] = useState(false);

    const [addMore, setAddMore] = useState(false);
    
    const firstTaskAllQuestionsCompleted = useMemo(() => firstTask?.allQuestionsCompleted, [firstTask?.allQuestionsCompleted]);

    const questions = useNodesIfPresent(firstTask?.children);

    const afterAddDialogRule = useChildRuleByActionOrNull(procedureId, RULE_ACTION_TYPE.afterAddNavigationMode.id);
    const afterAddNavigationMode = !!afterAddDialogRule && !afterAddDialogRule?.deleted ? afterAddDialogRule.format : defaultMode;

    const addMoreAvailableRule = useChildRuleByActionOrNull(procedureId, RULE_ACTION_TYPE.addMoreAvailable.id);
    const addMoreAvailable = !!addMoreAvailableRule && !addMoreAvailableRule?.deleted;

    const getTaskFormElement = () => {
        const form = ref.current.getElementsByTagName('form')[0] || ref.current;
        const scrollContainer = ref.current.getElementsByClassName('scrollContainer')[0];
        return {form, scrollContainer};
    }

    const localsRef = useRef({
        cancelOnUnmount: true,
        executionLinkNewId: null,
        patch: () => {},
    })

    useEffect(() => {
        localsRef.current.executionLinkNewId = executionLinkNew?.id;
    }, [executionLinkNew?.id])

    useEffect(() => {
        localsRef.current.patch = patch;
    }, [patch])

    const submitWorkItem = useCallback(() => {
        const updatedNode = {
            id: firstTask?.id,
            userCompleted: {
                completed: true,
                completedDate: getJsonDate(),
                completedUserEmail: user?.email,
                completedUserName: user?.name,
                completedUserId: SharedAuth.getUserId(),
            }
        }
        patch(updatedNode);
        patch({id: executionLinkNew?.id, submit: true});
        localsRef.current.cancelOnUnmount = false;
    }, [patch, firstTask?.id, executionLinkNew?.id, user?.email, user?.name])
    
    const questionsAttempted = useMemo(() => questions.some(q => q.completed && q.visible && !q.disabled && !q.initialValueReadOnly), [questions]);

    const handleCancel = useCallback(() => {
        const cancel = () => {
            patch({id: executionLinkNew?.id, cancel: true});
            onClose?.();
        }
        if (questionsAttempted) {
            dispatch(openConfirmationDialog({
                title: pageStrings.unsavedChangesTitle,
                description: pageStrings.unsavedChanges,
                action: cancel
            }));
        } else {
            cancel();
        }
    }, [patch, questionsAttempted, firstTask?.allQuestionsCompleted, executionLinkNew?.id]);

    useLayoutEffect(() => {
        return () => {
            if (localsRef.current.executionLinkNewId && localsRef.current.cancelOnUnmount) {
                localsRef.current.patch({id: localsRef.current.executionLinkNewId, cancel: true});
            }
        }
    }, []);

    useEffect(() => {
        if (submitted) {
            if (addMore) {
                patch({id: executionLinkNew?.id, executionId: null})
            } else {
                const shouldOpenExecution = (!execution?.preview && (execution?.children.length > 1 || firstStep?.children.length > 1 || cypress.isCypress()));
                if (!disableStartEditing && afterAddNavigationMode !== AFTER_ADD_NAVIGATION_MODE.addAndClose.id && (afterAddNavigationMode === AFTER_ADD_NAVIGATION_MODE.addAndOpen.id || shouldOpenExecution)) {
                    history.push(`/executions/${execution?.id}`);
                } else {
                    const message = <span>
                        {pageStrings.successfullyAddedWorqItem}
                        {' '}
                        <Link title={execution?.title} to={`/executions/${execution?.id}`}>
                            {execution?.title}
                        </Link>
                    </span>;
                    reportSuccess(message)
                    onClose?.();
                }
            }
            onAddCompleted?.({id: execution?.id, title: procedure?.name, linkType: linkProperties?.linkType});
            setSubmitted(false);
        }
    }, [submitted])

    const handleSubmit = useCallback(() => {
        if (firstTaskAllQuestionsCompleted) {
            submitWorkItem();
            setSubmitted(true);
        } else {
            setAttempted(true);
            const {form, scrollContainer} = getTaskFormElement();
            highlightIncompleteFieldsInForm(form, scrollContainer);
        }
    }, [
        patch,
        executionLinkNew?.id,
        addMore,
        afterAddNavigationMode,
        submitWorkItem,
        firstTaskAllQuestionsCompleted,
        executionLinkNew?.id,
        execution?.id,
        execution?.title,
        execution?.children.length,
        firstTask?.children.length,
        linkProperties?.linkType,
        execution?.preview,
        execution?.id,
        procedure?.name,
    ]);

    const handleClose = () => {
        handleCancel();
    }

    const handleAddMoreChecked = (event) => {
        setAddMore(event.target.checked);
    }

    useWarnIfUnsavedChanges(questionsAttempted);
    
    const handleKeyDown = e => e.stopPropagation();

    const {id, disabled, allQuestionsCompleted, procedureTaskId, columnWidth, children} = firstTask ?? {};

    return <Dialog
            id="add-execution-dialog"
            ref={ref}
            open={open && !!executionLinkNew?.id}
            onClose={handleClose}
            fullScreen={mobileViewPort}
            PaperProps={{
                className: cn(classes.paper, {"form-attempted": attempted}),
                "data-cy": "add-execution-dialog"
            }}
            onKeyDown={handleKeyDown}
            data-cy-will-prompt={questionsAttempted && !firstTaskAllQuestionsCompleted}
        >
        <Prompt when={questionsAttempted && !submitted} message={`${pageStrings.unsavedChangesTitle} ${pageStrings.unsavedChanges}`} />
        <DialogTitle className={classes.titleContainer}>
            <div className={classes.title}>
                <span>{procedure?.name}</span>
            </div>
        </DialogTitle>
        <DialogContent className={cn("scrollContainer")}>
            <Grid container className={classes.taskContainer} data-cy-element={"Task"}>
                <ExecutionComplete nodeId={id} disabled={disabled} hideSignOffAction={true} create={true}>
                    <ExecutionQuestionsGridItems
                        procedureTaskId={procedureTaskId}
                        questionIds={children}
                        taskColumnWidth={columnWidth}
                    />
                </ExecutionComplete>
            </Grid>
            {execution && <ExecutionShowDependencies executionId={execution.id} />}
        </DialogContent>
        <DialogActions className={classes.actions}>
            {addMoreAvailable &&
                <FormControlLabel
                    className={classes.addMoreButton}
                    control={<Checkbox
                        checked={addMore}
                        onChange={handleAddMoreChecked}
                        name="addMore"
                        data-cy={"add-more"}
                    />}
                    label="Add more"
                />
            }
            <Button
                color='secondary'
                variant='contained'
                className={classes.button}
                title={strings.app.cancel}
                onClick={handleClose}
                data-cy='add-link-cancel'
            >
                {strings.app.cancel}
            </Button>
            <Button
                variant='contained'
                color={allQuestionsCompleted ? 'primary' : 'inherit'}
                aria-invalid={!allQuestionsCompleted}
                title={pageStrings.submit}
                onClick={handleSubmit}
                type='submit'
                data-cy='add-link-submit'
            >
                {pageStrings.submit}
            </Button>
        </DialogActions>
    </Dialog>;
}

export default ExecutionAddNewDialog;