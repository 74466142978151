import React, {useCallback} from "react";
import {Permissions} from "../../../permissions";
import {reportBusinessError, reportEvent, reportInfo, TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";
import {useCallbackPatchNode, useNodeOrNull, useNodePropertyOrNull} from "../../../hooks/nodeHooks";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import {EXECUTION_STATUS, NODE_IDS} from "../../../reducers/graphReducer";
import {isOnline} from "../../../util/util";
import { IconButton } from "@mui/material";
import { OfflinePin } from "@mui/icons-material";

const ExecutionOfflineAction = React.forwardRef(({executionId, small, className, stopPropagation, showNotAvailableOffline = true}, ref) => {
    const offlineExecutions = useNodePropertyOrNull(NODE_IDS.UserDevice, a => a.offlineExecutions) || {}
    const offline = !!offlineExecutions[executionId]?.on
    const implicit = !!offlineExecutions[executionId]?.onImplicit
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)

    let patch = useCallbackPatchNode({
        id: NODE_IDS.UserDevice,
        offlineExecutions: {...offlineExecutions, [executionId]: {on: !offline}}
    })
    let onClick = useCallback((e) => {
        if (stopPropagation) {
            e.stopPropagation();
        }
        if (implicit) {
            reportBusinessError(strings.execution.show.offlineImplicitly)
        } else {
            patch()
            if (!offline) {
                reportEvent({name: 'Execution.Offline.On', id: executionId})
                if (isOnline()) {
                    reportInfo(strings.execution.show.offlineTurnOnWhenOnline)
                } else {
                    reportInfo(strings.execution.show.offlineTurnOnWhenOffline)
                }
            } else {
                reportEvent({name: 'Execution.Offline.Off', id: executionId})
            }
        }
    }, [patch, offline, implicit])
    
    if (!canView || (!offline && !showNotAvailableOffline)) {
        return null
    }
    const msg = offline ? strings.execution.show.offlineTurnOff : strings.execution.show.offlineTurnOn
    
    if (small) {
        return <IconButton
            data-cy='toggle-available-offline'
            onClick={onClick}
            className={className}
            aria-label={msg}
            ref={ref}
            title={offline ? strings.execution.show.offlineOnIconOnlyTitle : strings.execution.show.offlineTitle}
        >
            <OfflinePin />
        </IconButton>
    }

    return (
        <TbfAction
            menuItem={true}
            title={strings.execution.show.offlineTitle}
            dataCy='toggle-available-offline'
            iconComponent={CheckCircleOutline}
            onClick={onClick}
            className={className}
            ref={ref}
        >
            {msg}
        </TbfAction>
    )
})
export default ExecutionOfflineAction;
