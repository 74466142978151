import React from "react";
import withStyles from '@mui/styles/withStyles';
import AssignmentStatus from "../assignments/AssignmentStatus";
import Button from "@mui/material/Button";
import {getNodeOrNull} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Popover} from "@mui/material";
import WorkItemProgressDetails from "./WorkItemProgressDetails";
import {strings} from "../components/SopLocalizedStrings";

class WorkItemStatusWithDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            progressAnchorEl: null,
        }
    }

    handleOpenProgressPopover = (event) => {
        this.setState({progressAnchorEl: event.target});
    }

    handleCloseProgressPopover = () => {
        this.setState({progressAnchorEl: null});
    }

    render() {
        const {classes, status, totalCount, completedCount} = this.props;
        const {progressAnchorEl} = this.state;
        const showProgressPopover = Boolean(progressAnchorEl);

        return <React.Fragment>
            <Button onClick={this.handleOpenProgressPopover}
                    title={strings.buttons.showProgressPopover}
                    className={classes.statusWrapper}>
                <AssignmentStatus status={status}/>
            </Button>

            {
                showProgressPopover &&
                <Popover
                    open={showProgressPopover}
                    anchorEl={progressAnchorEl}
                    onClose={this.handleCloseProgressPopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <WorkItemProgressDetails
                        status={status}
                        totalQuestionCount={totalCount}
                        completedQuestionCount={completedCount}
                        handleCloseProgressPopover={this.handleCloseProgressPopover}
                    />
                </Popover>
            }
        </React.Fragment>
    }
}

const styles = theme => ({
    statusWrapper: {
        display: 'flex',
        alignItems: 'center',
        '& svg': {
            fontSize: 18,
            marginRight: 5
        },
        '& .title': {
            fontSize: 12,
            color: theme.palette.grey.five.main,
            textTransform: 'capitalize',
        }
    },
});

const mapStateToProps = (state, ownProps) => {
    const workItem = getNodeOrNull(state, ownProps.id);

    return {
        id: workItem.id,
        status: workItem.status,
        totalCount: workItem.totalQuestionCount + workItem.totalSignoffCount,
        completedCount: workItem.completedQuestionCount + workItem.completedSignoffCount,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(WorkItemStatusWithDetails)));
