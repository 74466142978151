import React from 'react'
import {Link, withRouter} from 'react-router-dom'
import {getProcedureTypeIcon} from '../../util/image'
import {ClickableArea} from "tbf-react-library";
import withStyles from '@mui/styles/withStyles';
import {TruncateReadMoreText} from "tbf-react-library";

const ActivityCard = ({classes, rootId, procedureType, rootTitle, nodeTitle, title, children}) => {
    const includeBothTitles = nodeTitle && rootTitle && nodeTitle !== rootTitle;

    return (
        <ClickableArea className={classes.card} role="listitem">
            <div>
                <img className={classes.icon} src={getProcedureTypeIcon(procedureType)} alt={''}/>
                <span className={classes.header}>
                    <Link data-cy='recent-activity-link' key={'link'} to={`/executions/${rootId}`}
                          tabIndex='-1'
                          title={rootTitle || title}
                          data-expand-click-area>
                        <TruncateReadMoreText>{rootTitle || title}</TruncateReadMoreText>
                    </Link>
                </span>
                {includeBothTitles && <label className={classes.copy}>{nodeTitle}</label>}
                {children}
            </div>
        </ClickableArea>
    )
}

const styles = theme => ({
    card: {
        display: 'block',
        width: '100%',
        borderBottom: `1px solid ${theme.palette.grey.two.main}`,
        position: 'relative',
        padding: '15px 5px 20px 55px',
        transition: 'box-shadow .2s',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '0 3px 3px #00000029',
            background: theme.palette.secondary.one.main
        }
    },
    icon: {
        display: 'block',
        position: 'absolute',
        top: 10,
        left: 8,
        borderRadius: 7,
        width: 30
    },
    header: {
        display: 'block',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '& a': {
            color: theme.palette.primary.three.main,
            // textDecoration: 'none',
        }
    },
    copy: {
        display: 'block',
        textTransform: 'capitalize',
        color: theme.palette.grey.six.main,
        fontSize: 12
    }
})

export default withRouter(
    withStyles(styles)(
        ActivityCard
    ));
