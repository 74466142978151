import React, {Component} from 'react';
import withStyles from '@mui/styles/withStyles';
import {getActiveDescendantsAndSelfIfPresent, getNodeOrNull, getSchema} from "../../selectors/graphSelectors";
import {getUpdatedNodesFromServer, putNodeProperty, restoreNodesByScope} from "../../actions";
import {connect} from "react-redux";
import Loader from "../components/Loader";
import * as PropTypes from "prop-types";
import {toast} from "react-toastify";
import Button from "@mui/material/Button";
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import NotFoundPage from "../components/NotFoundPage";
import {getIsDocumentHidden, isOnline} from "../../util/util";
import {DIAGNOSTIC_MODES, NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {checkHasValue} from "../../util/common/precondition";
import {cypress, getTime} from "tbf-react-library";
import {Link} from "react-router-dom";
import {createNodeForLoading, createResourceSyncNode} from "../../factory/graphFactory";
import {isIndexedDbAvailable} from '../../util/offline';
import HighlightLink from '../execution/troubleshoot/HighlightLink';
import {strings} from '../components/SopLocalizedStrings';

/**
 * Load data when navigated to a resource as required.
 *
 * e.g.
 * - /projects will load all projects
 * - /projects/123 will load project 123
 * - /procedures will load procedures
 * - /procedures/123 will load procedure 123
 */
class GraphResourceLoad extends Component {

    constructor(props, context) {
        super(props, context);
        this.loadGraph = this.loadGraph.bind(this);
        this.expireResourceSync = this.expireResourceSync.bind(this);
    }

    displayedError = false;
    firstLoadRun = true;
    nextRunTime = null;
    timer = null;
    loadIndexedDbStarted = false;
    extraPropertiesUpdated = false;

    componentDidMount() {
        this.loadGraph(true);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {pageSize, loadOnPageSizeChange, resourceSync, extraProps, extraPropertiesDirty, putNodeProperty} = this.props;
        let shouldForceLoad = false;
        if (loadOnPageSizeChange && pageSize !== prevProps.pageSize) {
            shouldForceLoad = true;
        }

        if(!this.extraPropertiesUpdated && extraPropertiesDirty) {
            putNodeProperty({id: resourceSync.id, ...extraProps});
            this.extraPropertiesUpdated = true;
        }

        this.loadGraph(false, shouldForceLoad);
    }

    componentWillUnmount() {
        this.stopTimer();
    }

    startTimer(nextRunTime) {
        let {offline, resourceSync} = this.props;
        this.stopTimer()
        const timeout = Math.max(0, nextRunTime - getTime())
        // If offline load we want to not load all offline content at once
        // So we need a small delay between load so that the first in runs, and the rest will cancel the timer
        // If this is a reload, we can afford to spread them out a bit more than other loads too
        const reloadJitter = resourceSync.loaded ? 50 : 1;
        const minJitter = offline ? 50 : 0;
        const jitter = Math.random() * (offline ? 50 : 1) * reloadJitter + minJitter;
        this.timer = setTimeout(() => this.loadGraph(false, false, true), timeout + jitter)
    }

    stopTimer() {
        if (this.timer !== null) {
            clearTimeout(this.timer);
            this.timer = null;
        }
    }

    computeNextLoadTime = (firstRun, manual = false) => {
        let {
            resourceSync,
            disabled,
            reloadIntervalMs,
            fastReloadOn,
            pageNumber,
            incrementalLoadAll,
            firstLoadQuickOff,
            waitForIndexedDb,
            isIndexedDbChecked,
            online,
            lastUserActivityDateTime,
            offline
        } = this.props;

        // #1 No Loading
        if (!resourceSync?.id || (isIndexedDbChecked !== true && isIndexedDbAvailable()) || !online || disabled) {
            return null;
        }

        // #2 Loading Timeout
        if (resourceSync.loading) {
            return resourceSync.lastReloadTicks + 120000;
        }

        // #3 Timers
        const timers = []
        const loadNeverAttempted = resourceSync.lastReloadTicks == null;
        // Load always on reload of page, but dont load if rendered multiple times
        let lastReloadExpiration = this.firstLoadRun && !firstLoadQuickOff ? 100 : (fastReloadOn ? 15000 : reloadIntervalMs);
        let lastReloadAgeMs = getTime() - resourceSync.lastReloadTicks;
        let lastReloadExpired = loadNeverAttempted === false && lastReloadAgeMs >= lastReloadExpiration;
        if (loadNeverAttempted === false) {
            timers.push(resourceSync.lastReloadTicks + lastReloadExpiration)
        }
        let lastLoadError = this.firstLoadRun && resourceSync.loadingError != null;

        // #4 Missing Children
        const loadFullExpected = !offline && (resourceSync.type === NODE_TYPE_OPTIONS.ExecutionRoot || resourceSync.type === NODE_TYPE_OPTIONS.ProcedureRoot);
        let notLoadedFullAndTryAgain = !loadNeverAttempted && resourceSync.loadedFull !== true && loadFullExpected;
        if (notLoadedFullAndTryAgain) {
            timers.push(resourceSync.lastReloadTicks + 60000)
        }
        let loadNextPageRequested = pageNumber > resourceSync.pageNumber;
        let nextPageAvailable = resourceSync.hasNextPage;
        let loadNextPage = resourceSync.loaded && loadNextPageRequested && nextPageAvailable;
        let incrementalLoadAllNow = incrementalLoadAll && resourceSync.hasNextPage;

        // #5 Load from server when...
        let loadNow =
            loadNeverAttempted
            || lastLoadError
            || lastReloadExpired
            || manual
            || loadNextPage
            || incrementalLoadAllNow;

        // #6 Except when
        if (loadNow && resourceSync.loadingErrorCount && !lastReloadExpired && !manual) {
            // We don't want to hammer the server, so lets not try to download when it fails.
            // This is mostly when incrementalLoadAllNow is true as it will keep trying the same failed request
            loadNow = false;
        }
        if (this.firstLoadRun === false && waitForIndexedDb) {
            loadNow = false;
        }

        // #7 Load NOW
        if (loadNow) {
            timers.push(0)
        }

        // #8 Min Load
        let minTimer = Math.min(...timers);

        // #9 Throttle - No more than 1 per second
        if (minTimer > 0 && resourceSync.lastReloadTicks && minTimer < resourceSync.lastReloadTicks + 1000) {
            minTimer = resourceSync.lastReloadTicks + 1000
        }
        // #10 Throttle for 5 mins if tab or user is inactive
        const userInactive = !!lastUserActivityDateTime && (Date.now() - lastUserActivityDateTime > (60000 * 5));
        const tabInactive = getIsDocumentHidden();
        if (minTimer > 0 && (userInactive || tabInactive)) {
            minTimer = minTimer + (60000 * 5);
        }
        return minTimer;
    }

    loadGraph(firstRun, manual = false, fromTimer) {
        let {
            schema,
            resourceSync,
            pageNumber,
            restoreNodesByScope,
            getUpdatedNodesFromServer,
            offline,
            isIndexedDbChecked,
            skipDeltaLoad
        } = this.props;

        if (!resourceSync.id) {
            throw new Error('Node has no id. ' + JSON.stringify(resourceSync));
        }
        if (isIndexedDbChecked !== true && isIndexedDbAvailable()) {
            if (!this.loadIndexedDbStarted) {
                restoreNodesByScope(resourceSync.id, resourceSync, offline);
                this.loadIndexedDbStarted = true;
            }
            return;
        } else {
            // Reset as the Reset Redux debug action means we need to reload from redux
            this.loadIndexedDbStarted = false;
        }

        const lastLoadTime = this.nextRunTime;
        const nextRunTime = this.computeNextLoadTime(firstRun, manual);

        this.nextRunTime = nextRunTime;
        if (nextRunTime === null) {
            this.stopTimer()
        } else if (nextRunTime > 0 || (offline && !fromTimer)) {
            if (nextRunTime !== lastLoadTime) {
                this.startTimer(nextRunTime);
            }
        } else if (nextRunTime == 0) {
            let loadNextPageRequested = pageNumber > resourceSync.pageNumber;
            let nextPageAvailable = resourceSync.hasNextPage;
            let loadNextPage = resourceSync.loaded && loadNextPageRequested && nextPageAvailable;
            getUpdatedNodesFromServer(schema, resourceSync, loadNextPage, this.firstLoadRun, offline, skipDeltaLoad);
        }

        this.firstLoadRun = false;
    }

    expireResourceSync() {
        const {resourceSync, putNodeProperty} = this.props;
        putNodeProperty({...resourceSync,
            lastReloadTicks: null,
            lastUpdatedDateTime: null,
            loadedFull: false,
        });
    }

    renderChildren() {
        const {children, renderChildren} = this.props;
        return renderChildren ? renderChildren() : children;
    }

    render() {
        const {
            resourceSync,
            displayErrorMode,
            friendlyName,
            disabled,
            hideLoader,
            disableLoader,
            processingWarnings,
            hideOfflineWarnings,
            isIndexedDbChecked,
            online,
            classes,
            offline
        } = this.props;
        if (disabled === true) {
            return (
                <React.Fragment>
                    {this.renderChildren()}
                </React.Fragment>
            );
        }
        let isLoading = resourceSync.loading || (isIndexedDbChecked !== true && isIndexedDbAvailable()) || (!offline && this.nextRunTime === 0);
        let errorMsg = (resourceSync.loadingError && `There was an error refreshing the ${friendlyName} from the server. ${resourceSync.loadingError}.`)
            || (resourceSync.loadingDbError && `There was an error loading ${friendlyName} from local storage. ${resourceSync.loadingDbError}`)
            || null;
        let allowErrorRetry = !!resourceSync.loadingError;
        if (resourceSync.loadedFull !== true || (isIndexedDbChecked !== true && isIndexedDbAvailable())) {
            return (
                <React.Fragment>
                    {
                        resourceSync.nodeExists === false &&
                        <NotFoundPage name={friendlyName} nodeId={resourceSync.id}/>
                    }
                    {
                        errorMsg && !isLoading && displayErrorMode === 'inline' &&
                        <div className={'alert alert-error'}>
                            {errorMsg}
                            &nbsp;
                            <Button variant={'outlined'} onClick={() => this.loadGraph(false, true)}>
                                <RefreshIcon/>
                                Retry
                            </Button>
                        </div>
                    }
                    {
                        isLoading && !disableLoader &&
                        <Loader
                            source={'GraphResourceLoad ' + (resourceSync.resourcePath || resourceSync.id || resourceSync.type || friendlyName)}
                            friendlyName={friendlyName}
                            hidden={hideLoader}
                        />
                    }
                    {
                        isIndexedDbChecked && !online && resourceSync && hideOfflineWarnings !== true &&
                        <div className={'alert alert-warning'}>
                            You are offline and the <b>{friendlyName}</b> have not been cached.</div>
                    }
                    {
                        resourceSync.processingError &&
                        <div className={'alert alert-warning'}>
                            This {friendlyName} cannot be displayed. {resourceSync.processingError}
                            <Button onClick={this.expireResourceSync} className={classes.reload} variant="contained" data-cy={"reload-execution-server"}>{strings.execution.errors.reload}</Button>
                        </div>
                    }
                </React.Fragment>);
        }
        let displayErrorThisTime = resourceSync.loadingErrorCount > 2 || resourceSync.loadingDbError;
        if (errorMsg && this.displayedError === false && displayErrorMode === 'toast' && displayErrorThisTime) {
            this.displayedError = true;
            toast.warn(errorMsg)
        }
        if (resourceSync.processingError) {
            return (
                <div className={'alert alert-warning'}>
                    This {friendlyName} cannot be displayed. {resourceSync.processingError}
                    <Button onClick={this.expireResourceSync} className={classes.reload} variant="contained" data-cy={"reload-execution-server"}>{strings.execution.errors.reload}</Button>
                </div>);
        }
        return (
            <React.Fragment>
                {
                    processingWarnings && processingWarnings.length > 0 &&
                    <div className={'alert alert-warning'}>
                        <h2>Diagnostic Mode Processing Warnings</h2>
                        <Link
                            to={`/graph/nodes/${encodeURIComponent(resourceSync.id)}`}>{resourceSync.type} {resourceSync.key || ''} {resourceSync.title || resourceSync.name || ''}</Link>
                        <ul>
                            {
                                processingWarnings.map(a =>
                                    <li key={a.id}>
                                        <div><HighlightLink id={a.id}>{a.type}: {a.title}</HighlightLink>:</div>
                                        <div>{a.msg}</div>
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                }
                {
                    errorMsg && displayErrorMode === 'inline' && displayErrorThisTime &&
                    <div className={'alert alert-warning'}>
                        {errorMsg}
                        {resourceSync.loadingError && resourceSync.loadingError.message}
                        {resourceSync.loadingDbError && resourceSync.loadingDbError.message}
                        {
                            allowErrorRetry &&
                            <Button variant={'outlined'} onClick={() => this.loadGraph(false, true)}>
                                <RefreshIcon/>
                                Retry
                            </Button>
                        }
                    </div>
                }
                {
                    resourceSync.loaded && resourceSync.nodeExists !== true &&
                    <NotFoundPage name={friendlyName} nodeId={resourceSync.id}/>
                }
                {
                    resourceSync.nodeExists !== false &&
                    this.renderChildren()
                }
            </React.Fragment>
        );
    }
}

const styles = () => ({
    reload: {
        marginLeft: '8px',
    }
});
GraphResourceLoad.propTypes = {
    displayErrorMode: PropTypes.string,
    resourcePath: PropTypes.string,
    friendlyName: PropTypes.string,
    disabled: PropTypes.bool,
    hideLoader: PropTypes.bool,
    scope: PropTypes.string,
    offline: PropTypes.bool,
    incrementalLoadOff: PropTypes.bool,
    nodeType: PropTypes.string.isRequired,
    reloadIntervalMs: PropTypes.number,
    hideOfflineWarnings: PropTypes.bool,
    disableLoader: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const diagnosticModeOn = userDevice?.diagnosticMode === DIAGNOSTIC_MODES.full.id;
    const isCypress = cypress.isCypress();
    const extraProps = ownProps.extraProps ?? {};
    let hasDiagnostics = diagnosticModeOn || isCypress;
    let processingWarnings = [];
    let displayErrorMode = ownProps.displayErrorMode || 'inline';
    let waitForIndexedDb = ownProps.offline && (state.graph.pendingStoreDbNodeCount > 0 || state.graph.serverLoading || getIsDocumentHidden())
    let online = isOnline()
    let userSettings = getNodeOrNull(state, NODE_IDS.UserSettings);
    let lastUserActivityDateTime = userSettings?.lastUserActivityDateTime;
    let resourceSync;
    let hideOfflineWarnings = ownProps.hideOfflineWarnings;
    let extraPropertiesDirty = false;

    if (ownProps.nodeId) {
        let node = getNodeOrNull(state, ownProps.nodeId);
        if (node && node.type === ownProps.nodeType) {
            resourceSync = node;
            checkHasValue(resourceSync.type, 'type is required', resourceSync);

            if (hasDiagnostics && displayErrorMode === 'inline') {
                processingWarnings = getActiveDescendantsAndSelfIfPresent(state, node.id)
                    .filter(a => a.processingWarning)
                    .map(a => ({
                        msg: a.processingWarning,
                        id: a.id,
                        type: a.type,
                        title: a.title || a.name || a.toNodeKey || a.toNodeTitle,
                    }));
            }

            if(extraProps) {
                extraPropertiesDirty = Object.entries(extraProps).some(([key, value]) => resourceSync[key] !== value);
            }

            if(extraPropertiesDirty) {
                resourceSync = {...resourceSync, ...extraProps};
            }

        }
        else  if (node && node.type !== ownProps.nodeType) {
            resourceSync = createNodeForLoading(ownProps.nodeId, ownProps.nodeType);
            resourceSync.loaded = true;
            resourceSync.loading = false;
            resourceSync.nodeExists = false;
            resourceSync.indexedDbChecked = true;
            online = false;
            hideOfflineWarnings = true;
        }
        else {
            resourceSync = createNodeForLoading(ownProps.nodeId, ownProps.nodeType, ownProps.extraProps);
            checkHasValue(resourceSync.type, 'type is required', resourceSync);
        }
    } else {
        let resourceSyncByPath = getNodeOrNull(state, ownProps.resourcePath);
        if (resourceSyncByPath == null) {
            resourceSyncByPath = createResourceSyncNode(ownProps.resourcePath, ownProps.nodeType, ownProps.pageSize, ownProps.reloadIntervalSeconds);
        }
        if (ownProps.incrementalLoadOff) {
            resourceSyncByPath.lastUpdatedDateTime = null;
        }
        checkHasValue(resourceSyncByPath.nodeType, 'nodeType is required', resourceSyncByPath);
        resourceSync = resourceSyncByPath;
    }



    return {
        resourceSync: resourceSync,
        schema: getSchema(state),
        processingWarnings: processingWarnings,
        displayErrorMode: displayErrorMode,
        fastReloadOn: userSettings?.fastReloadOn || false,
        waitForIndexedDb,
        isIndexedDbChecked: ownProps.offline ? resourceSync.indexedDbOfflineChecked || resourceSync.indexedDbChecked : resourceSync.indexedDbChecked,
        online: online,
        lastUserActivityDateTime,
        hideOfflineWarnings,
        extraPropertiesDirty
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getUpdatedNodesFromServer: (schema, resourceSync, loadNextPage, firstLoadRun, offline, skipDeltaLoad) => dispatch(getUpdatedNodesFromServer(schema, resourceSync, loadNextPage, firstLoadRun, offline, skipDeltaLoad)),
        restoreNodesByScope: (scope, resourceSync, offline) => dispatch(restoreNodesByScope(scope, resourceSync, offline)),
        putNodeProperty: (node) => dispatch(putNodeProperty(node))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(GraphResourceLoad));
