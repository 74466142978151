import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {getDirtiesById, getNodeOrNull, getNodesIfPresent} from "../../selectors/graphSelectors";
import {connect} from "react-redux";
import '../../style/alert.css';
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {Permissions} from "../../permissions";
import {strings} from "../components/SopLocalizedStrings";
import LoadHelpPoints from '../../manuals/layouts/LoadHelpPoints';
import {NODE_IDS, NODE_TYPE_OPTIONS} from "../../reducers/graphReducer";
import {ComponentBase, SharedAuth} from "tbf-react-library";
import {debounce, keyBy} from "lodash";
import {isRecent} from "../../util/util";
import {hasExecutionPermission, isExecutionLoadedFull} from "../../selectors/executionSelectors";
import {isNodeSaved} from "../../factory/executionFactory";
import {HOURS_1, MINUTES_5} from "../../util/constants";
import { isDeepEqual } from '@mui/x-data-grid/internals';

class ExecutionLoadDependencies extends ComponentBase {

    constructor(props, context) {
        super(props, context);
        this.state = {
            executionListPathsDebounce: false,
        }
    }

    mounted = true;

    componentDidUpdate(prevProps) {
        const {executionListPaths} = this.props;
        const listPathsChanged = !isDeepEqual(executionListPaths, prevProps.executionListPaths);
        if (listPathsChanged) {
            this.setState({executionListPathsDebounce: true});
            this.debounceExecutionListPaths(this);
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    debounceExecutionListPaths = debounce((t) => {
        if (!t.mounted) {
            return;
        }
        t.setState({executionListPathsDebounce: false})
    }, 200)

    render() {
        let {
            nodeId,
            executionProjectId,
            executionProcedureId,
            loadProcedureIds,
            executionLoaded,
            executionExistsApi,
            loadExecutionIds,
            scopeReturnFields,
            reloadInterval,
            maxLinkLastUpdatedDateTime,
            canView,
            executionListPaths,
        } = this.props;
        const {executionListPathsDebounce} = this.state;
        const scopedPath = NODE_IDS.ExecutionSummaryScoped(nodeId, scopeReturnFields || []);
        return (
            <>
                {
                    executionProjectId &&
                    <GraphResourceLoad
                        resourcePath={NODE_IDS.Project(executionProjectId)}
                        friendlyName={strings.project.name}
                        nodeId={executionProjectId}
                        nodeType={'ProjectRoot'}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                    />
                }
                {
                    executionListPaths &&
                    executionListPaths.filter((path) => {
                        if (!executionListPathsDebounce) {
                            return true;
                        }
                        return !path.includes("q=");
                    }).map((path) =>
                        <GraphResourceLoad
                            key={path}
                            resourcePath={path}
                            friendlyName={strings.execution.namePlural}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={MINUTES_5}
                            hideLoader={true}
                            incrementalLoadOff={true}
                            hideOfflineWarnings={true}
                        />
                    )
                }
                {
                    executionLoaded && executionExistsApi && canView &&
                    <GraphResourceLoad
                        key={reloadInterval}
                        resourcePath={NODE_IDS.PhotosForExecution(nodeId, executionProjectId)}
                        friendlyName={strings.photo.namePlural}
                        nodeType={'Photo'}
                        reloadIntervalMs={reloadInterval}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                    />
                }
                {
                    executionProcedureId &&
                    <LoadHelpPoints externalId={executionProcedureId}/>
                }
                {
                    executionLoaded && executionExistsApi && canView &&
                    <GraphResourceLoad
                        key={scopedPath + maxLinkLastUpdatedDateTime}
                        resourcePath={scopedPath}
                        friendlyName={strings.execution.namePlural}
                        nodeType={'ExecutionRoot'}
                        reloadIntervalMs={reloadInterval}
                        hideLoader={true}
                        hideOfflineWarnings={true}
                    />
                }
                {
                    loadProcedureIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={NODE_IDS.ProcedureFull(id)}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ProcedureRoot'}
                            reloadIntervalMs={HOURS_1}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                            firstLoadQuickOff={true}
                        />))
                }
                {
                    loadExecutionIds.map(id => (
                        <GraphResourceLoad
                            key={id}
                            resourcePath={`/executions?id=${id}`}
                            nodeId={id}
                            friendlyName={strings.procedure.name}
                            nodeType={'ExecutionRoot'}
                            reloadIntervalMs={reloadInterval}
                            hideLoader={true}
                            hideOfflineWarnings={true}
                        />))
                }
            </>
        )
    }
}

const styles = () => ({});
ExecutionLoadDependencies.propTypes = {};
const mapStateToProps = (state, ownProps) => {
    let nodeId = ownProps.executionId;
    let executionNode = getNodeOrNull(state, nodeId);

    if(executionNode?.type !== NODE_TYPE_OPTIONS.ExecutionRoot) {
        executionNode = null;
        nodeId = "unknown";
    }

    let rules = getNodesIfPresent(state, executionNode?.rules);
    const canComplete = hasExecutionPermission(state, nodeId, Permissions.execution.complete);
    const canView = hasExecutionPermission(state, nodeId, Permissions.execution.read)
    const disabled = !hasExecutionPermission(state, nodeId, Permissions.execution.complete) || (executionNode && executionNode.destroyed === true);
    const executionDisabled = disabled || (executionNode && executionNode.canComplete === false);
    const userDevice = getNodeOrNull(state, NODE_IDS.UserDevice);
    const troubleshootOn = userDevice?.troubleshootOn === true;

    // #1 Get all required templates
    let loadProcedureIds = (!executionDisabled && (executionNode?.resourceDependencies?.procedureIds ?? [])) || [];

    // #2 Get all required full executions
    // #2.1 When a link to an execution appears we will load it in full in case the toNodeId has a copyTo rule on it
    let links = getNodesIfPresent(state, (executionNode && executionNode.links) || []);
    let linkDirty = getDirtiesById(state, links.map(a => a.id));
    let unsaved = linkDirty.filter(a => a && (a.storedServer !== true || isRecent(a.lastSaveTicks, 60 * 1000)));
    let linksById = keyBy(links, a => a.id);
    let newlyLinkedExecutions = unsaved.map(a => linksById[a.id]?.toNodeId).filter(a => !!a);
    // #2.2 Load auto created ones in full
    // Unsure if I should load these
    let autoCreatedExecutionIds = troubleshootOn ? rules.filter(a => a.createExecutionIds).flatMap(a => a.createExecutionIds) : [];
    let loadExecutionIds = [...new Set([...newlyLinkedExecutions, ...autoCreatedExecutionIds])];//, autoCreatedExecutionIds];
    const executionExistsApi = executionNode && isNodeSaved(executionNode)
    return {
        nodeId: nodeId,
        executionLoaded: executionNode?.loaded,
        executionExistsApi: executionExistsApi,
        executionReady: isExecutionLoadedFull(executionNode),
        executionPreview: executionNode?.preview,
        executionDraft: executionNode?.draft,
        executionProcedureId: executionNode?.procedureId,
        executionProjectId: executionNode?.projectId,
        projectNode: getNodeOrNull(state, executionNode?.projectId),
        executionDisabled: executionDisabled,
        canAssign: SharedAuth.userHasPermission(Permissions.execution.assign),
        loadProcedureIds: loadProcedureIds,
        loadExecutionIds: loadExecutionIds,
        scopeReturnFields: executionNode?.scopeReturnFields,
        maxLinkLastUpdatedDateTime: executionNode?.maxLinkLastUpdatedDateTime,
        reloadInterval: (ownProps.reloadInterval * 1000) || executionNode?.reloadInterval,
        canView: canView,
        executionListPaths: executionNode?.resourceDependencies?.executionListPaths,
    };
};
const mapDispatchToProps = () => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExecutionLoadDependencies));
