import React, {useCallback, useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import GraphResourceLoad from "../graph/GraphResourceLoad";
import {strings} from "../components/SopLocalizedStrings";
import {Breadcrumbs, PageContentWrapper, PageHeader} from "tbf-react-library";
import {getProcedureTypeIcon} from "../../util/image";
import {EXECUTION_SEARCH_VIEWS, NODE_IDS, NODE_TYPE_OPTIONS,} from "../../reducers/graphReducer";
import AddNewExecutionButton from "./AddNewExecutionButton";
import ViewTemplateButton from "./ViewTemplateButton";
import WorkItems from "./WorkItems";
import {useCallbackPatchNode, useGetOrCreateNode, useNodePropertyOrNull} from "../../hooks/nodeHooks";
import {HOURS_1} from "../../util/constants";

const ListingPage = ({
                         procedureType,
                         procedureId,
                         viewId,
                         pivot
                     }) => {
    const style = useStyles();
    const useProcedureType = procedureType === 'all' ? null : procedureType;
    const procedureName = useNodePropertyOrNull(procedureId, p => p?.name)
    const procedureLoaded = useNodePropertyOrNull(procedureId, p => p?.loadedFull)
    const selectorId = NODE_IDS.ExecutionListingPage(procedureId, procedureType, viewId)
    let attributes = {procedureId: procedureId, procedureType: useProcedureType, selectedViewId: viewId}
    const selector = useGetOrCreateNode(selectorId, NODE_TYPE_OPTIONS.ExecutionListPage, attributes)
    let namePlural = procedureId ? procedureName : strings.procedure.types[procedureType || 'all'].namePlural
    const patch = useCallbackPatchNode()
    const patchRecentlyAdded = item => patch({
        id: selectorId,
        recentlyCreated: [{id: item.id, addedTicks: new Date().getTime()}, ...(selector?.recentlyCreated || [])]
    })
    const waitForProcedureLoad = procedureId && !procedureLoaded

    useEffect(() => {
        return () => {
            console.info('ListingPage unmounting')
        }
    })
    const renderPageActions = useCallback(() => {
        return (<React.Fragment>
            {
                procedureId &&
                <>
                    <ViewTemplateButton procedureId={procedureId}/>
                    <AddNewExecutionButton procedureId={procedureId} addingCompleted={patchRecentlyAdded}
                                           primaryAction={true}/>
                </>
            }
        </React.Fragment>)
    }, [procedureId, pivot])
    return <>
        <PageHeader
            title={namePlural}
            PageIcon={<img src={getProcedureTypeIcon(procedureType)} alt={''}/>}
            renderPageActions={renderPageActions}
            showMenuToggle={true}/>
        <Breadcrumbs list={[]}/>
        <PageContentWrapper classContainer={style.pageContentWrapper} >
            {
                procedureId &&
                <GraphResourceLoad
                    key={procedureId}
                    nodeId={procedureId}
                    resourcePath={NODE_IDS.ProcedureFull(procedureId)}
                    friendlyName={strings.buttons.view}
                    nodeType={'ProcedureRoot'}
                    reloadIntervalMs={HOURS_1}
                />
            }
            {
                selector && !waitForProcedureLoad &&
                <WorkItems selectorId={selectorId} procedureType={procedureType} />
            }
        </PageContentWrapper>
    </>
}
export default ListingPage;

const useStyles = makeStyles(() => ({
    pageContentWrapper: {
        paddingBottom: 0
    }
}))
