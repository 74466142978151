import React, { useState } from 'react';
import withStyles from '@mui/styles/withStyles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import {AFTER_ADD_NAVIGATION_MODE, EXECUTION_SOURCE_TYPES, QUESTION_TYPES} from "../../reducers/graphReducer";
import {reportUserError} from "tbf-react-library";
import {useNodeOrNull} from "../../hooks/nodeHooks";
import {useGetTemplateOptions} from "../../hooks/executionHooks";
import { strings } from '../components/SopLocalizedStrings';
import ExecutionAddNewDialog from './ExecutionAddNewDialog';

const ExecutionShowQuestionAddLink = (
    {
        anchorEl,
        onClose,
        handleCreate,
        handleModalClose,
        questionId,
    }) => {
    const fromQuestionNode = useNodeOrNull(questionId);
    const executionNode = useNodeOrNull(fromQuestionNode.rootId);
    const menuId = fromQuestionNode.id + "_executionOptionMenu";
    const {displayOptions, hasMultiOptions} = useGetTemplateOptions(questionId);

    const [createOptions, setCreateOptions] = useState(null);

    const handleAdd = (option) => () => {
        if (!option) {
            reportUserError(strings.execution.errors.noValidExecutionAddOption);
            return;
        }

        if (option.invalidReason) {
            reportUserError(option.invalidReason);
            return;
        }
        const options = getExecutionLinkAttrs(option);
        setCreateOptions(options);
        onClose();
    };

    const getExecutionLinkAttrs = (option) => {
        // Lets always create a preview so that we can secretly test upgrade
        // as the procedure is built.
        // If multiple link types, use the first available, will be auto-linked later
        
        const previewAttr = {
            procedureId: option.procedureId,
            linkType: option.linkTypes?.[0],
            fromExecutionId: fromQuestionNode.rootId,
            preview: fromQuestionNode.preview,
            projectId: executionNode.projectId,
            source: {
                createdFromExecutionId: fromQuestionNode.rootId,
                kind: EXECUTION_SOURCE_TYPES.queryAdd.id
            }
        };
        return previewAttr;
    };


    React.useEffect(() => {
        if(!anchorEl || hasMultiOptions || !displayOptions.length) {
            return;
        }

        handleAdd(displayOptions[0])();
    }, [anchorEl, displayOptions, hasMultiOptions])

    const handleDialogClose = () => {
        setCreateOptions(null);
        handleModalClose?.();
    }

    return (
        <React.Fragment>
            {
                createOptions &&
                <ExecutionAddNewDialog
                    open={!!createOptions}
                    onClose={handleDialogClose}
                    onAddCompleted={handleCreate}
                    procedureId={createOptions.procedureId}
                    linkProperties={createOptions}
                    disableStartEditing={fromQuestionNode.questionType === QUESTION_TYPES.select.id}
                    defaultMode={AFTER_ADD_NAVIGATION_MODE.addAndClose.id}
                />
            }
            {
                (anchorEl && hasMultiOptions) &&
                <Menu
                    id={menuId}
                    getContentAnchorEl={null}
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={onClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                >
                    {
                        displayOptions.map((option, i) => (
                            <MenuItem onClick={handleAdd(option)} key={i}>
                                {option.procedureName}
                            </MenuItem>))
                    }
                </Menu>
            }
        </React.Fragment>
    )

}

const styles = () => ({
    linktype: {
        fontSize: '75%'
    }
});

export default withStyles(styles)(ExecutionShowQuestionAddLink);
