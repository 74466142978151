import uuidv4 from "uuid/v4";
import cloneDeep from "lodash/cloneDeep";
import {getJsonDate} from "../util/util";
import {reportDeveloperWarning, SharedAuth, UNIT_TEST_MODE} from "tbf-react-library";
import {RELOAD_INTERVAL_DEFAULT} from "../reducers/graphReducer";

let counters = {};
export let generateId = (nodeSchema) => {
    let id;
    if (UNIT_TEST_MODE) {
        let count = (counters[nodeSchema.type] || 0) + 1;
        id = nodeSchema.type + count;
        counters[nodeSchema.type] = count;
    } else {
        id = uuidv4();
    }
    return id;
};
export const resetCounters = () => {
    counters = {};
};

export const createNode = (nodeSchema, attributes) => {
    const id = (attributes && attributes.id) || generateId(nodeSchema);
    if (attributes) {
        delete attributes.id;
    }
    const node = {
        id: id,
        rootId: nodeSchema.isRoot ? id : null,
        deleted: false,
        loaded: true,
        nodeExists: true,
        clientCreatedDateTime: getJsonDate(),
        clientCreatedByUser: SharedAuth.getAuditName(),
        indexedDbChecked: true,
        ...cloneDeep(nodeSchema.factory),
        ...attributes,
    };
    if (nodeSchema.properties['loadedFull']) {
        node.loadedFull = true;
    }
    if (!node.rootId) {
        reportDeveloperWarning(`createNode did not include rootId for ${nodeSchema.type}`);
    }
    if (!node.type) {
        reportDeveloperWarning(`createNode did not include type for ${nodeSchema.type}`);
    }
    return node;
};
export const createChildNode = (parent, nodeSchema, attributes) => {
    const hasParentId = !!nodeSchema.properties.parentId?.storeServer
    const extraAttributes = {...attributes, parentId: parent.id, rootId: parent.rootId}
    if (hasParentId) {
        extraAttributes.parentId = parent.id
    }
    return createNode(nodeSchema, extraAttributes);
};


export const createNodeForLoading = (nodeId, nodeType, extraProps = {}) => {
    return {
        type: nodeType,
        id: nodeId,
        rootId: nodeId,
        loading: false,
        loaded: false,
        loadedFull: false,
        loadingError: null,
        lastReloadTicks: null,
        lastUpdatedDateTime: null,
        loadingErrorCount: 0,
        indexedDbChecked: false,
        nodeExists: null,
        scopes: [nodeId],
        ...extraProps
    };
}

export const createResourceSyncNode = (resourcePath, nodeType, pageSize, reloadIntervalSeconds = RELOAD_INTERVAL_DEFAULT) => {
    return {
        type: 'ResourceSync',
        nodeType: nodeType,
        resourcePath: resourcePath,
        id: resourcePath,
        rootId: resourcePath,
        pageSize: pageSize,
        loading: false,
        loaded: false,
        loadedFull: false,
        loadingError: null,
        lastReloadTicks: null,
        lastUpdatedDateTime: null,
        loadingErrorCount: 0,
        indexedDbChecked: false,
        nodeExists: true,
        scopes: [resourcePath],
        reloadIntervalSeconds: reloadIntervalSeconds,
    };
}