import React from "react";
import {strings} from "../../components/SopLocalizedStrings";
import {Permissions} from "../../../permissions";
import TableView from "@mui/icons-material/TableView";
import Dashboard from "@mui/icons-material/Dashboard";
import {NonMobileOnly, TbfAction} from "tbf-react-library";
import {useHasProcedurePermission} from "../../../hooks/procedureHooks";
import AlphaFeature from "../../components/AlphaFeature";
import { useCallbackPatchNode, useNodeOrNull } from "../../../hooks/nodeHooks";
import makeStyles from "@mui/styles/makeStyles";

const ViewPivotTableButton = React.forwardRef(({selectorId, executionId, procedureId, className}, ref) => {

    const selector = useNodeOrNull(selectorId);

    const canView = useHasProcedurePermission(executionId || procedureId, Permissions.procedure.list)

    const patch = useCallbackPatchNode();

    const handleClick = () => {
        patch({id: selectorId, pivot: !selector?.pivot})
    }

    if (!canView) {
        return null
    }

    const useLabel = selector?.pivot !== true ? strings.buttons.viewPivotTable : strings.buttons.viewDataGrid;
    const useIcon = selector?.pivot !== true ? TableView : Dashboard;

    return (
        <AlphaFeature>
            <NonMobileOnly>
                <TbfAction
                    className={className}
                    title={useLabel}
                    dataCy='view-pivot-table'
                    iconComponent={useIcon}
                    onClick={handleClick}
                    ref={ref}
                >
                    {useLabel}
                </TbfAction>
            </NonMobileOnly>
        </AlphaFeature>
    )
})

export default ViewPivotTableButton;
