import React from "react";
import {Permissions} from "../../../permissions";
import {TbfAction} from "tbf-react-library";
import {strings} from "../../components/SopLocalizedStrings";
import ViewActivityIcon from "@mui/icons-material/HistoryRounded";
import {useHasExecutionPermission} from "../../../hooks/executionHooks";

const ExecutionActivityAction = React.forwardRef(({executionId, menuItem}, ref) => {
    const canView = useHasExecutionPermission(executionId, Permissions.execution.read)
    if (!canView) {
        return null
    }
    return (
        <TbfAction
            menuItem={menuItem}
            title={strings.execution.show.viewActivity}
            dataCy='view-activity'
            iconComponent={ViewActivityIcon}
            path={'/executions/' + executionId + '/activity'}
            ref={ref}
        >
            {strings.execution.show.viewActivity}
        </TbfAction>
    )
})
export default ExecutionActivityAction;
